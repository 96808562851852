import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";

const initialState = {
  pageCount: 0,
  rowCount: 0,
  category: "",
  priority: "",
  lifeCycleStatus: "",
  achievementStatus: "",
  list: [],
  selectedRow: null,
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const getGoals = createAsyncThunk("goals", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .get(
        `/goals?offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}&category=${params.category}&priority=${params.priority}&lifeCycleStatus=${params.lifeCycleStatus}&achievementStatus=${params.achievementStatus}&subject=${params.subject}`
      )
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const addGoal = createAsyncThunk("goals/add", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .post(`/goals`, params)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const updateGoal = createAsyncThunk(
  "goals/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/goals`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deleteGoal = createAsyncThunk("goals/delete", async (id, thunkApi) => {
  try {
    return await axiosInstance
      .delete(`/goals/${id}`)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const addFhirGoal = createAsyncThunk("goals/fhir", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .post("/goals/fhir", params)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const { actions, reducer } = createSlice({
  name: "goals",
  initialState,
  reducers: {
    setIntialProps: (state, action) => {
      return { ...state, ...action?.payload };
    },
    setSelectedRow: (state, action) => {
      state.selectedRow = action?.payload;
    },
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // get goals
      .addCase(getGoals.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getGoals.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data?.goals,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getGoals.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { setIntialProps, setSelectedRow, clearStatus } = actions;

const goalSlice = {
  getGoals,
  addGoal,
  updateGoal,
  deleteGoal,
  addFhirGoal,
  setSelectedRow,
  setIntialProps,
  clearStatus,
  reducer,
};

export default goalSlice;
