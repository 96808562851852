import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  height: 80,
  width: 80,
};

const { actions, reducer } = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      return { ...initialState, ...action?.payload };
    },
  },
});

const { setIsLoading } = actions;

const getIsLoading = (state) => state.loader;

const loaderSlice = {
  getIsLoading,
  setIsLoading,
  reducer,
};

export default loaderSlice;
