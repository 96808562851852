import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { useDispatch } from "react-redux";
import Label from "../Label";
import { AsyncPaginate } from "react-select-async-paginate";
import { FontAwesomeIcon, faTrash, faPlus } from "../../../Assets/icons";
import { appConstants, messageConstants } from "../../../Constants";
import { asyncForEach } from "../../../Utils";
import _ from "lodash";
import { taxonomySlice } from "../../../Features";
import { toast } from "react-toastify";

const ReferenceRange = forwardRef(({ rows }, ref) => {
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [referenceRange, setReferenceRange] = useState([{}]);
  useEffect(() => {
    setReferenceRange(rows?.length ? rows : [{}]);
  }, [rows]);

  const extendLoadOptions = useCallback(
    (searchQuery, loadedOptions, { name }) => {
      let options = [],
        rowCount = 0;
      return new Promise((resolve, reject) => {
        dispatch(
          taxonomySlice.getValueSet({
            text: searchQuery,
            name,
            offset: loadedOptions.length || appConstants.DEFAULT_SKIP,
            limit: appConstants.DEFAULT_LIMIT,
            sortBy: "createdAt",
            sortOrder: "desc",
          })
        )
          .unwrap()
          .then((response) => {
            options = response?.data?.codes;
            rowCount = response?.data?.rowCount;
            resolve({
              options,
              hasMore:
                loadedOptions.length + appConstants.DEFAULT_LIMIT < rowCount
                  ? true
                  : false,
              name,
            });
          })
          .catch((error) => {
            toast.error(error?.data?.message || error?.data);
            resolve({
              options,
              hasMore: false,
              name,
            });
          });
      });
    },
    [dispatch]
  );

  const removefields = (index) => {
    setReferenceRange((referenceRange) =>
      referenceRange.filter((v, i) => i !== index)
    );
  };

  const addfields = (index) => {
    if (
      Object.values(_(referenceRange[index]).pickBy(_.identity).value())
        .length &&
      !Object.values(
        _(validate(referenceRange[index], index)).pickBy(_.identity).value()
      ).length
    )
      setReferenceRange([...referenceRange, {}]);
    else setError(validate(referenceRange[index], index));
  };

  useImperativeHandle(ref, () => ({
    handleSubmit(event) {
      event.preventDefault();
      let invalid = false;
      asyncForEach(referenceRange, (val, index) => {
        setError(validate(val, index));
        invalid = Object.values(
          _(validate(val, index)).pickBy(_.identity).value()
        ).length
          ? true
          : false;
      });
      if (!invalid)
        return referenceRange.filter(
          (t) => t?.low?.value || t?.high?.value || t?.text?.trim()
        );
    },
  }));

  const validate = (values, index) => {
    const errorObj = {};
    if (
      Object.values(_(values).pickBy(_.identity).value()).length &&
      !values.low?.value &&
      !values.high?.value &&
      !values.text?.trim()
    ) {
      errorObj[`low${index}`] = messageConstants.EITHER_FIELD_REQUIRED;
      errorObj[`high${index}`] = messageConstants.EITHER_FIELD_REQUIRED;
      errorObj[`text${index}`] = messageConstants.EITHER_FIELD_REQUIRED;
    } else {
      errorObj[`low${index}`] = "";
      errorObj[`high${index}`] = "";
      errorObj[`text${index}`] = "";
    }
    if (values?.age?.low?.value && !values?.age?.high?.value)
      errorObj[`highAgeValue${index}`] = messageConstants.FIELD_REQUIRED;
    else if (!values?.age?.low?.value && values?.age?.high?.value)
      errorObj[`lowAgeValue${index}`] = messageConstants.FIELD_REQUIRED;
    else if (values?.age?.low?.value && !values?.age?.low?.unit)
      errorObj[`lowAgeUnit${index}`] = messageConstants.FIELD_REQUIRED;
    else if (values?.age?.high?.value && !values?.age?.high?.unit)
      errorObj[`highAgeUnit${index}`] = messageConstants.FIELD_REQUIRED;
    else if (values?.age?.low?.unit !== values?.age?.high?.unit) {
      errorObj[`highAgeUnit${index}`] = messageConstants.UNIT_ERROR;
      errorObj[`lowAgeUnit${index}`] = messageConstants.UNIT_ERROR;
    } else if (
      values?.age?.low?.value &&
      values?.age?.high?.value &&
      Number(values?.age?.low?.value) > Number(values?.age?.high?.value)
    ) {
      errorObj[`lowAgeValue${index}`] = messageConstants.COMPARE_ERROR;
    } else {
      errorObj[`highAgeValue${index}`] = "";
      errorObj[`lowAgeValue${index}`] = "";
      errorObj[`highAgeUnit${index}`] = "";
      errorObj[`lowAgeUnit${index}`] = "";
    }
    return errorObj;
  };

  return (
    <div className="col_xs_12">
      <div className="repeater">
        {referenceRange?.length
          ? referenceRange.map((value, index) => {
              return (
                <div key={index} className="repeater__row">
                  <div className="repeater__row__fields">
                    <div className="row">
                      <div className="col_xl_4 col_md_6">
                          <Label
                            tooltip={true}
                            content="The value of the low bound of the reference range. The low bound of the reference range endpoint is inclusive of the value (e.g. reference range is >=5 - <=9). If the low bound is omitted, it is assumed to be meaningless (e.g. reference range is <=2.3)."
                            label="Low"
                            isRequired={false}
                            id={`low${index}`}
                          />
                          <input
                            type="number"
                            step="any"
                            name={`low${index}`}
                            id={`low${index}`}
                            aria-label={`low${index}`}
                            onChange={(event) => {
                              const { value } = event.target;
                              const fieldsArr = [...referenceRange];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                low: { value },
                              };
                              setReferenceRange(fieldsArr);
                              setError(validate(fieldsArr[index], index));
                            }}
                            className={
                              error[`low${index}`] ? "control error" : "control"
                            }
                            autoComplete="off"
                            value={value?.low?.value || ""}
                          />
                          {error?.[`low${index}`] ? (
                            <p className={error[`low${index}`] ? "error" : ""}>
                              {error[`low${index}`]}
                            </p>
                          ) : null}
                      </div>
                      <div className="col_xl_4 col_md_6">
                          <Label
                            tooltip={true}
                            content="The value of the high bound of the reference range. The high bound of the reference range endpoint is inclusive of the value (e.g. reference range is >=5 - <=9). If the high bound is omitted, it is assumed to be meaningless (e.g. reference range is >= 2.3)."
                            label="High"
                            isRequired={false}
                            id={`high${index}`}
                          />
                          <input
                            type="number"
                            step="any"
                            name={`high${index}`}
                            id={`high${index}`}
                            aria-label={`high${index}`}
                            onChange={(event) => {
                              const { value } = event.target;
                              const fieldsArr = [...referenceRange];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                high: { value },
                              };
                              setReferenceRange(fieldsArr);
                              setError(validate(fieldsArr[index], index));
                            }}
                            className={
                              error[`high${index}`]
                                ? "control error"
                                : "control"
                            }
                            autoComplete="off"
                            value={value?.high?.value || ""}
                          />
                          {error?.[`high${index}`] ? (
                            <p className={error[`high${index}`] ? "error" : ""}>
                              {error[`high${index}`]}
                            </p>
                          ) : null}
                      </div>
                      <div className="col_xl_4 col_md_6">
                          <Label
                            tooltip={true}
                            content="The value of the normal value of the reference range."
                            label="Normal Value"
                            isRequired={false}
                            id={`normalValue${index}`}
                          />
                          <AsyncPaginate
                            value={value?.normalValue?.coding?.[0]}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            defaultOptions
                            isSearchable
                            isClearable
                            loadOptions={extendLoadOptions}
                            onChange={(option) => {
                              const fieldsArr = [...referenceRange];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                normalValue: {
                                  coding: option && [option],
                                  text: option?.display,
                                },
                              };
                              setReferenceRange(fieldsArr);
                            }}
                            getOptionValue={(option) => option.code}
                            getOptionLabel={(option) => option.display}
                            additional={{
                              name: "ObservationReferenceRangeNormalValue",
                            }}
                            name={`normalValue${index}`}
                            id={`normalValue${index}`}
                            aria-label={`normalValue${index}`}
                            classNamePrefix={`${
                              error[`normalValue${index}`]
                                ? "select__error"
                                : "select"
                            }`}
                          />
                          {error?.[`normalValue${index}`] ? (
                            <p
                              className={
                                error[`normalValue${index}`] ? "error" : ""
                              }
                            >
                              {error[`normalValue${index}`]}
                            </p>
                          ) : null}
                      </div>
                    </div>
                      <div className="row">
                        <div className="col_xl_4 col_md_6">
                          <Label
                            tooltip={true}
                            content="Codes to indicate the what part of the targeted reference population it applies to. For example, the normal or therapeutic range."
                            label="Type"
                            isRequired={false}
                            id={`type${index}`}
                          />
                          <AsyncPaginate
                            value={value?.type?.coding?.[0]}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            defaultOptions
                            isSearchable
                            isClearable
                            loadOptions={extendLoadOptions}
                            onChange={(option) => {
                              const fieldsArr = [...referenceRange];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                type: {
                                  coding: option && [option],
                                  text: option?.display,
                                },
                              };
                              setReferenceRange(fieldsArr);
                            }}
                            getOptionValue={(option) => option.code}
                            getOptionLabel={(option) => option.display}
                            additional={{
                              name: "ObservationReferenceRangeMeaningCodes",
                            }}
                            name={`type${index}`}
                            id={`type${index}`}
                            aria-label={`type${index}`}
                            classNamePrefix={`${
                              error[`type${index}`] ? "select__error" : "select"
                            }`}
                          />
                          {error?.[`type${index}`] ? (
                            <p className={error[`type${index}`] ? "error" : ""}>
                              {error[`type${index}`]}
                            </p>
                          ) : null}
                      </div>
                      <div className="col_xl_4 col_md_6">
                          <Label
                            tooltip={true}
                            content="Codes to indicate the target population this reference range applies to. For example, a reference range may be based on the normal population or a particular sex or race. Multiple appliesTo are interpreted as an 'AND' of the target populations. For example, to represent a target population of African American females, both a code of female and a code for African American would be used."
                            label="Applies To"
                            isRequired={false}
                            id={`appliesTo${index}`}
                          />
                          <AsyncPaginate
                            value={value?.appliesTo?.coding?.[0]}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            defaultOptions
                            isSearchable
                            isClearable
                            loadOptions={extendLoadOptions}
                            onChange={(option) => {
                              const fieldsArr = [...referenceRange];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                appliesTo: {
                                  coding: option && [option],
                                  text: option?.display,
                                },
                              };
                              setReferenceRange(fieldsArr);
                            }}
                            getOptionValue={(option) => option.code}
                            getOptionLabel={(option) => option.display}
                            additional={{
                              name: "ObservationReferenceRangeAppliesToCodes",
                            }}
                            name={`appliesTo${index}`}
                            id={`appliesTo${index}`}
                            aria-label={`appliesTo${index}`}
                            classNamePrefix={`${
                              error[`appliesTo${index}`]
                                ? "select__error"
                                : "select"
                            }`}
                          />
                          {error?.[`appliesTo${index}`] ? (
                            <p
                              className={
                                error[`appliesTo${index}`] ? "error" : ""
                              }
                            >
                              {error[`appliesTo${index}`]}
                            </p>
                          ) : null}
                      </div>
                      <div className="col_xl_4 col_md_6">
                          <Label
                            tooltip={true}
                            content="Text based reference range in an observation which may be used when a quantitative range is not appropriate for an observation. An example would be a reference value of 'Negative' or a list or table of 'normals'."
                            label="Text"
                            isRequired={false}
                            id={`text${index}`}
                          />
                          <input
                            type="text"
                            name={`text${index}`}
                            id={`text${index}`}
                            aria-label={`text${index}`}
                            onChange={(event) => {
                              const { value } = event.target;
                              const fieldsArr = [...referenceRange];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                text: value,
                              };
                              setReferenceRange(fieldsArr);
                              setError(validate(fieldsArr[index], index));
                            }}
                            className={
                              error[`text${index}`]
                                ? "control error"
                                : "control"
                            }
                            autoComplete="off"
                            value={value.text || ""}
                          />
                          {error?.[`text${index}`] ? (
                            <p className={error[`text${index}`] ? "error" : ""}>
                              {error[`text${index}`]}
                            </p>
                          ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col_xl_12">
                        <div className="title--h6">Age</div>
                      </div>
                      <div className="col_xl_3 col_md_6">
                          <Label
                            tooltip={true}
                            content="The lower limit of age at which this reference range is applicable. This is a neonatal age (e.g. number of weeks at term) if the meaning says so."
                            label="Low"
                            isRequired={false}
                            id={`lowAgeValue${index}`}
                          />
                          <input
                            type="number"
                            step="any"
                            name={`lowAgeValue${index}`}
                            id={`lowAgeValue${index}`}
                            aria-label={`lowAgeValue${index}`}
                            onChange={(event) => {
                              const { value } = event.target;
                              const fieldsArr = [...referenceRange];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                age: {
                                  ...fieldsArr[index]?.age,
                                  low: { ...fieldsArr[index]?.age?.low, value },
                                },
                              };
                              setReferenceRange(fieldsArr);
                              setError(validate(fieldsArr[index], index));
                            }}
                            className={
                              error[`lowAgeValue${index}`]
                                ? "control error"
                                : "control"
                            }
                            autoComplete="off"
                            value={value?.age?.low?.value || ""}
                          />
                          {error?.[`lowAgeValue${index}`] ? (
                            <p
                              className={
                                error[`lowAgeValue${index}`] ? "error" : ""
                              }
                            >
                              {error[`lowAgeValue${index}`]}
                            </p>
                          ) : null}
                      </div>
                      <div className="col_xl_3 col_md_6">
                          <Label
                            tooltip={true}
                            content="The unit of lower limit of age at which this reference range is applicable. This is a neonatal age (e.g. number of weeks at term) if the meaning says so."
                            label="Low Unit"
                            isRequired={false}
                            id={`lowAgeUnit${index}`}
                          />
                          <AsyncPaginate
                            value={
                              value?.age?.low?.unit && {
                                code: value?.age?.low?.code,
                                display: value?.age?.low?.unit,
                              }
                            }
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            defaultOptions
                            isSearchable
                            isClearable
                            loadOptions={extendLoadOptions}
                            onChange={(option) => {
                              const fieldsArr = [...referenceRange];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                age: {
                                  ...fieldsArr[index]?.age,
                                  low: {
                                    ...fieldsArr[index]?.age?.low,
                                    unit: option?.display,
                                    code: option?.code,
                                  },
                                },
                              };
                              setReferenceRange(fieldsArr);
                              setError(validate(fieldsArr[index], index));
                            }}
                            getOptionValue={(option) => option.code}
                            getOptionLabel={(option) => option.display}
                            additional={{
                              name: "CommonUCUMCodesForAge",
                            }}
                            name={`lowAgeUnit${index}`}
                            id={`lowAgeUnit${index}`}
                            aria-label={`lowAgeUnit${index}`}
                            classNamePrefix={`${
                              error[`lowAgeUnit${index}`]
                                ? "select__error"
                                : "select"
                            }`}
                          />
                          {error?.[`lowAgeUnit${index}`] ? (
                            <p
                              className={
                                error[`lowAgeUnit${index}`] ? "error" : ""
                              }
                            >
                              {error[`lowAgeUnit${index}`]}
                            </p>
                          ) : null}
                      </div>
                      <div className="col_xl_3 col_md_6">
                          <Label
                            tooltip={true}
                            content="The higher limit of age at which this reference range is applicable. This is a neonatal age (e.g. number of weeks at term) if the meaning says so."
                            label="High"
                            isRequired={false}
                            id={`highAgeValue${index}`}
                          />
                          <input
                            type="number"
                            step="any"
                            name={`highAgeValue${index}`}
                            id={`highAgeValue${index}`}
                            aria-label={`highAgeValue${index}`}
                            onChange={(event) => {
                              const { value } = event.target;
                              const fieldsArr = [...referenceRange];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                age: {
                                  ...fieldsArr[index]?.age,
                                  high: {
                                    ...fieldsArr[index]?.age?.high,
                                    value,
                                  },
                                },
                              };
                              setReferenceRange(fieldsArr);
                              setError(validate(fieldsArr[index], index));
                            }}
                            className={
                              error[`highAgeValue${index}`]
                                ? "control error"
                                : "control"
                            }
                            autoComplete="off"
                            value={value?.age?.high?.value || ""}
                          />
                          {error?.[`highAgeValue${index}`] ? (
                            <p
                              className={
                                error[`highAgeValue${index}`] ? "error" : ""
                              }
                            >
                              {error[`highAgeValue${index}`]}
                            </p>
                          ) : null}
                      </div>
                      <div className="col_xl_3 col_md_6">
                          <Label
                            tooltip={true}
                            content="The unit of higher limit of age at which this reference range is applicable. This is a neonatal age (e.g. number of weeks at term) if the meaning says so."
                            label="High Unit"
                            isRequired={false}
                            id={`highAgeUnit${index}`}
                          />
                          <AsyncPaginate
                            value={
                              value?.age?.high?.unit && {
                                code: value?.age?.high?.code,
                                display: value?.age?.high?.unit,
                              }
                            }
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            defaultOptions
                            isSearchable
                            isClearable
                            loadOptions={extendLoadOptions}
                            onChange={(option) => {
                              const fieldsArr = [...referenceRange];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                age: {
                                  ...fieldsArr[index]?.age,
                                  high: {
                                    ...fieldsArr[index]?.age?.high,
                                    unit: option?.display,
                                    code: option?.code,
                                  },
                                },
                              };
                              setReferenceRange(fieldsArr);
                              setError(validate(fieldsArr[index], index));
                            }}
                            getOptionValue={(option) => option.code}
                            getOptionLabel={(option) => option.display}
                            additional={{
                              name: "CommonUCUMCodesForAge",
                            }}
                            name={`highAgeUnit${index}`}
                            id={`highAgeUnit${index}`}
                            aria-label={`highAgeUnit${index}`}
                            classNamePrefix={`${
                              error[`highAgeUnit${index}`]
                                ? "select__error"
                                : "select"
                            }`}
                          />
                          {error?.[`highAgeUnit${index}`] ? (
                            <p
                              className={
                                error[`highAgeUnit${index}`] ? "error" : ""
                              }
                            >
                              {error[`highAgeUnit${index}`]}
                            </p>
                          ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="repeater__row__button">
                    {index === referenceRange.length - 1 ? (
                      <button
                        onClick={() => addfields(index)}
                        className="btn btn--success"
                        aria-label="add"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    ) : null}

                    {index !== referenceRange.length - 1 ? (
                      <button
                        onClick={() => removefields(index)}
                        className="btn btn--danger"
                        aria-label="delete"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    ) : null}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
});

export default ReferenceRange;
