import {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch } from "react-redux";
import Label from "../Label";
import { AsyncPaginate } from "react-select-async-paginate";
import { FontAwesomeIcon, faTrash, faPlus } from "../../../Assets/icons";
import { appConstants } from "../../../Constants";
import { taxonomySlice } from "../../../Features";
import { toast } from "react-toastify";
import moment from "moment";

const Qualification = forwardRef(({ rows }, ref) => {
  const dispatch = useDispatch();
  const error = {};
  const [qualifications, setQualification] = useState([{}]);
  useEffect(() => {
    setQualification(rows?.length ? rows : [{}]);
  }, [rows]);

  const extendLoadOptions = useCallback(
    (searchQuery, loadedOptions, { name }) => {
      let options = [],
        rowCount = 0;
      return new Promise((resolve, reject) => {
        dispatch(
          taxonomySlice.getValueSet({
            text: searchQuery,
            name,
            offset: loadedOptions.length || appConstants.DEFAULT_SKIP,
            limit: appConstants.DEFAULT_LIMIT,
            sortBy: "createdAt",
            sortOrder: "desc",
          })
        )
          .unwrap()
          .then((response) => {
            options = response?.data?.codes;
            rowCount = response?.data?.rowCount;
            resolve({
              options,
              hasMore:
                loadedOptions.length + appConstants.DEFAULT_LIMIT < rowCount
                  ? true
                  : false,
              name,
            });
          })
          .catch((error) => {
            toast.error(error?.data?.message || error?.data);
            resolve({
              options,
              hasMore: false,
              name,
            });
          });
      });
    },
    [dispatch]
  );

  const removefields = (index) => {
    setQualification((qualifications) =>
      qualifications.filter((v, i) => i !== index)
    );
  };

  const addfields = (index) => {
    if (qualifications?.[index]?.code?.coding?.[0]?.code)
      setQualification([...qualifications, {}]);
  };

  useImperativeHandle(ref, () => ({
    handleSubmit(event) {
      event.preventDefault();
      return qualifications.filter((t) => t?.code?.coding?.[0]?.code);
    },
  }));

  return (
    <div className="col_xs_12">
      <div className="repeater">
        {qualifications?.length
          ? qualifications.map((value, index) => {
              return (
                <div key={index} className="repeater__row">
                  <div className="repeater__row__fields">
                    <div className="row">
                      <div className="col_xl_3 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="Certification, licenses, or training pertaining to the provision of care."
                            label="Qualification Code"
                            isRequired={false}
                            aria-label="qualification Code"
                          />
                          <AsyncPaginate
                            value={value?.code?.coding?.[0]}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            defaultOptions
                            isSearchable
                            isClearable
                            aria-label="Qualification Code"
                            loadOptions={extendLoadOptions}
                            onChange={(option) => {
                              const fieldsArr = [...qualifications];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                code: {
                                  coding: option && [option],
                                  text: option?.display,
                                },
                              };
                              setQualification(fieldsArr);
                            }}
                            getOptionValue={(option) => option.code}
                            getOptionLabel={(option) => option.display}
                            additional={{
                              name: "Hl7VSDegreeLicenseCertificate",
                            }}
                            name={`code${index}`}
                            id={`code${index}`}
                            classNamePrefix={`${
                              error[`code${index}`] ? "select__error" : "select"
                            }`}
                          />
                          {error?.[`code${index}`] ? (
                            <p className={error[`code${index}`] ? "error" : ""}>
                              {error[`code${index}`]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col_xl_3 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="Organization that regulates and issues the qualification"
                            label="Issuer"
                            isRequired={false}
                            id={`issuer${index}`}
                          />
                          <input
                            type="text"
                            name={`issuer${index}`}
                            id={`issuer${index}`}
                            onChange={(event) => {
                              const { value } = event.target;
                              const fieldsArr = [...qualifications];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                issuer: { display: value },
                              };
                              setQualification(fieldsArr);
                            }}
                            className={
                              error[`issuer${index}`]
                                ? "control error"
                                : "control"
                            }
                            autoComplete="off"
                            value={value?.issuer?.display || ""}
                          />
                          {error?.[`issuer${index}`] ? (
                            <p
                              className={error[`issuer${index}`] ? "error" : ""}
                            >
                              {error[`issuer${index}`]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col_xl_3 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="Period from which the qualification is valid."
                            label="Start Period"
                            isRequired={false}
                            id={`start${index}`}
                          />
                          <input
                            type="month"
                            name={`start${index}`}
                            id={`start${index}`}
                            onChange={(event) => {
                              const { value } = event.target;
                              const fieldsArr = [...qualifications];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                period: {
                                  ...fieldsArr[index].period,
                                  start: value,
                                },
                              };
                              setQualification(fieldsArr);
                            }}
                            className={
                              error[`start${index}`]
                                ? "control error"
                                : "control"
                            }
                            autoComplete="off"
                            value={
                              value?.period?.start
                                ? moment(value.period.start).format(
                                    appConstants.MONTHYEAR
                                  )
                                : ""
                            }
                          />
                          {error?.[`start${index}`] ? (
                            <p
                              className={error[`start${index}`] ? "error" : ""}
                            >
                              {error[`start${index}`]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col_xl_3 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="Period to which the qualification is valid."
                            label="End Period"
                            isRequired={false}
                            id={`end${index}`}
                          />
                          <input
                            type="month"
                            name={`end${index}`}
                            id={`end${index}`}
                            onChange={(event) => {
                              const { value } = event.target;
                              const fieldsArr = [...qualifications];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                period: {
                                  ...fieldsArr[index].period,
                                  end: value,
                                },
                              };
                              setQualification(fieldsArr);
                            }}
                            className={
                              error[`end${index}`] ? "control error" : "control"
                            }
                            autoComplete="off"
                            value={
                              value?.period?.end
                                ? moment(value.period.end).format(
                                    appConstants.MONTHYEAR
                                  )
                                : ""
                            }
                          />
                          {error?.[`end${index}`] ? (
                            <p className={error[`end${index}`] ? "error" : ""}>
                              {error[`end${index}`]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="repeater__row__button">
                    {index === qualifications.length - 1 ? (
                      <button
                        onClick={() => addfields(index)}
                        className="btn btn--success"
                        aria-label="add"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    ) : null}

                    {index !== qualifications.length - 1 ? (
                      <button
                        onClick={() => removefields(index)}
                        className="btn btn--danger"
                        aria-label="delete"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    ) : null}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
});

export default Qualification;
