import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import Label from "../Label";
import Select from "react-select";
import { FontAwesomeIcon, faTrash, faPlus } from "../../../Assets/icons";
import { appConstants, messageConstants } from "../../../Constants";
import { asyncForEach } from "../../../Utils";
import _ from "lodash";

const Endpoint = forwardRef(({ rows }, ref) => {
  const [error, setError] = useState({});
  const endpointConnectionTypeOpts = appConstants.endpointConnectionTypeOpts;
  const [endpoints, setEndpoint] = useState([{}]);
  useEffect(() => {
    setEndpoint(rows?.length ? rows : [{}]);
  }, [rows]);

  const removefields = (index) => {
    setEndpoint((endpoints) => endpoints.filter((v, i) => i !== index));
  };

  const addfields = (index) => {
    if (
      endpoints[index]?.uriAddress &&
      !Object.values(
        _(validate(endpoints[index], index)).pickBy(_.identity).value()
      ).length
    )
      setEndpoint([...endpoints, {}]);
    else setError(validate(endpoints[index], index));
  };

  useImperativeHandle(ref, () => ({
    handleSubmit(event) {
      event.preventDefault();
      let invalid = false;
      asyncForEach(endpoints, (val, index) => {
        setError(validate(val, index));
        invalid =
          invalid ||
          Object.values(_(validate(val, index)).pickBy(_.identity).value())
            .length
            ? true
            : false;
      });
      if (!invalid)
        return endpoints.filter(
          (t) => t?.uriAddress && t?.connectionType?.code
        );
    },
  }));

  const validate = (values, index) => {
    const errorObj = {};
    values.uriAddress && (!values.connectionType || !values.connectionType.code)
      ? (errorObj[`connectionType${index}`] = messageConstants.FIELD_REQUIRED)
      : (errorObj[`connectionType${index}`] = "");
    values.connectionType?.code && !values.uriAddress?.trim()
      ? (errorObj[`uriAddress${index}`] = messageConstants.FIELD_REQUIRED)
      : (errorObj[`uriAddress${index}`] = "");
    return errorObj;
  };

  return (
    <div className="col_xs_12">
      <div className="repeater">
        {endpoints?.length
          ? endpoints.map((value, index) => {
              return (
                <div key={index} className="repeater__row">
                  <div className="repeater__row__fields">
                    <div className="row">
                      <div className="col_xl_4 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="The technical base address for connecting to this endpoint."
                            label="Address"
                            isRequired={true}
                            id={`uriAddress${index}`}
                          />
                          <input
                            type="text"
                            name={`uriAddress${index}`}
                            id={`uriAddress${index}`}
                            onChange={(event) => {
                              const { value } = event.target;
                              const fieldsArr = [...endpoints];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                uriAddress: value,
                              };
                              setEndpoint(fieldsArr);
                            }}
                            className={
                              error[`uriAddress${index}`]
                                ? "control error"
                                : "control"
                            }
                            autoComplete="off"
                            value={value.uriAddress || ""}
                          />
                          {error?.[`uriAddress${index}`] ? (
                            <p
                              className={
                                error[`uriAddress${index}`] ? "error" : ""
                              }
                            >
                              {error[`uriAddress${index}`]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col_xl_4 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="Protocol/Profile/Standard to be used with this endpoint connection."
                            label="Connection Type"
                            isRequired={value.uriAddress ? true : false}
                            id={`connectionType${index}`}
                          />
                          <Select
                            value={
                              value?.connectionType?.code &&
                              endpointConnectionTypeOpts.find(
                                (c) => c?.code === value.connectionType.code
                              )
                            }
                            name={`connectionType${index}`}
                            id={`connectionType${index}`}
                            aria-label={`connectionType${index}`}
                            onChange={(option) => {
                              const fieldsArr = [...endpoints];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                connectionType: option,
                              };
                              setEndpoint(fieldsArr);
                              setError(validate(fieldsArr[index], index));
                            }}
                            isSearchable
                            isClearable
                            classNamePrefix={`${
                              error[`connectionType${index}`]
                                ? "select__error"
                                : "select"
                            }`}
                            options={endpointConnectionTypeOpts}
                            getOptionValue={(option) => option.code}
                            getOptionLabel={(option) => option.display}
                          />
                          {error?.[`connectionType${index}`] ? (
                            <p
                              className={
                                error[`connectionType${index}`] ? "error" : ""
                              }
                            >
                              {error[`connectionType${index}`]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col_xl_4 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="Additional headers / information to send as part of the notification."
                            label="Header"
                            isRequired={false}
                            id={`header${index}`}
                          />
                          <input
                            type="text"
                            name={`header${index}`}
                            id={`header${index}`}
                            onChange={(event) => {
                              const { value } = event.target;
                              const fieldsArr = [...endpoints];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                header: value,
                              };
                              setEndpoint(fieldsArr);
                            }}
                            className={
                              error[`header${index}`]
                                ? "control error"
                                : "control"
                            }
                            autoComplete="off"
                            value={value.header || ""}
                          />
                          {error?.[`header${index}`] ? (
                            <p
                              className={error[`header${index}`] ? "error" : ""}
                            >
                              {error[`header${index}`]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="repeater__row__button">
                    {index === endpoints.length - 1 ? (
                      <button
                        onClick={() => addfields(index)}
                        className="btn btn--success"
                        aria-label="add"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    ) : null}

                    {index !== endpoints.length - 1 ? (
                      <button
                        onClick={() => removefields(index)}
                        className="btn btn--danger"
                        aria-label="delete"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    ) : null}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
});

export default Endpoint;
