import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";
const initialState = {
  pageCount: 0,
  rowCount: 0,
  text: "",
  list: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const getOrganizations = createAsyncThunk(
  "organizations",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(
          `/organizations?text=${params.text}&offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}`
        )
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getOrganizationById = createAsyncThunk(
  "organizations/:id",
  async (id, thunkApi) => {
    try {
      return await axiosInstance
        .get(`/organizations/${id}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateOrganization = createAsyncThunk(
  "organizations/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/organizations/${params.id}`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const addOrganization = createAsyncThunk(
  "organizations/add",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/organizations`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deleteOrganization = createAsyncThunk(
  "organizations/delete",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .delete(`/services/${params.id}`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const { actions, reducer } = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setIntialProps: (state, action) => {
      return { ...state, ...action?.payload };
    },
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //get organizations
      .addCase(getOrganizations.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getOrganizations.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data?.organizations,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getOrganizations.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { setIntialProps, clearStatus } = actions;

const organizationSlice = {
  getOrganizations,
  getOrganizationById,
  updateOrganization,
  addOrganization,
  deleteOrganization,
  setIntialProps,
  clearStatus,
  reducer,
};

export default organizationSlice;
