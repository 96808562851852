import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";

const initialState = {
  pageCount: 0,
  rowCount: 0,
  text: "",
  organization: "",
  referralFilter: "individuals",
  list: [],
  selectedRow: null,
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const getReferrals = createAsyncThunk("referrals", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .get(
        `/referrals?text=${params.text}&offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}&organization=${params.organization}&referralFilter=${params.referralFilter}&subject=${params.subject}&id=${params.id}`
      )
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const addReferral = createAsyncThunk(
  "referrals/add",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/referrals`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateReferral = createAsyncThunk(
  "referrals/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/referrals`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deleteReferral = createAsyncThunk(
  "referrals/delete",
  async (id, thunkApi) => {
    try {
      return await axiosInstance
        .delete(`/referrals/${id}`)
        .then((response) => response.data)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const { actions, reducer } = createSlice({
  name: "referrals",
  initialState,
  reducers: {
    setIntialProps: (state, action) => {
      return { ...state, ...action?.payload };
    },
    setSelectedRow: (state, action) => {
      state.selectedRow = action?.payload;
    },
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // get referrals
      .addCase(getReferrals.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getReferrals.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data?.referrals,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getReferrals.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { setIntialProps, setSelectedRow, clearStatus } = actions;

const referralSlice = {
  getReferrals,
  addReferral,
  updateReferral,
  deleteReferral,
  setSelectedRow,
  setIntialProps,
  clearStatus,
  reducer,
};

export default referralSlice;
