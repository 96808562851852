import { forwardRef } from "react";
import Identifier from "./identifier";
import Address from "./address";
import RelatedPerson from "./relatedPerson";
import SubInput from "./subInput";
import Endpoint from "./endpoint";
import Qualification from "./qualification";
import Schedule from "./schedule";
import Participant from "./participant";
import ReferenceRange from "./referenceRange";
import ObservationValue from "./observationValue";
import Component from "./component";

const MultipleInput = forwardRef((props, ref) => {
  let name = props.name;
  return (
    <>
      {name === "Identifier" ? (
        <Identifier {...props} ref={ref} />
      ) : name === "Address" ? (
        <Address {...props} ref={ref} />
      ) : name === "RelatedPerson" ? (
        <RelatedPerson {...props} ref={ref} />
      ) : name === "SubInput" ? (
        <SubInput {...props} />
      ) : name === "Endpoint" ? (
        <Endpoint {...props} ref={ref} />
      ) : name === "Qualification" ? (
        <Qualification {...props} ref={ref} />
      ) : name === "Schedule" ? (
        <Schedule {...props} ref={ref} />
      ) : name === "Participant" ? (
        <Participant {...props} ref={ref} />
      ) : name === "ReferenceRange" ? (
        <ReferenceRange {...props} ref={ref} />
      ) : name === "ObservationValue" ? (
        <ObservationValue {...props} ref={ref} />
      ) : name === "Component" ? (
        <Component {...props} ref={ref} />
      ) : (
        ""
      )}
    </>
  );
});
export default MultipleInput;
