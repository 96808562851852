import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";

const getNotifications = createAsyncThunk(
  "notifications",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(
          `/notifications?id=${params.id}&uid=${params.uid}&offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}&category=${params.category}`
        )
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const notificationSlice = {
  getNotifications,
};

export default notificationSlice;
