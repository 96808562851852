import config from "../Config";

const appConstants = {
  ROLES: {
    SUPERADMIN: "su",
    STAFF: "staff",
    CLIENT: "user",
  },
  MODULES: {
    AGENCY360: "agency360",
    NAVIGATOR360: "navigator360",
    JOURNEY360: "journey360",
    FEATURES: "features",
    STATIC: "static",
  },
  DEFAULT_ZOOM: 9,
  DEFAULT_SKIP: 0,
  DEFAULT_LIMIT: 10,
  LONGDATE: "MMMM DD, y",
  SHORT: "M/DD/yy h:mm a",
  MEDIUM: "MMM DD, y h:mm:ss A",
  SHORTDATE: "M/DD/yy",
  MEDIUMDATE: "MMM DD, y",
  FULLDATE: "EEEE, MMMM DD, y",
  SHORTTIME: "h:mm a",
  MEDIUMTIME: "h:mm:ss a",
  DATEPICKERDATETIME: "YYYY-MM-DDTHH:mm",
  DATEPICKERDATE: "YYYY-MM-DD",
  DATEPICKERTIME: "HH:mm",
  MONTHYEAR: "YYYY-MM",
  PDFPATH: "./media/pdf2json/",
  IMAGESPATH: "./media/images/",
  ATTACHMENTSPATH: "./media/attachments/",
  smartLaunchScope:
    "launch openid profile offline_access patient/Patient.read user/Practitioner.read user/Organization.read",
  referralFilterOpts: [
    { display: "Individuals", code: "individuals" },
    { display: "Outgoing Referrals", code: "outgoing" },
    { display: "Incoming Referrals", code: "incoming" },
  ],
  referralSortOpts: [
    {
      display: "Referral Date",
      code: "createdAt",
    },
    {
      display: "Name",
      code: "subject.name.text",
    },
    {
      display: "Priority",
      code: "priority.name",
    },
    {
      display: "In/Out",
      code: "outgoing",
    },
    {
      display: "Organization",
      code: "organization.name",
    },
    {
      display: "Service Name",
      code: "performer.name",
    },
    {
      display: "Service Attributes",
      code: "showTaxonomy",
    },
  ],
  conditionSortOpts: [
    {
      display: "Recorded Date",
      code: "recordedDate",
    },
    {
      display: "Category",
      code: "category.0.coding.display",
    },
    {
      display: "Code",
      code: "code.coding.display",
    },
    {
      display: "Severity",
      code: "severity.code",
    },
    {
      display: "Verification Status",
      code: "verificationStatus.code",
    },
    {
      display: "Clinical Status",
      code: "clinicalStatus.code",
    },
  ],
  severityOpts: [
    {
      code: "24484000",
      display: "Severe",
    },
    {
      code: "6736007",
      display: "Moderate",
    },
    {
      code: "255604002",
      display: "Mild",
    },
  ],
  clinicalStatusOpts: [
    {
      code: "active",
      display: "Active",
    },
    {
      code: "recurrence",
      display: "Recurrence",
    },
    {
      code: "relapse",
      display: "Relapse",
    },
    {
      code: "inactive",
      display: "Inactive",
    },
    {
      code: "remission",
      display: "Remission",
    },
    {
      code: "resolved",
      display: "Resolved",
    },
  ],
  verificationStatusOpts: [
    {
      code: "unconfirmed",
      display: "Unconfirmed",
    },
    {
      code: "provisional",
      display: "Provisional",
    },
    {
      code: "differential",
      display: "Differential",
    },
    {
      code: "confirmed",
      display: "Confirmed",
    },
    {
      code: "refuted",
      display: "Refuted",
    },
    {
      code: "entered-in-error",
      display: "Entered in Error",
    },
  ],
  goalSortOpts: [
    {
      display: "Status Date",
      code: "statusDate",
    },
    {
      display: "Category",
      code: "category.0.coding.display",
    },
    {
      display: "Description",
      code: "description.coding.display",
    },
    {
      display: "Priority",
      code: "priority.code",
    },
    {
      display: "Achievement Status",
      code: "achievementStatus.code",
    },
    {
      display: "Lifecycle Status",
      code: "lifeCycleStatus.code",
    },
  ],
  priorityOpts: [
    {
      code: "high-priority",
      display: "High Priority",
    },
    {
      code: "medium-priority",
      display: "Medium Priority",
    },
    {
      code: "low-priority",
      display: "Low Priority",
    },
  ],
  lifeCycleStatusOpts: [
    {
      code: "proposed",
      display: "Proposed",
    },
    { code: "planned", display: "Planned" },
    { code: "accepted", display: "Accepted" },
    { code: "active", display: "Active" },
    { code: "on-hold", display: "On Hold" },
    { code: "completed", display: "Completed" },
    { code: "cancelled", display: "Cancelled" },
    { code: "entered-in-error", display: "Entered in Error" },
    { code: "rejected", display: "Rejected" },
  ],
  achievementStatusOpts: [
    {
      code: "in-progress",
      display: "In Progress",
    },
    {
      code: "improving",
      display: "Improving",
    },
    {
      code: "worsening",
      display: "Worsening",
    },
    {
      code: "no-change",
      display: "No Change",
    },
    {
      code: "achieved",
      display: "Achieved",
    },
    {
      code: "sustaining",
      display: "Sustaining",
    },
    {
      code: "not-achieved",
      display: "Not Achieved",
    },
    {
      code: "no-progress",
      display: "No Progress",
    },
    {
      code: "not-attainable",
      display: "Not Attainable",
    },
  ],
  expressedByOpts: [
    { code: "patients", display: "Patient" },
    { code: "practitionerroles", display: "Practitioner Role" },
    { code: "practitioners", display: "Practitioner" },
    { code: "relatedpersons", display: "Related Person" },
  ],
  procedureSortOpts: [
    {
      display: "Created Date",
      code: "createdAt",
    },
    {
      display: "Category",
      code: "category.coding.display",
    },
    {
      display: "Code",
      code: "code.coding.display",
    },
    {
      display: "Status",
      code: "status.code",
    },
    {
      display: "Reason Code",
      code: "reasonCode.0.coding.display",
    },
    {
      display: "Status Reason",
      code: "statusReason.coding.display",
    },
  ],
  outcomeOpts: [
    { code: "385669000", display: "Successful" },
    { code: "385671000", display: "Unsuccessful" },
    { code: "385670004", display: "Partially successful" },
  ],
  procedureStatusOpts: [
    { code: "preparation", display: "Preparation" },
    { code: "in-progress", display: "In Progress" },
    { code: "not-done", display: "Not Done" },
    { code: "on-hold", display: "On Hold" },
    { code: "stopped", display: "Stopped" },
    { code: "completed", display: "Completed" },
    { code: "entered-in-error", display: "Entered in Error" },
    { code: "unknown", display: "Unknown" },
  ],
  asserterOpts: [
    { code: "patients", display: "Patient" },
    { code: "practitionerroles", display: "Practitioner Role" },
    { code: "practitioners", display: "Practitioner" },
    { code: "relatedpersons", display: "Related Person" },
  ],
  basedOnOpts: [
    { code: "servicerequests", display: "Service Requests" },
    { code: "careplans", display: "Care Plans" },
  ],
  reasonReferenceOpts: [
    { code: "conditions", display: "Condition" },
    { code: "observations", display: "Observation" },
    { code: "procedures", display: "Procedure" },
    { code: "diagnosticreports", display: "Diagnostic Reports" },
    {
      code: "documentreferences",
      display: "Document References",
    },
  ],
  taskSortOpts: [
    {
      display: "Created Date",
      code: "createdAt",
    },
    {
      display: "Priority",
      code: "priority.code",
    },
    {
      display: "Status",
      code: "status.code",
    },
  ],
  taskCodeOpts: [
    {
      code: "make-contact",
      display: "Make Contact",
      definition:
        "Designates that the action performed is to make contact (e.g., schedule an appointment, call for results, etc.) with a designated entity (e.g., a healthcare provider, social program, etc.)",
      system:
        "http://hl7.org/fhir/us/sdoh-clinicalcare/CodeSystem/SDOHCC-CodeSystemTemporaryCodes",
    },
    {
      code: "review-material",
      display: "Review Material",
      definition:
        "Designates that the action performed is to review material (e.g., attached instructions)",
      system:
        "http://hl7.org/fhir/us/sdoh-clinicalcare/CodeSystem/SDOHCC-CodeSystemTemporaryCodes",
    },
    {
      code: "general-information",
      display: "General Information",
      definition:
        "Designates that information with respect to an action to be performed",
      system:
        "http://hl7.org/fhir/us/sdoh-clinicalcare/CodeSystem/SDOHCC-CodeSystemTemporaryCodes",
    },
    {
      code: "complete-questionnaire",
      display: "Complete Questionnaire",
      definition:
        "Code indicating a request to complete the a questionnaire - e.g. by Task or ServiceRequest",
      system: "http://hl7.org/fhir/uv/sdc/CodeSystem/temp",
    },
    {
      code: "approve",
      display: "Activate/approve",
      definition:
        "Take what actions are needed to transition the focus resource from 'draft' to 'active' or 'in-progress', as appropriate for the resource type. This may involve adding additional content, approval, validation, etc.",
      system: "http://hl7.org/fhir/CodeSystem/task-code",
    },
    {
      code: "fulfill",
      display: "Fulfill",
      definition: "Act to perform the actions described in the focus request.",
      system: "http://hl7.org/fhir/CodeSystem/task-code",
    },
    {
      code: "instantiate",
      display: "Instantiate",
      definition:
        "Act to perform the actions defined in the focus definition resource (ActivityDefinition, PlanDefinition, Questionnaire, etc.)",
      system: "",
    },
    {
      code: "abort",
      display: "Abort",
      definition:
        "Abort, cancel or withdraw the focal resource, as appropriate for the type of resource.",
      system: "http://hl7.org/fhir/CodeSystem/task-code",
    },
    {
      code: "replace",
      display: "Replace",
      definition:
        "Replace the focal resource with the specified input resource",
      system: "http://hl7.org/fhir/CodeSystem/task-code",
    },
    {
      code: "change",
      display: "Change",
      definition:
        "Update the focal resource of the owning system to reflect the content specified as the Task.focus",
      system: "http://hl7.org/fhir/CodeSystem/task-code",
    },
    {
      code: "suspend",
      display: "Suspend",
      definition:
        "Transition the focal resource from 'active' or 'in-progress' to 'suspended'",
      system: "http://hl7.org/fhir/CodeSystem/task-code",
    },
    {
      code: "resume",
      display: "Re-activate",
      definition:
        "Transition the focal resource from 'suspended' to 'active' or 'in-progress' as appropriate for the resource type.",
      system: "http://hl7.org/fhir/CodeSystem/task-code",
    },
  ],
  ownerOpts: [
    { code: "practitioners", display: "Practitioner" },
    { code: "practitionerroles", display: "Practitioner Role" },
    { code: "patients", display: "Patient" },
    { code: "relatedpersons", display: "Related Person" },
    { code: "organizations", display: "Organization" },
  ],
  taskFocusOpts: [
    {
      code: "questionnaires",
      display: "Questionnaire",
      check: "complete-questionnaire",
    },
    { code: "servicerequests", display: "Service Request", check: "fulfill" },
    {
      code: "medicationrequests",
      display: "Medication Request",
      check: "fulfill",
    },
    { code: "practitioners", display: "Practitioner", check: "make-contact" },
    {
      code: "practitionerroles",
      display: "Practitioner Role",
      check: "make-contact",
    },
    { code: "patients", display: "Patient", check: "make-contact" },
    { code: "persons", display: "Person", check: "make-contact" },
    {
      code: "relatedpersons",
      display: "Related Person",
      check: "make-contact",
    },
    { code: "organizations", display: "Organization", check: "make-contact" },
    {
      code: "documentreferences",
      display: "Document References",
      check: "review-material",
    },
  ],
  patientSortOpts: [
    {
      display: "Intake Date",
      code: "createdAt",
    },
    {
      display: "Name",
      code: "name.text",
    },
    {
      display: "Date of Birth",
      code: "birthDate",
    },
  ],
  practitionerSortOpts: [
    {
      display: "Created At",
      code: "createdAt",
    },
    {
      display: "Name",
      code: "name.text",
    },
    {
      display: "Title",
      code: "practitionerData.title",
    },
  ],
  practitionerRoleSortOpts: [
    {
      display: "Created At",
      code: "createdAt",
    },
    {
      display: "Code",
      code: "code.coding.display",
    },
    {
      display: "Practitioner Name",
      code: "practitioner.name.text",
    },
    {
      display: "Organization Name",
      code: "organization.name",
    },
    {
      display: "Healthcare Service",
      code: "healthcareService.name",
    },
    {
      display: "Specialty",
      code: "specialty.coding.display",
    },
  ],
  taskStatusOpts: [
    {
      code: "draft",
      display: "Draft",
      serviceStatus: "draft",
    },
    {
      code: "requested",
      display: "Requested",
      serviceStatus: "active",
    },
    {
      code: "received",
      display: "Received",
      serviceStatus: "active",
    },
    {
      code: "accepted",
      display: "Accepted",
      serviceStatus: "active",
    },
    {
      code: "rejected",
      display: "Rejected",
      serviceStatus: "revoked",
    },
    {
      code: "ready",
      display: "Ready",
      serviceStatus: "active",
    },
    {
      code: "cancelled",
      display: "Cancelled",
      serviceStatus: "revoked",
    },
    {
      code: "in-progress",
      display: "In Progress",
      serviceStatus: "active",
    },
    {
      code: "on-hold",
      display: "On Hold",
      serviceStatus: "on-hold",
    },
    {
      code: "failed",
      display: "Failed",
      serviceStatus: "revoked",
    },
    {
      code: "completed",
      display: "Completed",
      serviceStatus: "completed",
    },
    {
      code: "entered-in-error",
      display: "Entered in Error",
      serviceStatus: "entered-in-error",
    },
  ],
  serviceStatusOpts: [
    {
      code: "draft",
      display: "Draft",
    },
    {
      code: "active",
      display: "Active",
    },
    {
      code: "on-hold",
      display: "On Hold",
    },
    {
      code: "revoked",
      display: "Revoked",
    },
    {
      code: "completed",
      display: "Completed",
    },
    {
      code: "entered-in-error",
      display: "Entered in Error",
    },
    {
      code: "unknown",
      display: "Unknown",
    },
  ],
  genderOpts: [
    { display: "Male", code: "male" },
    { display: "Female", code: "female" },
    { display: "Other", code: "other" },
    { display: "Unknown", code: "unknown" },
  ],
  identifierUseOpts: [
    { code: "medicaid", display: "Medicaid" },
    { code: "usual", display: "Usual" },
    { code: "official", display: "Official" },
    { code: "temp", display: "Temp" },
    { code: "secondary", display: "Secondary" },
    { code: "old", display: "Old" },
  ],
  directoryOpts: [
    { display: "211 (NJ)", code: "nj211" },
    // { display: "Open City Labs", code: "ocl" },
    // { display: "National Directory", code: "natDir" },
    // { display: "HSDS Directory", code: "hsdsDir" },
  ],
  searchTypeOpts: [
    { display: "Topic", code: "topic" },
    { display: "Agency", code: "agency" },
    { display: "Program", code: "program" },
  ],
  requestPriorityOpts: [
    {
      code: "routine",
      display: "Routine",
    },
    {
      code: "urgent",
      display: "Urgent",
    },
    {
      code: "asap",
      display: "ASAP",
    },
    {
      code: "stat",
      display: "STAT",
    },
  ],
  requestIntentOpts: [
    {
      code: "proposal",
      display: "Proposal",
    },
    {
      code: "plan",
      display: "Plan",
    },
    {
      code: "directive",
      display: "Directive",
    },
    {
      code: "order",
      display: "Order",
    },
    {
      code: "original-order",
      display: "Original Order",
    },
    {
      code: "reflex-order",
      display: "Reflex Order",
    },
    {
      code: "filler-order",
      display: "Filler Order",
    },
    {
      code: "instance-order",
      display: "Instance Order",
    },
    {
      code: "option",
      display: "Option",
    },
  ],
  shareTypeOpts: [
    { code: "email", display: "Email" },
    { code: "text", display: "Text" },
    { code: "print", display: "Print" },
    { code: "fax", display: "Fax" },
    { code: "direct-address", display: "Direct Address" },
  ],
  referralTypeOpts: [
    { code: "hl7-fhir-rest", check: "fhirList", display: "FHIR" },
    {
      code: "fhir-over-direct",
      check: "fhirList",
      display: "FHIR-over-Direct",
    },
    {
      code: "secure-email",
      check: "directAddressList",
      display: "Direct Secure Message",
    },
    {
      code: "email",
      check: "emailList",
      display: "Email",
    },
    {
      code: "fax",
      check: "faxList",
      display: "Fax",
    },
    { code: "print", display: "Print" },
    {
      code: "none",
      display: "Log Referral without Messaging",
    },
  ],
  organizationTypeOpts: [
    {
      code: "prov",
      display: "Healthcare Provider",
    },
    {
      code: "dept",
      display: "Hospital Department",
    },
    {
      code: "team",
      display: "Organizational team",
    },
    {
      code: "govt",
      display: "Government",
    },
    {
      code: "ins",
      display: "Insurance Company",
    },
    {
      code: "pay",
      display: "Payer",
    },
    {
      code: "edu",
      display: "Educational Institute",
    },
    {
      code: "reli",
      display: "Religious Institution",
    },
    {
      code: "crs",
      display: "Clinical Research Sponsor",
    },
    {
      code: "cg",
      display: "Community Group",
    },
    {
      code: "bus",
      display: "Non-Healthcare Business or Corporation",
    },
    {
      code: "other",
      display: "Other",
    },
  ],
  endpointConnectionTypeOpts: [
    {
      code: "fax",
      display: "Fax",
      system: config.BACKEND_URL,
    },
    {
      code: "email",
      display: "Email",
      system: config.BACKEND_URL,
    },
    {
      code: "hl7-fhir-rest",
      display: "HL7 FHIR",
      system: "http://terminology.hl7.org/CodeSystem/endpoint-connection-type",
    },
    {
      code: "secure-email",
      display: "Direct Secure Email",
      system: "http://terminology.hl7.org/CodeSystem/endpoint-connection-type",
    },
  ],
  nameUseOpts: [
    { code: "usual", display: "Usual" },
    { code: "official", display: "Official" },
    { code: "temp", display: "Temporary" },
    { code: "nickname", display: "Nickname" },
    { code: "anonymous", display: "Anonymous" },
    { code: "old", display: "Old" },
    { code: "maiden", display: "Maiden" },
  ],
  addressTypeOpts: [
    { code: "postal", display: "Postal" },
    { code: "physical", display: "Physical" },
    { code: "both", display: "Both" },
  ],
  purposeOpts: [
    { code: "BILL", display: "Billing" },
    { code: "ADMIN", display: "Administrative" },
    { code: "PAYOR", display: "Payor" },
    { code: "PATINF", display: "Patient" },
    { code: "PRESS", display: "Press" },
  ],
  relationOpts: [
    { code: "BP", display: "Billing contact person" },
    { code: "CP", display: "Contact person" },
    { code: "EP", display: "Emergency contact person" },
    { code: "PR", display: "Person preparing referral" },
    { code: "E", display: "Employer" },
    { code: "C", display: "Emergency Contact" },
    { code: "F", display: "Federal Agency" },
    { code: "I", display: "Insurance Company" },
    { code: "N", display: "Next-of-Kin" },
    { code: "S", display: "State Agency" },
    { code: "O", display: "Other" },
    { code: "U", display: "Unknown" },
  ],
  careTeamSortOpts: [
    {
      display: "Created Date",
      code: "createdAt",
    },
    {
      display: "Name",
      code: "name",
    },
    {
      display: "Category",
      code: "category.0.coding.display",
    },
    {
      display: "Status",
      code: "status.code",
    },
    {
      display: "Managing Organization",
      code: "managingOrganization.name",
    },
  ],
  careTeamStatusOpts: [
    {
      code: "proposed",
      display: "Proposed",
      definition:
        "The care team has been drafted and proposed, but not yet participating in the coordination and delivery of patient care.",
    },
    {
      code: "active",
      display: "Active",
      definition:
        "The care team is currently participating in the coordination and delivery of care.",
    },
    {
      code: "suspended",
      display: "Suspended",
      definition:
        "The care team is temporarily on hold or suspended and not participating in the coordination and delivery of care.",
    },
    {
      code: "inactive",
      display: "Inactive",
      definition:
        "The care team was, but is no longer, participating in the coordination and delivery of care.",
    },
    {
      code: "entered-in-error",
      display: "Entered in Error",
      definition: "The care team should have never existed.",
    },
  ],
  participantMemberOpts: [
    { code: "practitioners", display: "Practitioner" },
    {
      code: "practitionerroles",
      display: "Practitioner Role",
    },
    {
      code: "relatedpersons",
      display: "Related Person",
    },
    { code: "patients", display: "Patient" },
    { code: "organizations", display: "Organization" },
    { code: "careteams", display: "Care Team" },
  ],
  carePlanSortOpts: [
    {
      display: "Created Date",
      code: "createdAt",
    },
    {
      display: "Title",
      code: "title",
    },
    {
      display: "Category",
      code: "category.0.coding.display",
    },
    {
      display: "Status",
      code: "status.code",
    },
    {
      display: "Intent",
      code: "intent.code",
    },
  ],
  carePlanStatusOpts: [
    {
      code: "draft",
      display: "Draft",
    },
    {
      code: "active",
      display: "Active",
    },
    {
      code: "on-hold",
      display: "On Hold",
    },
    {
      code: "revoked",
      display: "Revoked",
    },
    {
      code: "completed",
      display: "Completed",
    },
    {
      code: "entered-in-error",
      display: "Entered in Error",
    },
    {
      code: "unknown",
      display: "Unknown",
    },
  ],
  carePlanIntentOpts: [
    {
      code: "proposal",
      display: "Proposal",
    },
    {
      code: "plan",
      display: "Plan",
    },
    {
      code: "order",
      display: "Order",
    },
    {
      code: "option",
      display: "Option",
    },
  ],
  observationSortOpts: [
    {
      display: "Created Date",
      code: "createdAt",
    },
    {
      display: "Category",
      code: "category.0.coding.display",
    },
    {
      display: "Code",
      code: "code.coding.display",
    },
    {
      display: "Status",
      code: "status",
    },
    {
      display: "Interpretation",
      code: "interpretation.0.coding.display",
    },
  ],
  observationStatusOpts: [
    { code: "registered", display: "Registered" },
    { code: "preliminary", display: "Preliminary" },
    { code: "final", display: "Final" },
    { code: "amended", display: "Amended" },
    { code: "corrected", display: "Corrected" },
    { code: "cancelled", display: "Cancelled" },
    { code: "entered-in-error", display: "Entered in Error" },
    { code: "unknown", display: "Unknown" },
  ],
  formTypeOpts: [
    { code: "application", display: "Application" },
    { code: "compliance", display: "Compliance" },
    { code: "intake", display: "Intake" },
    { code: "referral", display: "Referral" },
    { code: "others", display: "Others" },
  ],
  formAccessibilityOpts: [
    { code: "internal", display: "Internal" },
    { code: "external", display: "External" },
    { code: "public", display: "Public" },
  ],
  serviceSortOpts: [
    {
      display: "Created Date",
      code: "createdAt",
    },
    {
      display: "Name",
      code: "name",
    },
    {
      display: "Organization Name",
      code: "organizations.name",
    },
  ],
  organizationSortOpts: [
    {
      display: "Created Date",
      code: "createdAt",
    },
    {
      display: "Name",
      code: "name",
    },
  ],
  questionnaireSortOpts: [
    {
      display: "Created Date",
      code: "createdAt",
    },
    {
      display: "Title",
      code: "title",
    },
    {
      display: "Name",
      code: "name",
    },
    {
      display: "Code",
      code: "code.0.coding.display",
    },
    {
      display: "Version",
      code: "version",
    },
  ],
  questionnaireStatusOpts: [
    {
      code: "draft",
      display: "Draft",
    },
    {
      code: "active",
      display: "Active",
    },
    {
      code: "retired",
      display: "Retired",
    },
    {
      code: "unknown",
      display: "Unknown",
    },
  ],
  questionnaireResponseStatusOpts: [
    {
      code: "in-progress",
      display: "In Progress",
      definition:
        "This QuestionnaireResponse has been partially filled out with answers but changes or additions are still expected to be made to it.",
      system: "http://hl7.org/fhir/questionnaire-answers-status",
    },
    {
      code: "completed",
      display: "Completed",
      definition:
        "This QuestionnaireResponse has been filled out with answers and the current content is regarded as definitive.",
      system: "http://hl7.org/fhir/questionnaire-answers-status",
    },
    {
      code: "amended",
      display: "Amended",
      definition:
        "This QuestionnaireResponse has been filled out with answers, then marked as complete, yet changes or additions have been made to it afterwards.",
      system: "http://hl7.org/fhir/questionnaire-answers-status",
    },
    {
      code: "entered-in-error",
      display: "Entered in Error",
      definition: "This QuestionnaireResponse was entered in error and voided.",
      system: "http://hl7.org/fhir/questionnaire-answers-status",
    },
    {
      code: "stopped",
      display: "Stopped",
      definition:
        "This QuestionnaireResponse has been partially filled out with answers but has been abandoned. It is unknown whether changes or additions are expected to be made to it.",
      system: "http://hl7.org/fhir/questionnaire-answers-status",
    },
  ],
  performerOpts: [
    { code: "practitioners", display: "Practitioner" },
    {
      code: "practitionerroles",
      display: "Practitioner Role",
    },
    {
      code: "relatedpersons",
      display: "Related Person",
    },
    { code: "patients", display: "Patient" },
    { code: "organizations", display: "Organization" },
    { code: "careteams", display: "Care Team" },
  ],
  observationValueTypeOpts: [
    { code: "valueQuantity", display: "Quantity" },
    { code: "valueCodeableConcept", display: "Codeable Concept" },
    { code: "valueString", display: "String" },
    { code: "valueBoolean", display: "Boolean" },
    { code: "valueInteger", display: "Integer" },
    { code: "valueRange", display: "Range" },
    { code: "valueRatio", display: "Ratio" },
    { code: "valueSampledData", display: "Sampled Data" },
    { code: "valueTime", display: "Time" },
    { code: "valueDateTime", display: "Date Time" },
    { code: "valuePeriod", display: "Period" },
  ],
  fieldTypeOpts: [
    { code: "text", display: "Text" },
    { code: "number", display: "Number" },
    { code: "email", display: "Email" },
    { code: "textarea", display: "Multi Line" },
    { code: "radio", display: "Radio" },
    { code: "checkbox", display: "Checkbox" },
    { code: "select", display: "Drop-down" },
    { code: "multiselect", display: "Multi Select Drop-down" },
    { code: "date", display: "Date" },
    { code: "time", display: "Time" },
    { code: "datetime", display: "Date Time" },
  ],
  textValidationOpts: [
    { code: "isPhone", display: "Phone" },
    { code: "isUrl", display: "Url" },
    { code: "isPostalCode", display: "Postal Code" },
  ],
  numberValidationOpts: [
    { code: "isInteger", display: "Integer" },
    { code: "isDecimal", display: "Decimal" },
  ],
  mapCollectionOpts: [
    { code: "forms", display: "Forms" },
    { code: "eligibilityconditions", display: "Eligibility Conditions" },
    { code: "questionnaires", display: "Questionnaires" },
    { code: "patients", display: "Patients" },
    { code: "persons", display: "Persons" },
    { code: "relatedpersons", display: "Related Persons" },
    { code: "conditions", display: "Conditions" },
    { code: "goals", display: "Goals" },
    { code: "procedures", display: "Procedures" },
    { code: "tasks", display: "Tasks" },
  ],
  aliasSortOpts: [
    {
      display: "Created Date",
      code: "createdAt",
    },
    {
      display: "Title",
      code: "title",
    },
    {
      display: "Name",
      code: "name",
    },
    {
      display: "Version",
      code: "version",
    },
  ],
  formSortOpts: [
    {
      display: "Created Date",
      code: "createdAt",
    },
    {
      display: "Title",
      code: "title",
    },
    {
      display: "Name",
      code: "name",
    },
    {
      display: "Code",
      code: "code.0.coding.display",
    },
    {
      display: "Version",
      code: "version",
    },
  ],
  rbacPermissions: [
    { code: "1", display: "Create", definition: "create" },
    { code: "2", display: "Read", definition: "read" },
    { code: "3", display: "Update", definition: "update" },
    { code: "4", display: "Delete", definition: "delete" },
  ],
  customerSortOpts: [
    {
      display: "Created Date",
      code: "createdAt",
    },
    {
      display: "Name",
      code: "name",
    },
  ],
  resourceSortOpts: [
    {
      display: "Created Date",
      code: "createdAt",
    },
    {
      display: "Name",
      code: "name",
    },
  ],
  resourceModuleOpts: [
    { code: "agency360", display: "AGENCY360" },
    { code: "navigator360", display: "NAVIGATOR360" },
    { code: "journey360", display: "JOURNEY360" },
    { code: "features", display: "FEATURES" },
  ],
};

export default appConstants;
