const Privacy = () => {
  return (
    <div className="page_content privacy">
      <h1 className="title--h2 title--regular">Privacy Policy</h1>
      <div className="privacy__content">
        <p>
          Open City Labs, Inc. ("Open City Labs") provides a hotline for members
          of the public to report potential compliance concerns. If you would
          like to raise a compliance-related concern, please dial 1 (802)
          489-6737.
          <small className="block">Last Updated: December 18, 2022</small>
        </p>
        <p>
          We take your privacy seriously. This policy is about how we keep your
          information safe while helping you get connected to services.
        </p>

        <h2 className="title--h5">Who are we?</h2>
        <p>
          Open City Labs connects people to health and social care providers and
          to the data they need to improve health and well-being. We are a
          mission driven organization committed to empowering people to live
          their most healthy and fulfilling lives by enabling services to be
          universally accessible, individually personalized and one click away.
        </p>
        <p>
          Our platform can help you access healthcare and social services
          provided by a group of practitioners and organizations that we call
          your Care Team.
        </p>

        <h2 className="title--h5">Who is part of your Care Team?</h2>
        <p>
          Open City Labs works with healthcare providers that include, but are
          not limited to (hospitals, federally qualified community health
          centers, visiting nurses, health insurance plans, health information
          exchanges) and social care providers that include, but are not limited
          to (food pantries, educational institutions, homeless shelters) and
          government agencies to get you connected to services and connect
          practitioners of those organizations to the data and technology they
          need to deliver the best care. Your Care Team are the people and
          organizations who provide care to the you and your community.
        </p>
        <p>
          Network Participants offer several services, such as clothing and
          household goods, mental or behavioral health treatment, veteran
          services, and financial assistance.
        </p>
        <p>
          The Network Participants are here to help you no matter your needs.
          Our tool allows them to come together to better serve you.
        </p>

        <h2 className="title--h5">How do you get my information?</h2>
        <p>
          We are a technology company that helps your Care Team coordinate care
          for you. We receive information directly from the Care Team so that we
          can all better serve you. You may also provide your information
          through our online forms. We also receive information from publicly
          available sources.
        </p>
        <p>
          No matter the source of information, Open City Labs is strongly
          committed to protecting your personal information.
        </p>

        <h2 className="title--h5">What type of information do you collect?</h2>
        <p>
          The only information required to get you help through Open City Labs
          is your name and date of birth and address. You may share other
          details about yourself, such as household information, contact
          information for you or your family, age, race, gender identity, tribal
          affiliation, and military service. You always decide what information
          you want to share.
        </p>
        <p>
          Network Participants or your Care Team may require more information to
          confirm your care needs and eligibility. Examples include:
        </p>
        <ul>
          <li>Healthcare conditions and insurance benefits</li>
          <li>Financial information and job status</li>
          <li>Housing status</li>
          <li>Government benefit status</li>
        </ul>
        <p>and other information needed to connect you with services.</p>
        <p>
          Some details you provide may be a special type of information called
          “protected health information” or “personal information” under federal
          or state laws. We have built our technology to limit who can view your
          information and prevent inappropriate access to your information. To
          protect your confidentiality, we can track who provided your
          information, who accessed it, and whether it has been shared with
          other Network Participants or your Care Team.
        </p>

        <h2 className="title--h5">
          How may Open City Labs use or share my information?
        </h2>
        <p>
          We only use or share your information as described in this privacy
          policy or as required by law. Here are a few examples of how your
          information may be used or shared:
        </p>
        <ul>
          <li>
            <strong>To contact you, a family member, or caregiver:</strong> You
            may be asked to share contact information for yourself, or others
            involved in your care, like a family member or an aide. Open City
            Labs and Network Participants may call, text, or email to help
            connect you with services, understand your needs, or update you
            about your care. You will only be contacted if you agree, and we are
            allowed to do so.
          </li>
          <li>
            <strong>
              For referrals for treatment, care coordination, care management
              and case management:
            </strong>{" "}
            Open City Labs and Network Participants may use or share your
            information to get you the health and social care you need. This
            includes coordinating your care, managing your case, and evaluating
            the impact of Network Participant services and the Network.
          </li>
          <li>
            <strong>For qualification for benefits:</strong> Your information
            may be used to see if you are eligible for any government or private
            benefits or programs.
          </li>
          <li>
            <strong>For payment of services:</strong> Open City Labs and Network
            Participants may share your information with government agencies or
            other providers and organizations paying for services.
          </li>
          <li>
            <strong>For business operations:</strong> Open City Labs may use and
            share your information for our business operations. Open City Labs
            may also use your information to improve our products and
            investigate any complaints. Additionally, to keep the Network
            running, Open City Labs may share your information with our service
            providers, such as technology, accounting, legal, and research
            services. Our service providers are required to keep your
            information safe and follow privacy laws, just like we do.
          </li>
          <li>
            <strong>Marketing:</strong> Open City Labs will not use your
            information for marketing purposes unless you expressly agree to
            receive marketing communications.
          </li>
          <li>
            <strong>If required by law:</strong> Open City Labs may be required
            to share your information if required by law. This could include
            responding to a regulatory agency, or to satisfy a subpoena or
            similar legal process. If any information is required to be released
            by law, Open City Labs will take appropriate legal action to protect
            the information.
          </li>
        </ul>

        <h2 className="title--h5">
          Non-Personal Information We Collect Automatically
        </h2>
        <p>
          We may automatically collect the following information about your use
          of our Site or Services through cookies, web beacons, and other
          technologies: your domain name; your browser type and operating
          system; web pages you view; links you click; your IP address; the
          length of time you visit our Site or use our Services; and the
          referring URL, or the webpage that led you to our Site, and the
          following: access time, browser type, device ID, domain name, IP
          address, page views and referring URL. We also may collect the
          following information about your use of the Application: mobile device
          ID; location and language information; device name and model;
          operating system type, name, and version; your activities within the
          Application; and the length of time that you are logged into our
          Application. We may combine this information with other information
          that we have collected about you, including, where applicable, your
          username, name, and other personal information. Please see the section
          “Our Use of Cookies and Other Tracking Mechanisms” below for more
          information.
        </p>

        <p>
          <strong>Our Use of Cookies and Other Tracking Mechanisms</strong>
        </p>
        <p>
          We and our third-party service providers use cookies and other
          tracking mechanisms to track information about your use of our Site or
          Services. We may combine this information with other personal
          information we collect from you (and our third-party service providers
          may do so on our behalf).
        </p>
        <p>
          Currently, our systems do not recognize browser “do-not-track”
          requests. You may, however, disable certain tracking as discussed in
          this section (e.g., by disabling cookies); you also may opt-out of
          targeted advertising by following the instructions in the Third-Party
          Ad Network section.
        </p>

        <p>
          <strong>Cookies:</strong> Cookies are alphanumeric identifiers that we
          transfer to your computer’s hard drive through your web browser for
          record-keeping purposes. Some cookies allow us to make it easier for
          you to navigate our Site and Services, while others are used to enable
          a faster log-in process or to allow us to track your activities at our
          Site and Service. There are two types of cookies: session and
          persistent cookies.
        </p>

        <p>
          <strong>Session Cookies:</strong> Session cookies exist only during an
          online session. They disappear from your computer when you close your
          browser or turn off your computer. We use session cookies to allow our
          systems to uniquely identify you during a session or while you are
          logged into the Site. This allows us to process your online
          transactions and requests and verify your identity, after you have
          logged in, as you move through our Site.
        </p>

        <p>
          <strong>Persistent Cookies:</strong> Persistent cookies remain on your
          computer after you have closed your browser or turned off your
          computer. We do not currently use persistent cookies on the Site.
        </p>

        <p>
          <strong>Disabling Cookies:</strong> Most web browsers automatically
          accept cookies, but if you prefer, you can edit your browser options
          to block them in the future. The Help portion of the toolbar on most
          browsers will tell you how to prevent your computer from accepting new
          cookies, how to have the browser notify you when you receive a new
          cookie, or how to disable cookies altogether. Visitors to our Site who
          disable cookies will be able to browse certain areas of the Site, but
          some features may not function.
        </p>

        <p>
          <strong>Local Storage Objects:</strong> We may use Flash Local Storage
          Objects (“Flash LSOs”) to store your Site preferences and to
          personalize your visit. Flash LSOs are different from browser cookies
          because of the amount and type of data stored. Typically, you cannot
          control, delete, or disable the acceptance of Flash LSOs through your
          web browser. For more information on Flash LSOs, or to learn how to
          manage your settings for Flash LSOs, go to the Adobe Flash Player Help
          Page, choose “Global Storage Settings Panel,” and follow the
          instructions. To see the Flash LSOs currently on your computer, choose
          “Website Storage Settings Panel” and follow the instructions to review
          and, if you choose, to delete any specific Flash LSO.
        </p>

        <h2 className="title--h6">Third Party Analytics</h2>
        <p>
          We use automated devices and applications, such as Google Analytics,
          to evaluate usage of our Site and, to the extent permitted, our
          Applications. We also may use other analytic means to evaluate our
          Services. We use these tools to help us improve our Services,
          performance, and user experiences. These entities may use cookies and
          other tracking technologies to perform their services. We do not share
          your personal information with these third parties.
        </p>

        <p>
          With respect to Google Analytics specifically, we may collect the some
          or all the following information about your use of our Site and
          Applications, and we may be able to connect this information to other
          information we have about you:
        </p>
        <p>
          Pages visited, time of visit, and time spent on each page of the
          webpages
          <br />
          Type of web browser
          <br />
          Type of operating system (OS)
          <br />
          Screen resolution
          <br />
          IP address
          <br />
          Third-Party Links
        </p>

        <p>
          Our Site and Services may contain links to third-party websites. Any
          access to and use of such linked websites is not governed by this
          Policy, but instead is governed by the privacy policies of those
          third-party websites. We are not responsible for the information
          practices of such third-party websites.
        </p>

        <p>
          <strong>Security of Personal Information</strong>
        </p>

        <p>
          We use reasonable administrative, technical, and physical measures to
          protect Personal Information under our control, and Protected Health
          Information is specifically per the HIPAA rules. Unfortunately, no
          data transmission over the Internet or data storage system can be
          guaranteed to be 100% secure. If you have reason to believe that your
          interaction with us is no longer secure (for example, if you feel that
          the security of any account you might have with us has been
          compromised), please immediately notify us of the problem by
          contacting us at{" "}
          <a href="mailto:legal@opencitylabs.com">legal@opencitylabs.com</a>.
        </p>

        <p>
          You should take steps to protect against unauthorized access to your
          password, phone, and computer by, among other things, signing off
          after using a shared computer, choosing a robust password that nobody
          else knows or can easily guess, and keeping your log-in and password
          private. We are not responsible for any lost, stolen, or compromised
          passwords or for any activity on your account via unauthorized
          password activity.
        </p>

        <h2 className="title--h5">Is there anything else I need to know?</h2>
        <p>
          <strong className="block">We protect your information.</strong>
          Open City Labs cares about and protects your private information. We
          follow all applicable laws and industry best practices to protect your
          information.
        </p>

        <p>
          You may modify Personal Information that you have submitted by logging
          into your account and updating your profile information. Please note
          that copies of information that you have updated, modified, or deleted
          may remain viewable in cached and archived pages of the Site or
          Application for a period of time.
        </p>

        <p>
          You may also contact us directly if you would like to review, correct,
          update, delete or otherwise limit our use of your Personal Information
          that has been previously provided to us by sending us an email at{" "}
          <a href="mailto:legal@opencitylabs.com">legal@opencitylabs.com</a>.{" "}
        </p>
        <p>
          In your request, please make clear what information you would like to
          have changed, whether you would like to have your Personal Information
          deleted from our database or otherwise let us know what limitations
          you would like to put on our use of your Personal Information. We will
          try to comply with your request as soon as reasonably practicable.
          Please note that in order to comply with certain requests to limit use
          of your Personal Information we may need to terminate your account
          with us and your ability to access and use the Services, and you agree
          that we will not be liable to you for such termination. Although we
          will use reasonable efforts to do so, you understand that it may not
          be technologically possible to remove from our systems every record of
          your Personal Information. The need to back up our systems to protect
          information from inadvertent loss means a copy of your Personal
          Information may exist in a non-erasable form that will be difficult or
          impossible for us to locate or remove. We may retain your information
          only as required by law.
        </p>

        <h2 className="title--h5">What about my sensitive information?</h2>
        <p>
          Our technology enables care coordination while protecting sensitive
          information. We have built protections to limit the visibility of
          sensitive information. This includes referrals and services that are
          related to substance use and subject to 42 CFR Part 2, HIV/AIDS
          support, and other information protected under federal and/or state
          privacy laws.
        </p>

        <h2 className="title--h5">Special Notice to California Residents</h2>
        <p>
          This section applies only to those living in California and describes
          additional rights available to them.
        </p>
        <ul>
          <li>
            You have the right to ask us to delete your information from our
            records. We may keep information if we or our Network Participants
            are required to do so by law.
          </li>
          <li>
            You have the right to request a copy of the information collected
            about you.
          </li>
          <li>
            You have the right to request whether your information was sold or
            shared.
          </li>
        </ul>

        <p>
          We may ask you to verify your identity if you make these requests. We
          will not discriminate against you for making these requests.
        </p>

        <p>
          These rights do not apply to information covered by HIPAA or the
          California Confidentiality of Medical Information Act. They also do
          not apply to publicly available information from government records,
          de-identified or aggregated information, or personal information
          covered by other privacy laws. We do not sell personal information as
          it is described under the California Consumer Protection Act.
        </p>

        <h2 className="title--h5">General Information</h2>
        <p>
          <strong>Requests:</strong> Please submit all requests, complaints, and
          concerns in writing to compliance@opencitylabs.com or through our
          toll-free number at (800) 461-9330. If you no longer want your
          information shared on the Network or if you want to request removal of
          your personal information, you can email{" "}
          <a href="mailto:consent@opencitylabs.com">consent@opencitylabs.com</a>
          .
        </p>

        <p>
          <strong>Changes to this policy:</strong> Open City Labs reserves the
          right to change this policy at any time and will post any changes to
          this website.
        </p>
        <p>
          <strong>Translations:</strong> We have this policy available in
          languages other than English. The translated versions are provided for
          convenience and do not change the English version.
        </p>
        <p>
          <strong>Notice to website users located outside the U.S.:</strong>{" "}
          Open City Labs operates in accordance with the laws of the U.S. When
          you access our website from outside the U.S., we may transfer the
          personal information that we collect from you to a location outside of
          your jurisdiction, including the U.S. The data protection laws in
          these jurisdictions may not provide you with the same protections as
          those of your jurisdiction. By using this website, you acknowledge
          that these laws may provide a different standard of protection, and
          you consent to the transfer of your personal data to other
          jurisdictions, including the U.S.
        </p>
        <p>
          <strong>Children:</strong> Open City Labs is intended for a general
          audience and is not directed at children under 13 years of age. If you
          are a parent or guardian and you believe we have collected information
          from your child in a manner not permitted by law, please contact us at{" "}
          <a href="mailto:compliance@opencitylabs.com">
            compliance@opencitylabs.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default Privacy;
