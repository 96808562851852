import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";

const initialState = {
  active: false,
  showMap: false,
  showUniversalSearch: false,
  patientImageModal: false,
  modalProps: {},
};

const uploadFile = createAsyncThunk("common/file", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .post(`/common/file`, params)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const { actions, reducer } = createSlice({
  name: "common",
  initialState,
  reducers: {
    setIsActive: (state, action) => {
      state.active = action?.payload;
    },
    setShowUniversalSearch: (state, action) => {
      state.showUniversalSearch = action?.payload;
    },
    setShowMap: (state, action) => {
      state.showMap = action?.payload;
    },
    setPatientImageModal: (state, action) => {
      state.patientImageModal = action?.payload;
    },
  },
});

const {
  setIsActive,
  setShowUniversalSearch,
  setShowMap,
  setPatientImageModal,
} = actions;

const getIsActive = (state) => state.common.active;
const getShowMap = (state) => state.common.showMap;
const getShowUniversalSearch = (state) => state.common.showUniversalSearch;
const getPatientImageModal = (state) => state.common.patientImageModal;

const commonSlice = {
  getIsActive,
  getShowMap,
  getShowUniversalSearch,
  getPatientImageModal,
  uploadFile,
  setIsActive,
  setShowUniversalSearch,
  setShowMap,
  setPatientImageModal,
  reducer,
};

export default commonSlice;
