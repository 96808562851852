import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { useDispatch } from "react-redux";
import Label from "../Label";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { appConstants, messageConstants } from "../../../Constants";
import { taxonomySlice } from "../../../Features";
import { toast } from "react-toastify";
import moment from "moment";
import _ from "lodash";

const ObservationValue = forwardRef(({ value }, ref) => {
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const observationValueTypeOpts = appConstants.observationValueTypeOpts;
  const [observationValue, setObservationValue] = useState({});
  useEffect(() => {
    let valueType;
    if (value?.valueQuantity?.value) valueType = "valueQuantity";
    if (value?.valueCodeableConcept?.coding?.[0]?.code)
      valueType = "valueCodeableConcept";
    if (value?.valueString?.trim() && typeof value.valueString === "string")
      valueType = "valueString";
    if (value?.valueBoolean === true || value?.valueBoolean === false)
      valueType = "valueBoolean";
    if (value?.valueInteger?.trim() && typeof value.valueInteger === "number")
      valueType = "valueInteger";
    if (value?.valueRange?.low?.value && value?.valueRange?.high?.value)
      valueType = "valueRange";
    if (
      value?.valueRatio?.numerator?.value &&
      value?.valueRatio?.denominator?.value
    )
      valueType = "valueRatio";
    if (value?.valueSampledData?.origin) valueType = "valueSampledData";
    if (value?.valueTime) valueType = "valueTime";
    if (value?.valueDateTime) valueType = "valueDateTime";
    if (value?.valuePeriod?.start || value?.valuePeriod?.end)
      valueType = "valuePeriod";
    setObservationValue(value ? { ...value, valueType } : {});
  }, [value]);

  const extendLoadOptions = useCallback(
    (searchQuery, loadedOptions, { name }) => {
      let options = [],
        rowCount = 0;
      return new Promise((resolve, reject) => {
        dispatch(
          taxonomySlice.getValueSet({
            text: searchQuery,
            name,
            offset: loadedOptions.length || appConstants.DEFAULT_SKIP,
            limit: appConstants.DEFAULT_LIMIT,
            sortBy: "createdAt",
            sortOrder: "desc",
          })
        )
          .unwrap()
          .then((response) => {
            options = response?.data?.codes;
            rowCount = response?.data?.rowCount;
            resolve({
              options,
              hasMore:
                loadedOptions.length + appConstants.DEFAULT_LIMIT < rowCount
                  ? true
                  : false,
              name,
            });
          })
          .catch((error) => {
            toast.error(error?.data?.message || error?.data);
            resolve({
              options,
              hasMore: false,
              name,
            });
          });
      });
    },
    [dispatch]
  );

  useImperativeHandle(ref, () => ({
    handleSubmit(event) {
      event.preventDefault();
      if (
        !Object.values(_(validate(observationValue)).pickBy(_.identity).value())
          .length
      )
        return observationValue;
    },
  }));

  const validate = (values) => {
    console.log(
      "check...",
      values?.valueRange,
      typeof values?.valueRange?.low?.value,
      Number(values?.valueRange?.low?.value)
    );
    const errorObj = {};
    if (values?.valueRange?.low?.value && !values?.valueRange?.high?.value)
      errorObj["valueRangeHighValue"] = messageConstants.FIELD_REQUIRED;
    else if (!values?.valueRange?.low?.value && values?.valueRange?.high?.value)
      errorObj["valueRangeLowValue"] = messageConstants.FIELD_REQUIRED;
    else if (values?.valueRange?.low?.value && !values?.valueRange?.low?.unit)
      errorObj["valueRangeLowUnit"] = messageConstants.FIELD_REQUIRED;
    else if (values?.valueRange?.high?.value && !values?.valueRange?.high?.unit)
      errorObj["valueRangeHighUnit"] = messageConstants.FIELD_REQUIRED;
    else if (values?.valueRange?.low?.unit !== values?.valueRange?.high?.unit) {
      errorObj["valueRangeHighUnit"] = messageConstants.UNIT_ERROR;
      errorObj["valueRangeLowUnit"] = messageConstants.UNIT_ERROR;
    } else if (
      values?.valueRange?.low?.value &&
      values?.valueRange?.high?.value &&
      Number(values?.valueRange?.low?.value) >
        Number(values?.valueRange?.high?.value)
    )
      errorObj["valueRangeLowValue"] = messageConstants.COMPARE_ERROR;
    else {
      errorObj["valueRangeHighValue"] = "";
      errorObj["valueRangeLowValue"] = "";
      errorObj["valueRangeHighUnit"] = "";
      errorObj["valueRangeLowUnit"] = "";
    }

    if (
      values?.valueRatio?.numerator?.value &&
      !values?.valueRatio?.denominator?.value
    )
      errorObj["valueRatioDenominatorValue"] = messageConstants.FIELD_REQUIRED;
    else if (
      !values?.valueRatio?.numerator?.value &&
      values?.valueRatio?.denominator?.value
    )
      errorObj["valueRatioNumeratorValue"] = messageConstants.FIELD_REQUIRED;
    else if (
      values?.valueRatio?.numerator?.value &&
      !values?.valueRatio?.numerator?.unit
    )
      errorObj["valueRatioNumeratorUnit"] = messageConstants.FIELD_REQUIRED;
    else if (
      values?.valueRatio?.denominator?.value &&
      !values?.valueRatio?.denominator?.unit
    )
      errorObj["valueRatioDenominatorUnit"] = messageConstants.FIELD_REQUIRED;
    else if (
      values?.valueRatio?.numerator?.unit !==
      values?.valueRatio?.denominator?.unit
    ) {
      errorObj["valueRatioNumeratorUnit"] = messageConstants.UNIT_ERROR;
      errorObj["valueRatioDenominatorUnit"] = messageConstants.UNIT_ERROR;
    } else if (
      values?.valueRatio?.numerator?.value &&
      values?.valueRatio?.denominator?.value &&
      Number(values?.valueRatio?.numerator?.value) >
        Number(values?.valueRatio?.denominator?.value)
    ) {
      errorObj["valueRatioDenominatorValue"] = messageConstants.COMPARE_ERROR;
    } else {
      errorObj["valueRatioNumeratorValue"] = "";
      errorObj["valueRatioDenominatorValue"] = "";
      errorObj["valueRatioNumeratorUnit"] = "";
      errorObj["valueRatioDenominatorUnit"] = "";
    }
    return errorObj;
  };

  return (
    <div className="col_xs_12">
      <div className="row">
        <div className="col_xl_4 col_md_6">
            <Label
              tooltip={true}
              content="The type of information determined as a result of making the observation, if the information has a simple value."
              label="Type of Value"
              isRequired={false}
              id="valueType"
            />
            <Select
              value={
                value?.valueType?.code &&
                observationValueTypeOpts.find(
                  (c) => c?.code === value.valueType.code
                )
              }
              name="valueType"
              id="valueType"
              aria-label="valueType"
              onChange={(option) => {
                setObservationValue({
                  ...observationValue,
                  valueType: option,
                });
              }}
              isSearchable
              isClearable
              classNamePrefix={`${
                error[`valueType`] ? "select__error" : "select"
              }`}
              options={observationValueTypeOpts}
              getOptionValue={(option) => option.code}
              getOptionLabel={(option) => option.display}
            />
            {error?.[`valueType`] ? (
              <p className={error[`valueType`] ? "error" : ""}>
                {error[`valueType`]}
              </p>
            ) : null}
        </div>
        {observationValue?.valueType?.code === "valueQuantity" ? (
          <>
            <div className="col_xl_4 col_md_6">
                <Label
                  tooltip={true}
                  content="Numerical value (with implicit precision)."
                  label="Value"
                  isRequired={false}
                  id="valueQuantityValue"
                />
                <input
                  type="number"
                  step="any"
                  name="valueQuantityValue"
                  id="valueQuantityValue"
                  aria-label="valueQuantityValue"
                  onChange={(event) => {
                    const { value } = event.target;
                    setObservationValue({
                      ...observationValue,
                      valueQuantity: {
                        ...observationValue.valueQuantity,
                        value,
                      },
                    });
                  }}
                  className={
                    error["valueQuantityValue"] ? "control error" : "control"
                  }
                  autoComplete="off"
                  value={observationValue?.valueQuantity?.value || ""}
                />
                {error?.["valueQuantityValue"] ? (
                  <p className={error["valueQuantityValue"] ? "error" : ""}>
                    {error["valueQuantityValue"]}
                  </p>
                ) : null}
            </div>
            <div className="col_xl_3 col_md_6">
                <Label
                  tooltip={true}
                  content="Unit representation."
                  label="Unit"
                  isRequired={false}
                  id="valueQuantityUnit"
                />
                <AsyncPaginate
                  value={value?.normalValue?.coding?.[0]}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  defaultOptions
                  isSearchable
                  isClearable
                  loadOptions={extendLoadOptions}
                  onChange={(option) => {
                    setObservationValue({
                      ...observationValue,
                      valueQuantity: {
                        ...observationValue.valueQuantity,
                        unit: option?.display,
                        code: option?.code,
                        system: option?.system,
                      },
                    });
                  }}
                  getOptionValue={(option) => option.code}
                  getOptionLabel={(option) => option.display}
                  additional={{
                    name: "UnitsOfMeasureCaseSensitive",
                  }}
                  name="valueQuantityUnit"
                  id="valueQuantityUnit"
                  aria-label="valueQuantityUnit"
                  classNamePrefix={`${
                    error["valueQuantityUnit"] ? "select__error" : "select"
                  }`}
                />
                {error?.["valueQuantityUnit"] ? (
                  <p className={error["valueQuantityUnit"] ? "error" : ""}>
                    {error["valueQuantityUnit"]}
                  </p>
                ) : null}
            </div>
          </>
        ) : (
          ""
        )}
        {observationValue?.valueType?.code === "valueCodeableConcept" ? (
          <>
            <div className="col_xl_4 col_md_6"></div>
          </>
        ) : (
          ""
        )}
        {observationValue?.valueType?.code === "valueString" ? (
          <>
            <div className="col_xl_4 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="String value."
                  label="Value"
                  isRequired={false}
                  id="valueStringValue"
                />
                <input
                  type="text"
                  name="valueStringValue"
                  id="valueStringValue"
                  aria-label="valueStringValue"
                  onChange={(event) => {
                    const { value } = event.target;
                    setObservationValue({
                      ...observationValue,
                      valueString: value,
                    });
                  }}
                  className={
                    error["valueStringValue"] ? "control error" : "control"
                  }
                  autoComplete="off"
                  value={observationValue?.valueString || ""}
                />
                {error?.["valueStringValue"] ? (
                  <p className={error["valueStringValue"] ? "error" : ""}>
                    {error["valueStringValue"]}
                  </p>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {observationValue?.valueType?.code === "valueBoolean" ? (
          <>
            <div className="col_xl_4 col_md_6">
              <div className="formgroup">
                <div className="flex gap2">
                  <div className="checkbox checkbox--inline">
                    <input
                      className="checkbox__input"
                      type="radio"
                      id="valueBooleanTrue"
                      name="valueBooleanTrue"
                      aria-label="valueBooleanTrue"
                      value={true}
                      checked={observationValue.valueBoolean ? true : false}
                      onChange={(event) => {
                        const { checked } = event?.target;
                        if (checked)
                          setObservationValue({
                            ...observationValue,
                            valueBoolean: true,
                          });
                      }}
                    />
                    <Label
                      label="True"
                      tooltip={false}
                      isRequired={false}
                      id="valueBooleanTrue"
                    />
                  </div>
                  <div className="checkbox checkbox--inline">
                    <input
                      className="checkbox__input"
                      type="radio"
                      id="valueBooleanFalse"
                      name="valueBooleanFalse"
                      aria-label="valueBooleanFalse"
                      value={false}
                      checked={!observationValue.valueBoolean ? true : false}
                      onChange={(event) => {
                        const { checked } = event?.target;
                        if (checked)
                          setObservationValue({
                            ...observationValue,
                            valueBoolean: false,
                          });
                      }}
                    />
                    <Label
                      label="False"
                      tooltip={false}
                      isRequired={false}
                      id="valueBooleanFalse"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {observationValue?.valueType?.code === "valueInteger" ? (
          <>
            <div className="col_xl_4 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="Integer value."
                  label="Value"
                  isRequired={false}
                  id="valueIntegerValue"
                />
                <input
                  type="number"
                  name="valueIntegerValue"
                  id="valueIntegerValue"
                  aria-label="valueIntegerValue"
                  onChange={(event) => {
                    const { value } = event.target;
                    setObservationValue({
                      ...observationValue,
                      valueInteger: value,
                    });
                  }}
                  className={
                    error["valueIntegerValue"] ? "control error" : "control"
                  }
                  autoComplete="off"
                  value={observationValue?.valueInteger || ""}
                />
                {error?.["valueIntegerValue"] ? (
                  <p className={error["valueIntegerValue"] ? "error" : ""}>
                    {error["valueIntegerValue"]}
                  </p>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {observationValue?.valueType?.code === "valueRange" ? (
          <>
            <div className="col_xl_2 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="The lower limit of range."
                  label="Low"
                  isRequired={false}
                  id="valueRangeLowValue"
                />
                <input
                  type="number"
                  step="any"
                  name="valueRangeLowValue"
                  id="valueRangeLowValue"
                  aria-label="valueRangeLowValue"
                  onChange={(event) => {
                    const { value } = event.target;
                    let valueRange = {
                      ...observationValue.valueRange,
                      low: { ...observationValue.valueRange?.low, value },
                    };
                    setObservationValue({
                      ...observationValue,
                      valueRange,
                    });
                    setError(
                      validate({
                        ...observationValue,
                        valueRange,
                      })
                    );
                  }}
                  className={
                    error["valueRangeLowValue"] ? "control error" : "control"
                  }
                  autoComplete="off"
                  value={observationValue.valueRange?.low?.value || ""}
                />
                {error?.["valueRangeLowValue"] ? (
                  <p className={error["valueRangeLowValue"] ? "error" : ""}>
                    {error["valueRangeLowValue"]}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col_xl_2 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="The unit of lower limit of range."
                  label="Low Unit"
                  isRequired={false}
                  id="valueRangeLowUnit"
                />
                <AsyncPaginate
                  value={
                    observationValue.valueRange?.low?.unit && {
                      code: observationValue.valueRange?.low?.code,
                      display: observationValue.valueRange?.low?.unit,
                    }
                  }
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  defaultOptions
                  isSearchable
                  isClearable
                  loadOptions={extendLoadOptions}
                  onChange={(option) => {
                    let valueRange = {
                      ...observationValue.valueRange,
                      low: {
                        ...observationValue.valueRange?.low,
                        unit: option?.display,
                        code: option?.code,
                        system: option?.system,
                      },
                    };
                    setObservationValue({
                      ...observationValue,
                      valueRange,
                    });
                    setError(
                      validate({
                        ...observationValue,
                        valueRange,
                      })
                    );
                  }}
                  getOptionValue={(option) => option.code}
                  getOptionLabel={(option) => option.display}
                  additional={{
                    name: "UnitsOfMeasureCaseSensitive",
                  }}
                  name="valueRangeLowUnit"
                  id="valueRangeLowUnit"
                  aria-label="valueRangeLowUnit"
                  classNamePrefix={`${
                    error["valueRangeLowUnit"] ? "select__error" : "select"
                  }`}
                />
                {error?.["valueRangeLowUnit"] ? (
                  <p className={error["valueRangeLowUnit"] ? "error" : ""}>
                    {error["valueRangeLowUnit"]}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col_xl_2 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="The higher limit of range."
                  label="High"
                  isRequired={false}
                  id="valueRangeHighValue"
                />
                <input
                  type="number"
                  step="any"
                  name="valueRangeHighValue"
                  id="valueRangeHighValue"
                  aria-label="valueRangeHighValue"
                  onChange={(event) => {
                    const { value } = event.target;
                    let valueRange = {
                      ...observationValue.valueRange,
                      high: { ...observationValue.valueRange?.high, value },
                    };
                    setObservationValue({
                      ...observationValue,
                      valueRange,
                    });
                    setError(
                      validate({
                        ...observationValue,
                        valueRange,
                      })
                    );
                  }}
                  className={
                    error["valueRangeHighValue"] ? "control error" : "control"
                  }
                  autoComplete="off"
                  value={observationValue.valueRange?.high?.value || ""}
                />
                {error?.["valueRangeHighValue"] ? (
                  <p className={error["valueRangeHighValue"] ? "error" : ""}>
                    {error["valueRangeHighValue"]}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col_xl_2 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="The unit of higher limit of range."
                  label="High Unit"
                  isRequired={false}
                  id="valueRangeHighUnit"
                />
                <AsyncPaginate
                  value={
                    observationValue.valueRange?.high?.unit && {
                      code: observationValue.valueRange?.high?.code,
                      display: observationValue.valueRange?.high?.unit,
                    }
                  }
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  defaultOptions
                  isSearchable
                  isClearable
                  loadOptions={extendLoadOptions}
                  onChange={(option) => {
                    let valueRange = {
                      ...observationValue.valueRange,
                      high: {
                        ...observationValue.valueRange?.high,
                        unit: option?.display,
                        code: option?.code,
                        system: option?.system,
                      },
                    };
                    setObservationValue({
                      ...observationValue,
                      valueRange,
                    });
                    setError(
                      validate({
                        ...observationValue,
                        valueRange,
                      })
                    );
                  }}
                  getOptionValue={(option) => option.code}
                  getOptionLabel={(option) => option.display}
                  additional={{
                    name: "UnitsOfMeasureCaseSensitive",
                  }}
                  name="valueRangeHighUnit"
                  id="valueRangeHighUnit"
                  aria-label="valueRangeHighUnit"
                  classNamePrefix={`${
                    error["valueRangeHighUnit"] ? "select__error" : "select"
                  }`}
                />
                {error?.["valueRangeHighUnit"] ? (
                  <p className={error["valueRangeHighUnit"] ? "error" : ""}>
                    {error["valueRangeHighUnit"]}
                  </p>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {observationValue?.valueType?.code === "valueRatio" ? (
          <>
            <div className="col_xl_2 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="The numerator of ratio."
                  label="Numerator"
                  isRequired={false}
                  id="valueRatioNumeratorValue"
                />
                <input
                  type="number"
                  step="any"
                  name="valueRatioNumeratorValue"
                  id="valueRatioNumeratorValue"
                  aria-label="valueRatioNumeratorValue"
                  onChange={(event) => {
                    const { value } = event.target;
                    let valueRatio = {
                      ...observationValue.valueRatio,
                      numerator: {
                        ...observationValue.valueRatio?.numerator,
                        value,
                      },
                    };
                    setObservationValue({
                      ...observationValue,
                      valueRatio,
                    });
                    setError(
                      validate({
                        ...observationValue,
                        valueRatio,
                      })
                    );
                  }}
                  className={
                    error["valueRatioNumeratorValue"]
                      ? "control error"
                      : "control"
                  }
                  autoComplete="off"
                  value={observationValue.valueRatio?.numerator?.value || ""}
                />
                {error?.["valueRatioNumeratorValue"] ? (
                  <p
                    className={error["valueRatioNumeratorValue"] ? "error" : ""}
                  >
                    {error["valueRatioNumeratorValue"]}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col_xl_2 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="The unit of numerator of ratio."
                  label="Numerator Unit"
                  isRequired={false}
                  id="valueRatioNumeratorUnit"
                />
                <AsyncPaginate
                  value={
                    observationValue.valueRatio?.numerator?.unit && {
                      code: observationValue.valueRatio?.numerator?.code,
                      display: observationValue.valueRatio?.numerator?.unit,
                    }
                  }
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  defaultOptions
                  isSearchable
                  isClearable
                  loadOptions={extendLoadOptions}
                  onChange={(option) => {
                    let valueRatio = {
                      ...observationValue.valueRatio,
                      numerator: {
                        ...observationValue.valueRatio?.numerator,
                        unit: option?.display,
                        code: option?.code,
                        system: option?.system,
                      },
                    };
                    setObservationValue({
                      ...observationValue,
                      valueRatio,
                    });
                    setError(
                      validate({
                        ...observationValue,
                        valueRatio,
                      })
                    );
                  }}
                  getOptionValue={(option) => option.code}
                  getOptionLabel={(option) => option.display}
                  additional={{
                    name: "UnitsOfMeasureCaseSensitive",
                  }}
                  name="valueRatioNumeratorUnit"
                  id="valueRatioNumeratorUnit"
                  aria-label="valueRatioNumeratorUnit"
                  classNamePrefix={`${
                    error["valueRatioNumeratorUnit"]
                      ? "select__error"
                      : "select"
                  }`}
                />
                {error?.["valueRatioNumeratorUnit"] ? (
                  <p
                    className={error["valueRatioNumeratorUnit"] ? "error" : ""}
                  >
                    {error["valueRatioNumeratorUnit"]}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col_xl_2 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="< | <= | >= | > | ad - how to understand the value."
                  label="Comparator"
                  isRequired={false}
                  id="valueRatioNumeratorComparator"
                />
                <AsyncPaginate
                  value={
                    observationValue.valueRatio?.numerator?.comparator && {
                      code: observationValue.valueRatio?.numerator?.comparator,
                      display:
                        observationValue.valueRatio?.numerator?.comparator,
                    }
                  }
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  defaultOptions
                  isSearchable
                  isClearable
                  loadOptions={extendLoadOptions}
                  onChange={(option) => {
                    let valueRatio = {
                      ...observationValue.valueRatio,
                      numerator: {
                        ...observationValue.valueRatio?.numerator,
                        comparator: option?.code,
                      },
                    };
                    setObservationValue({
                      ...observationValue,
                      valueRatio,
                    });
                  }}
                  getOptionValue={(option) => option.code}
                  getOptionLabel={(option) => option.display}
                  additional={{
                    name: "QuantityComparator",
                  }}
                  name="valueRatioNumeratorComparator"
                  id="valueRatioNumeratorComparator"
                  aria-label="valueRatioNumeratorComparator"
                  classNamePrefix={`${
                    error["valueRatioNumeratorComparator"]
                      ? "select__error"
                      : "select"
                  }`}
                />
                {error?.["valueRatioNumeratorComparator"] ? (
                  <p
                    className={
                      error["valueRatioNumeratorComparator"] ? "error" : ""
                    }
                  >
                    {error["valueRatioNumeratorComparator"]}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col_xl_2 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="The denominator of ratio."
                  label="Denominator"
                  isRequired={false}
                  id="valueRatioDenominatorValue"
                />
                <input
                  type="number"
                  step="any"
                  name="valueRatioDenominatorValue"
                  id="valueRatioDenominatorValue"
                  aria-label="valueRatioDenominatorValue"
                  onChange={(event) => {
                    const { value } = event.target;
                    let valueRatio = {
                      ...observationValue.valueRatio,
                      denominator: {
                        ...observationValue.valueRatio?.denominator,
                        value,
                      },
                    };
                    setObservationValue({
                      ...observationValue,
                      valueRatio,
                    });
                    setError(
                      validate({
                        ...observationValue,
                        valueRatio,
                      })
                    );
                  }}
                  className={
                    error["valueRatioDenominatorValue"]
                      ? "control error"
                      : "control"
                  }
                  autoComplete="off"
                  value={observationValue.valueRatio?.denominator?.value || ""}
                />
                {error?.["valueRatioDenominatorValue"] ? (
                  <p
                    className={
                      error["valueRatioDenominatorValue"] ? "error" : ""
                    }
                  >
                    {error["valueRatioDenominatorValue"]}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col_xl_2 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="The unit of denominator of ratio."
                  label="Denominator Unit"
                  isRequired={false}
                  id="valueRatioDenominatorUnit"
                />
                <AsyncPaginate
                  value={
                    observationValue.valueRatio?.denominator?.unit && {
                      code: observationValue.valueRatio?.denominator?.code,
                      display: observationValue.valueRatio?.denominator?.unit,
                    }
                  }
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  defaultOptions
                  isSearchable
                  isClearable
                  loadOptions={extendLoadOptions}
                  onChange={(option) => {
                    let valueRatio = {
                      ...observationValue.valueRatio,
                      denominator: {
                        ...observationValue.valueRatio?.denominator,
                        unit: option?.display,
                        code: option?.code,
                        system: option?.system,
                      },
                    };
                    setObservationValue({
                      ...observationValue,
                      valueRatio,
                    });
                    setError(
                      validate({
                        ...observationValue,
                        valueRatio,
                      })
                    );
                  }}
                  getOptionValue={(option) => option.code}
                  getOptionLabel={(option) => option.display}
                  additional={{
                    name: "UnitsOfMeasureCaseSensitive",
                  }}
                  name="valueRatioDenominatorUnit"
                  id="valueRatioDenominatorUnit"
                  aria-label="valueRatioDenominatorUnit"
                  classNamePrefix={`${
                    error["valueRatioDenominatorUnit"]
                      ? "select__error"
                      : "select"
                  }`}
                />
                {error?.["valueRatioDenominatorUnit"] ? (
                  <p
                    className={
                      error["valueRatioDenominatorUnit"] ? "error" : ""
                    }
                  >
                    {error["valueRatioDenominatorUnit"]}
                  </p>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {observationValue?.valueType?.code === "valueSampledData" ? (
          <>
            <div className="col_xl_2 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="Zero value and units."
                  label="Origin"
                  isRequired={false}
                  id="valueSampledDataOriginValue"
                />
                <input
                  type="number"
                  step="any"
                  name="valueSampledDataOriginValue"
                  id="valueSampledDataOriginValue"
                  aria-label="valueSampledDataOriginValue"
                  onChange={(event) => {
                    const { value } = event.target;
                    setObservationValue({
                      ...observationValue,
                      valueSampledData: {
                        ...observationValue.valueSampledData,
                        origin: {
                          ...observationValue.valueSampledData?.origin,
                          value,
                        },
                      },
                    });
                  }}
                  className={
                    error["valueSampledDataOriginValue"]
                      ? "control error"
                      : "control"
                  }
                  autoComplete="off"
                  value={observationValue.valueSampledData?.origin?.value || ""}
                />
                {error?.["valueSampledDataOriginValue"] ? (
                  <p
                    className={
                      error["valueSampledDataOriginValue"] ? "error" : ""
                    }
                  >
                    {error["valueSampledDataOriginValue"]}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col_xl_2 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="Origin Unit."
                  label="Origin Unit"
                  isRequired={false}
                  id="valueSampledDataOriginUnit"
                />
                <AsyncPaginate
                  value={
                    observationValue.valueSampledData?.origin?.unit && {
                      code: observationValue.valueSampledData?.origin?.code,
                      display: observationValue.valueSampledData?.origin?.unit,
                    }
                  }
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  defaultOptions
                  isSearchable
                  isClearable
                  loadOptions={extendLoadOptions}
                  onChange={(option) => {
                    let valueSampledData = {
                      ...observationValue.valueSampledData,
                      origin: {
                        ...observationValue.valueSampledData?.origin,
                        unit: option?.display,
                        code: option?.code,
                        system: option?.system,
                      },
                    };
                    setObservationValue({
                      ...observationValue,
                      valueSampledData,
                    });
                  }}
                  getOptionValue={(option) => option.code}
                  getOptionLabel={(option) => option.display}
                  additional={{
                    name: "UnitsOfMeasureCaseSensitive",
                  }}
                  name="valueSampledDataOriginUnit"
                  id="valueSampledDataOriginUnit"
                  aria-label="valueSampledDataOriginUnit"
                  classNamePrefix={`${
                    error["valueSampledDataOriginUnit"]
                      ? "select__error"
                      : "select"
                  }`}
                />
                {error?.["valueSampledDataOriginUnit"] ? (
                  <p
                    className={
                      error["valueSampledDataOriginUnit"] ? "error" : ""
                    }
                  >
                    {error["valueSampledDataOriginUnit"]}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col_xl_2 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="Number of intervalUnits between samples."
                  label="Interval"
                  isRequired={false}
                  id="valueSampledDataInterval"
                />
                <input
                  type="number"
                  step="any"
                  name="valueSampledDataInterval"
                  id="valueSampledDataInterval"
                  aria-label="valueSampledDataInterval"
                  onChange={(event) => {
                    const { value } = event.target;
                    setObservationValue({
                      ...observationValue,
                      valueSampledData: {
                        ...observationValue.valueSampledData,
                        interval: value,
                      },
                    });
                  }}
                  className={
                    error["valueSampledDataInterval"]
                      ? "control error"
                      : "control"
                  }
                  autoComplete="off"
                  value={observationValue.valueSampledData?.interval || ""}
                />
                {error?.["valueSampledDataInterval"] ? (
                  <p
                    className={error["valueSampledDataInterval"] ? "error" : ""}
                  >
                    {error["valueSampledDataInterval"]}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col_xl_2 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="The measurement unit of the interval between samples."
                  label="Interval Unit"
                  isRequired={false}
                  id="valueSampledDataIntervalUnit"
                />
                <AsyncPaginate
                  value={
                    observationValue.valueSampledData?.intervalUnit && {
                      code: observationValue.valueSampledData?.intervalUnit,
                      display: observationValue.valueSampledData?.intervalUnit,
                    }
                  }
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  defaultOptions
                  isSearchable
                  isClearable
                  loadOptions={extendLoadOptions}
                  onChange={(option) => {
                    setObservationValue({
                      ...observationValue,
                      valueSampledData: {
                        ...observationValue.valueSampledData,
                        intervalUnit: option?.code,
                      },
                    });
                  }}
                  getOptionValue={(option) => option.code}
                  getOptionLabel={(option) => option.display}
                  additional={{
                    name: "UnitsOfMeasureCaseSensitive",
                  }}
                  name="valueSampledDataIntervalUnit"
                  id="valueSampledDataIntervalUnit"
                  aria-label="valueSampledDataIntervalUnit"
                  classNamePrefix={`${
                    error["valueSampledDataIntervalUnit"]
                      ? "select__error"
                      : "select"
                  }`}
                />
                {error?.["valueSampledDataIntervalUnit"] ? (
                  <p
                    className={
                      error["valueSampledDataIntervalUnit"] ? "error" : ""
                    }
                  >
                    {error["valueSampledDataIntervalUnit"]}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col_xl_2 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="Multiply data by this before adding to origin."
                  label="Factor"
                  isRequired={false}
                  id="valueSampledDataFactor"
                />
                <input
                  type="number"
                  step="any"
                  name="valueSampledDataFactor"
                  id="valueSampledDataFactor"
                  aria-label="valueSampledDataFactor"
                  onChange={(event) => {
                    const { value } = event.target;
                    setObservationValue({
                      ...observationValue,
                      valueSampledData: {
                        ...observationValue.valueSampledData,
                        factor: value,
                      },
                    });
                  }}
                  className={
                    error["valueSampledDataFactor"]
                      ? "control error"
                      : "control"
                  }
                  autoComplete="off"
                  value={observationValue.valueSampledData?.factor || ""}
                />
                {error?.["valueSampledDataFactor"] ? (
                  <p className={error["valueSampledDataFactor"] ? "error" : ""}>
                    {error["valueSampledDataFactor"]}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col_xl_2 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="Lower limit of detection."
                  label="Lower Limit"
                  isRequired={false}
                  id="valueSampledDataLowerLimit"
                />
                <input
                  type="number"
                  step="any"
                  name="valueSampledDataLowerLimit"
                  id="valueSampledDataLowerLimit"
                  aria-label="valueSampledDataLowerLimit"
                  onChange={(event) => {
                    const { value } = event.target;
                    setObservationValue({
                      ...observationValue,
                      valueSampledData: {
                        ...observationValue.valueSampledData,
                        lowerLimit: value,
                      },
                    });
                  }}
                  className={
                    error["valueSampledDataLowerLimit"]
                      ? "control error"
                      : "control"
                  }
                  autoComplete="off"
                  value={observationValue.valueSampledData?.lowerLimit || ""}
                />
                {error?.["valueSampledDataLowerLimit"] ? (
                  <p
                    className={
                      error["valueSampledDataLowerLimit"] ? "error" : ""
                    }
                  >
                    {error["valueSampledDataLowerLimit"]}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col_xl_2 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="Upper limit of detection."
                  label="Upper Limit"
                  isRequired={false}
                  id="valueSampledDataUpperLimit"
                />
                <input
                  type="number"
                  step="any"
                  name="valueSampledDataUpperLimit"
                  id="valueSampledDataUpperLimit"
                  aria-label="valueSampledDataUpperLimit"
                  onChange={(event) => {
                    const { value } = event.target;
                    setObservationValue({
                      ...observationValue,
                      valueSampledData: {
                        ...observationValue.valueSampledData,
                        upperLimit: value,
                      },
                    });
                  }}
                  className={
                    error["valueSampledDataUpperLimit"]
                      ? "control error"
                      : "control"
                  }
                  autoComplete="off"
                  value={observationValue.valueSampledData?.upperLimit || ""}
                />
                {error?.["valueSampledDataUpperLimit"] ? (
                  <p
                    className={
                      error["valueSampledDataUpperLimit"] ? "error" : ""
                    }
                  >
                    {error["valueSampledDataUpperLimit"]}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col_xl_2 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="Number of sample points at each time point."
                  label="Dimensions"
                  isRequired={false}
                  id="valueSampledDataDimensions"
                />
                <input
                  type="number"
                  name="valueSampledDataDimensions"
                  id="valueSampledDataDimensions"
                  aria-label="valueSampledDataDimensions"
                  onChange={(event) => {
                    const { value } = event.target;
                    setObservationValue({
                      ...observationValue,
                      valueSampledData: {
                        ...observationValue.valueSampledData,
                        dimensions: value,
                      },
                    });
                  }}
                  className={
                    error["valueSampledDataDimensions"]
                      ? "control error"
                      : "control"
                  }
                  autoComplete="off"
                  value={observationValue.valueSampledData?.dimensions || ""}
                />
                {error?.["valueSampledDataDimensions"] ? (
                  <p
                    className={
                      error["valueSampledDataDimensions"] ? "error" : ""
                    }
                  >
                    {error["valueSampledDataDimensions"]}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col_xl_2 col_md_6">
              <div className="formgroup">
                <Label
                  tooltip={true}
                  content="Decimal values with spaces, or 'E' | 'U' | 'L'."
                  label="Data"
                  isRequired={false}
                  id="valueSampledDataData"
                />
                <input
                  type="text"
                  name="valueSampledDataData"
                  id="valueSampledDataData"
                  aria-label="valueSampledDataData"
                  onChange={(event) => {
                    const { value } = event.target;
                    setObservationValue({
                      ...observationValue,
                      valueSampledData: {
                        ...observationValue.valueSampledData,
                        data: value,
                      },
                    });
                  }}
                  className={
                    error["valueSampledDataData"] ? "control error" : "control"
                  }
                  autoComplete="off"
                  value={observationValue.valueSampledData?.data || ""}
                />
                {error?.["valueSampledDataData"] ? (
                  <p className={error["valueSampledDataData"] ? "error" : ""}>
                    {error["valueSampledDataData"]}
                  </p>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {observationValue?.valueType?.code === "valueTime" ? (
          <>
            <div className="col_xl_4 col_md_6">
              <Label
                tooltip={true}
                content="Time Value."
                label="Value"
                isRequired={false}
                id="valueTimeValue"
              />
              <input
                type="time"
                name="valueTimeValue"
                id="valueTimeValue"
                aria-label="valueTimeValue"
                onChange={(event) => {
                  const { value } = event.target;
                  setObservationValue({
                    ...observationValue,
                    valueTimeValue: value,
                  });
                }}
                className={
                  error["valueTimeValue"] ? "control error" : "control"
                }
                value={
                  observationValue?.valueTime
                    ? moment(observationValue?.valueTime).format(
                        appConstants.DATEPICKERTIME
                      )
                    : ""
                }
              />
              {error?.["valueTimeValue"] ? (
                <p className={error["valueTimeValue"] ? "error" : ""}>
                  {error["valueTimeValue"]}
                </p>
              ) : null}
            </div>
          </>
        ) : (
          ""
        )}
        {observationValue?.valueType?.code === "valueDateTime" ? (
          <>
            <div className="col_xl_4 col_md_6">
              <Label
                tooltip={true}
                content="Date-time Value."
                label="Value"
                isRequired={false}
                id="valueDateTimeValue"
              />
              <input
                type="datetime-local"
                name="valueDateTimeValue"
                id="valueDateTimeValue"
                aria-label="valueDateTimeValue"
                onChange={(event) => {
                  const { value } = event.target;
                  setObservationValue({
                    ...observationValue,
                    valueDateTimeValue: value,
                  });
                }}
                className={
                  error["valueDateTimeValue"] ? "control error" : "control"
                }
                value={
                  observationValue?.valueDateTime
                    ? moment(observationValue?.valueDateTime).format(
                        appConstants.DATEPICKERDATETIME
                      )
                    : ""
                }
              />
              {error?.["valueDateTimeValue"] ? (
                <p className={error["valueDateTimeValue"] ? "error" : ""}>
                  {error["valueDateTimeValue"]}
                </p>
              ) : null}
            </div>
          </>
        ) : (
          ""
        )}
        {observationValue?.valueType?.code === "valuePeriod" ? (
          <>
            <div className="col_xl_4 col_md_6">
              <Label
                tooltip={true}
                content="Value Start Period"
                label="Start"
                isRequired={false}
                id="valuePeriodStart"
              />
              <input
                type="datetime-local"
                name="valuePeriodStart"
                id="valuePeriodStart"
                aria-label="valuePeriodStart"
                onChange={(event) => {
                  const { value } = event.target;
                  setObservationValue({
                    ...observationValue,
                    valuePeriod: {
                      ...observationValue.valuePeriod,
                      start: value,
                    },
                  });
                }}
                className={
                  error["valuePeriodStart"] ? "control error" : "control"
                }
                value={
                  observationValue?.valuePeriod?.start
                    ? moment(observationValue?.valuePeriod?.start).format(
                        appConstants.DATEPICKERDATETIME
                      )
                    : ""
                }
              />
              {error?.["valuePeriodStart"] ? (
                <p className={error["valuePeriodStart"] ? "error" : ""}>
                  {error["valuePeriodStart"]}
                </p>
              ) : null}
            </div>
            <div className="col_xl_4 col_md_6">
              <Label
                tooltip={true}
                content="Value End Period"
                label="End"
                isRequired={false}
                id="valuePeriodEnd"
              />
              <input
                type="datetime-local"
                name="valuePeriodEnd"
                id="valuePeriodEnd"
                aria-label="valuePeriodEnd"
                onChange={(event) => {
                  const { value } = event.target;
                  setObservationValue({
                    ...observationValue,
                    valuePeriod: {
                      ...observationValue.valuePeriod,
                      end: value,
                    },
                  });
                }}
                className={
                  error["valuePeriodEnd"] ? "control error" : "control"
                }
                value={
                  observationValue?.valuePeriod?.end
                    ? moment(observationValue?.valuePeriod?.end).format(
                        appConstants.DATEPICKERDATETIME
                      )
                    : ""
                }
              />
              {error?.["valuePeriodEnd"] ? (
                <p className={error["valuePeriodEnd"] ? "error" : ""}>
                  {error["valuePeriodEnd"]}
                </p>
              ) : null}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
});

export default ObservationValue;
