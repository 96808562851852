import {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch } from "react-redux";
import Label from "../Label";
import Select, { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { FontAwesomeIcon, faTrash, faPlus } from "../../../Assets/icons";
import { appConstants } from "../../../Constants";
import { taxonomySlice } from "../../../Features";
import { MultipleInput } from "../../Common";
import { toast } from "react-toastify";
import moment from "moment";

const RelatedPerson = forwardRef(({ rows }, ref) => {
  const dispatch = useDispatch();
  const error = {};
  const genderOpts = appConstants.genderOpts;
  const [relatedPersons, setRelatedPerson] = useState(rows);
  useEffect(() => {
    setRelatedPerson(
      rows?.length
        ? rows.map((r) => ({
            ...r,
            firstName: r?.name?.[0]?.given?.[0],
            middleName: r?.name?.[0]?.given?.[1],
            lastName: r?.name?.[0]?.family,
            birthDate:
              r?.birthDate &&
              new Date(
                new Date(r.birthDate).getTime() +
                  new Date(r.birthDate).getTimezoneOffset() * 60000
              ),
          }))
        : [{ phoneNumbers: [{}], emails: [{}] }]
    );
  }, [rows]);

  const extendLoadOptions = useCallback(
    (searchQuery, loadedOptions, { name }) => {
      let options = [],
        rowCount = 0;
      return new Promise((resolve, reject) => {
        dispatch(
          taxonomySlice.getValueSet({
            text: searchQuery,
            name,
            offset: loadedOptions.length || appConstants.DEFAULT_SKIP,
            limit: appConstants.DEFAULT_LIMIT,
            sortBy: "createdAt",
            sortOrder: "desc",
          })
        )
          .unwrap()
          .then((response) => {
            options = response?.data?.codes;
            rowCount = response?.data?.rowCount;
            resolve({
              options,
              hasMore:
                loadedOptions.length + appConstants.DEFAULT_LIMIT < rowCount
                  ? true
                  : false,
              name,
            });
          })
          .catch((error) => {
            toast.error(error?.data?.message || error?.data);
            resolve({
              options,
              hasMore: false,
              name,
            });
          });
      });
    },
    [dispatch]
  );

  const removefields = (index) => {
    setRelatedPerson((relatedPersons) =>
      relatedPersons.filter((v, i) => i !== index)
    );
  };

  const addfields = (index) => {
    if (
      relatedPersons?.[index]?.firstName &&
      relatedPersons?.[index]?.relationship
    )
      setRelatedPerson([
        ...relatedPersons,
        { phoneNumbers: [{}], emails: [{}] },
      ]);
  };

  const customOption = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  useImperativeHandle(ref, () => ({
    handleSubmit(event) {
      event.preventDefault();
      return relatedPersons.filter(
        (t) =>
          (t?.firstName || t?.lastName || t?.middleName) &&
          t?.relationship?.length
      );
    },
  }));

  return (
    <div className="col_xs_12">
      <div className="repeater">
        {relatedPersons?.length
          ? relatedPersons.map((value, index) => {
              return (
                <div key={index} className="repeater__row">
                  <div className="repeater__row__fields">
                    <div className="row">
                      <div className="col_xl_4 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="First name associated with the related person."
                            label="First Name"
                            isRequired={false}
                            id={`firstName${index}`}
                          />
                          <input
                            type="text"
                            name={`firstName${index}`}
                            id={`firstName${index}`}
                            onChange={(event) => {
                              const { value } = event.target;
                              const fieldsArr = [...relatedPersons];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                firstName: value,
                              };
                              setRelatedPerson(fieldsArr);
                            }}
                            className={
                              error[`firstName${index}`]
                                ? "control error"
                                : "control"
                            }
                            autoComplete="off"
                            value={value?.firstName || ""}
                          />
                          {error?.[`firstName${index}`] ? (
                            <p
                              className={
                                error[`firstName${index}`] ? "error" : ""
                              }
                            >
                              {error[`firstName${index}`]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col_xl_4 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="Middle name associated with the related person."
                            label="Middle Name"
                            isRequired={false}
                            id={`middleName${index}`}
                          />
                          <input
                            type="text"
                            name={`middleName${index}`}
                            id={`middleName${index}`}
                            onChange={(event) => {
                              const { value } = event.target;
                              const fieldsArr = [...relatedPersons];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                middleName: value,
                              };
                              setRelatedPerson(fieldsArr);
                            }}
                            className={
                              error[`middleName${index}`]
                                ? "control error"
                                : "control"
                            }
                            autoComplete="off"
                            value={value?.middleName || ""}
                          />
                          {error?.[`middleName${index}`] ? (
                            <p
                              className={
                                error[`middleName${index}`] ? "error" : ""
                              }
                            >
                              {error[`middleName${index}`]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col_xl_4 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="Last name associated with the related person."
                            label="Last/Family Name"
                            isRequired={false}
                            id={`lastName${index}`}
                          />
                          <input
                            type="text"
                            name={`lastName${index}`}
                            id={`lastName${index}`}
                            onChange={(event) => {
                              const { value } = event.target;
                              const fieldsArr = [...relatedPersons];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                lastName: value,
                              };
                              setRelatedPerson(fieldsArr);
                            }}
                            className={
                              error[`lastName${index}`]
                                ? "control error"
                                : "control"
                            }
                            autoComplete="off"
                            value={value?.lastName || ""}
                          />
                          {error?.[`lastName${index}`] ? (
                            <p
                              className={
                                error[`lastName${index}`] ? "error" : ""
                              }
                            >
                              {error[`lastName${index}`]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col_xl_4 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="The gender that the person is considered to have for administration and record keeping purposes."
                            label="Gender"
                            isRequired={false}
                            aria-label={`gender${index}`}
                          />
                          <Select
                            value={
                              value?.gender &&
                              genderOpts.find((g) => g?.code === value.gender)
                            }
                            name={`gender${index}`}
                            aria-label={`gender${index}`}
                            id={`gender${index}`}
                            onChange={(option) => {
                              const fieldsArr = [...relatedPersons];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                gender: option?.code,
                              };
                              setRelatedPerson(fieldsArr);
                            }}
                            isSearchable
                            isClearable
                            classNamePrefix={`${
                              error[`gender${index}`]
                                ? "select__error"
                                : "select"
                            }`}
                            options={genderOpts}
                            getOptionValue={(option) => option.code}
                            getOptionLabel={(option) => option.display}
                          />
                          {error?.[`gender${index}`] ? (
                            <p
                              className={error[`gender${index}`] ? "error" : ""}
                            >
                              {error[`gender${index}`]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col_xl_4 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="The date on which the realted person was born."
                            label="Date of birth"
                            isRequired={false}
                            id={`birthDate${index}`}
                          />
                          <input
                            type="date"
                            name={`birthDate${index}`}
                            id={`birthDate${index}`}
                            onChange={(event) => {
                              const { value } = event.target;
                              const fieldsArr = [...relatedPersons];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                birthDate: value,
                              };
                              setRelatedPerson(fieldsArr);
                            }}
                            className={
                              error[`birthDate${index}`]
                                ? "control error"
                                : "control"
                            }
                            autoComplete="off"
                            value={
                              value?.birthDate
                                ? moment(value.birthDate).format(
                                    appConstants.DATEPICKERDATE
                                  )
                                : ""
                            }
                          />
                          {error?.[`birthDate${index}`] ? (
                            <p
                              className={
                                error[`birthDate${index}`] ? "error" : ""
                              }
                            >
                              {error[`birthDate${index}`]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col_xl_4 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="The nature of the relationship with patient."
                            label="Relationship"
                            isRequired={false}
                            id={`relationship${index}`}
                          />
                          <AsyncPaginate
                            components={{ Option: customOption }}
                            value={
                              value?.relationship?.length &&
                              value.relationship.map((opt) => opt?.coding?.[0])
                            }
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            defaultOptions
                            aria-label={`relationship${index}`}
                            loadOptions={extendLoadOptions}
                            onChange={(option) => {
                              const fieldsArr = [...relatedPersons];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                relationship: option.map((opt) => {
                                  return { coding: [opt], text: opt.display };
                                }),
                              };
                              setRelatedPerson(fieldsArr);
                            }}
                            getOptionValue={(option) => option.code}
                            getOptionLabel={(option) => option.display}
                            additional={{ name: "PatientRelationshipType" }}
                            name={`relationship${index}`}
                            id={`relationship${index}`}
                            classNamePrefix={`${
                              error[`relationship${index}`]
                                ? "select__error"
                                : "select"
                            }`}
                          />
                          {error?.[`relationship${index}`] ? (
                            <p
                              className={
                                error[`relationship${index}`] ? "error" : ""
                              }
                            >
                              {error[`relationship${index}`]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col_xl_4 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="List of phone number(s) by which the related person may be contacted."
                            label="Phone number(s)"
                            isRequired={false}
                            aria-label="Phone number"
                          />
                          <MultipleInput
                            name="SubInput"
                            type="text"
                            placeholder="+1 (123) (123) (1234)"
                            rows={value?.phoneNumbers}
                            onChange={(fields) => {
                              let fieldArr = [...relatedPersons];
                              fieldArr[index] = {
                                ...fieldArr[index],
                                phoneNumbers: fields,
                              };
                              setRelatedPerson(fieldArr);
                            }}
                            field="phone"
                          />
                        </div>
                      </div>
                      <div className="col_xl_4 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="List of email address(es) by which the related person may be contacted."
                            label="Email Address(es)"
                            isRequired={false}
                            aria-label="Email address"
                          />
                          <MultipleInput
                            name="SubInput"
                            type="email"
                            placeholder="abc@example.com"
                            rows={value?.emails}
                            onChange={(fields) => {
                              let fieldArr = [...relatedPersons];
                              fieldArr[index] = {
                                ...fieldArr[index],
                                emails: fields,
                              };
                              setRelatedPerson(fieldArr);
                            }}
                            field="email"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="repeater__row__button">
                    {index === relatedPersons.length - 1 ? (
                      <button
                        onClick={() => addfields(index)}
                        className="btn btn--success"
                        aria-label="add"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    ) : null}

                    {index !== relatedPersons.length - 1 ? (
                      <button
                        onClick={() => removefields(index)}
                        className="btn btn--danger"
                        aria-label="delete"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    ) : null}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
});

export default RelatedPerson;
