import { useState, useEffect } from "react";
import { FontAwesomeIcon, faXmark } from "../../../Assets/icons";
import Avatar from "react-avatar-edit";
import images from "../../../Assets/images";
import config from "../../../Config";

export default function ImageUpload({ file, onClose, onCrop, upload }) {
  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let imageFile = new File([u8arr], filename, { type: mime });
    return imageFile;
  };
  const [image, setImage] = useState(
    dataURLtoFile(images.no_profile, "no_profile")
  );
  const [fileUrl, setFileUrl] = useState(
    `${URL.createObjectURL(dataURLtoFile(images.no_profile, "no_profile"))}`
  );
  useEffect(() => {
    setImage(file ? file : dataURLtoFile(images.no_profile, "no_profile"));
    if (file?.url) setFileUrl(`${config.BACKEND_URL}${file.url}`);
    else
      setFileUrl(
        `${URL.createObjectURL(dataURLtoFile(images.no_profile, "no_profile"))}`
      );
  }, [file]);

  return (
    <div
      id="updateProfileModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      className="modal modal--show"
    >
      <div className="modal__dialog modal__dialog--centered" role="document">
        <div className="modal__content">
          <div className="modal__header">
            <div className="modal__header__title">
              <h5 className="title--h4">Profile Image</h5>
            </div>
            <button
              onClick={onClose}
              type="button"
              className="btn btn--clear"
              data-dismiss="modal"
              aria-label="Close"
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <div className="modal__body">
            <div className="image__upload">
              <Avatar
                width={500}
                height={300}
                onCrop={onCrop.bind()}
                label={"Browse Image"}
                src={fileUrl}
                closeIconColor={"black"}
              />
            </div>
          </div>
          <div className="modal__footer justify--center">
            <button
              onClick={(e) => {
                upload(image);
              }}
              type="button"
              className="btn btn--primary btn--full"
            >
              Upload
            </button>
            <button
              type="button"
              className="btn btn--primary btn--full btn--upload"
            >
              Browse Image
              <input
                type="file"
                aria-label="Browse file"
                onChange={(event) => {
                  setImage(event.target.files[0]);
                  setFileUrl(URL.createObjectURL(event.target.files[0]));
                }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
