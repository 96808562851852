import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon, faXmark } from "../../../Assets/icons";
import { Loader, Tree } from "../../Common";
import { toast } from "react-toastify";
import { taxonomySlice } from "../../../Features";

export default function SelectContact({ mode, onClose }) {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    dispatch(taxonomySlice.getTaxonomyTerms({ text: searchText }));
  }, [searchText, dispatch]);
  const {
    taxonomyTerms,
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    successMessage,
  } = useSelector((state) => state.taxonomy);
  useEffect(() => {
    if (isError) {
      dispatch(taxonomySlice.clearStatus());
      toast.error(errorMessage);
    }
    if (isSuccess) {
      dispatch(taxonomySlice.clearStatus());
    }
  }, [
    taxonomyTerms,
    isError,
    isSuccess,
    isFetching,
    errorMessage,
    successMessage,
    dispatch,
  ]);

  return (
    <div className="modal modal--show">
      <div className="modal__dialog modal__dialog--contacts modal__dialog--sidebar">
        <div className="modal__content">
          <div className="modal__header">
            <input
              type="search"
              value={searchText}
              onChange={(event) => {
                const { value } = event.target;
                setSearchText(value);
              }}
              placeholder="Search Service Attribute"
              className="control"
              aria-label="search"
            />
            <button
              onClick={onClose}
              type="button"
              className="btn btn--clear"
              data-dismiss="modal"
              aria-label="Close"
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <div className="modal__body">
            {isFetching ? (
              <Loader text={"Loading"} />
            ) : taxonomyTerms?.length ? (
              <Tree data={taxonomyTerms} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
