import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";

const initialState = {
  pageCount: 0,
  rowCount: 0,
  category: "",
  severity: "",
  clinicalStatus: "",
  verificationStatus: "",
  list: [],
  selectedRow: null,
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const getConditions = createAsyncThunk(
  "conditions",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(
          `/conditions?offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}&category=${params.category}&severity=${params.severity}&clinicalStatus=${params.clinicalStatus}&verificationStatus=${params.verificationStatus}&subject=${params.subject}`
        )
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const addCondition = createAsyncThunk(
  "conditions/add",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/conditions`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateCondition = createAsyncThunk(
  "conditions/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/conditions`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deleteCondition = createAsyncThunk(
  "conditions/delete",
  async (id, thunkApi) => {
    try {
      return await axiosInstance
        .delete(`/conditions/${id}`)
        .then((response) => response.data)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const addFhirCondition = createAsyncThunk(
  "conditions/fhir",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post("/conditions/fhir", params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const { actions, reducer } = createSlice({
  name: "conditions",
  initialState,
  reducers: {
    setIntialProps: (state, action) => {
      return { ...state, ...action?.payload };
    },
    setSelectedRow: (state, action) => {
      state.selectedRow = action?.payload;
    },
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // get conditions
      .addCase(getConditions.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getConditions.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data?.conditions,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getConditions.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { setIntialProps, setSelectedRow, clearStatus } = actions;

const conditionSlice = {
  getConditions,
  addCondition,
  updateCondition,
  deleteCondition,
  addFhirCondition,
  setSelectedRow,
  setIntialProps,
  clearStatus,
  reducer,
};

export default conditionSlice;
