import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "../src/Components/Login";
import ForgotPassword from "./Components/ForgotPassword";
import { Loader } from "./Components/Common";
import Privacy from "./Components/Privacy";
import { appConstants } from "./Constants";

const ProtectedLayout = React.lazy(() =>
  import("./Components/Layout/ProtectedLayout")
);
const StaffLayout = React.lazy(() => import("./Components/Layout/StaffLayout"));
const SuperAdminLayout = React.lazy(() =>
  import("./Components/Layout/SuperAdminLayout")
);
const ListReferrals = React.lazy(() =>
  import("./Components/Referrals/ListReferrals")
);
const ListPatients = React.lazy(() =>
  import("./Components/Patients/ListPatients")
);
const AddEditPatients = React.lazy(() =>
  import("./Components/Patients/AddEditPatient")
);
const Launch = React.lazy(() => import("./Components/Smart-on-fhir/launch"));
const Redirect = React.lazy(() =>
  import("./Components/Smart-on-fhir/redirect")
);
const PractitionerDetails = React.lazy(() =>
  import("./Components/Practitioners/PractitionerDetails")
);
const EditPractitioner = React.lazy(() =>
  import("./Components/Practitioners/EditPractitioner")
);
const AddPractitioner = React.lazy(() =>
  import("./Components/Practitioners/AddPractitioner")
);
const ListPractitioners = React.lazy(() =>
  import("./Components/Practitioners/ListPractitioners")
);
const ListPractitionerRoles = React.lazy(() =>
  import("./Components/PractitionerRoles/ListPractitionerRoles")
);
const AddEditPractitionerRoles = React.lazy(() =>
  import("./Components/PractitionerRoles/AddEditPractitionerRoles")
);
const ResourceSearch = React.lazy(() => import("./Components/ResourceSearch"));
const ServiceResourceDetails = React.lazy(() =>
  import("./Components/Services/ServiceResourceDetails")
);
const OrganizationResourceDetails = React.lazy(() =>
  import("./Components/Organizations/OrganizationResourceDetails")
);
const ListOrganizations = React.lazy(() =>
  import("./Components/Organizations/ListOrganizations")
);
const AddEditOrganization = React.lazy(() =>
  import("./Components/Organizations/AddEditOrganization")
);
const OrganizationDetails = React.lazy(() =>
  import("./Components/Organizations/OrganizationDetails")
);
const AddEditService = React.lazy(() =>
  import("./Components/Services/AddEditService")
);
const ListServices = React.lazy(() =>
  import("./Components/Services/ListServices")
);
const ChangePassword = React.lazy(() => import("./Components/ChangePassword"));
const ListContacts = React.lazy(() =>
  import("./Components/Contacts/ListContacts")
);
const AddContact = React.lazy(() => import("./Components/Contacts/AddContact"));
const EditContact = React.lazy(() =>
  import("./Components/Contacts/EditContact")
);
const ListLocations = React.lazy(() =>
  import("./Components/Locations/ListLocations")
);
const AddEditLocation = React.lazy(() =>
  import("./Components/Locations/AddEditLocation")
);
const ListGoals = React.lazy(() => import("./Components/Goals/ListGoals"));
const AddGoal = React.lazy(() => import("./Components/Goals/AddGoal"));
const ListConditions = React.lazy(() =>
  import("./Components/Conditions/ListConditions")
);
const AddCondition = React.lazy(() =>
  import("./Components/Conditions/AddCondition")
);
const AddReferral = React.lazy(() =>
  import("./Components/Referrals/AddReferral")
);
const ListProcedures = React.lazy(() =>
  import("./Components/Procedures/ListProcedures")
);
const AddProcedure = React.lazy(() =>
  import("./Components/Procedures/AddProcedure")
);
const ListTasks = React.lazy(() => import("./Components/Tasks/ListTasks"));
const AddTask = React.lazy(() => import("./Components/Tasks/AddTask"));
const ListCareTeams = React.lazy(() =>
  import("./Components/CareTeams/ListCareTeams")
);
const AddCareTeam = React.lazy(() =>
  import("./Components/CareTeams/AddCareTeam")
);
const ListQuestionnaires = React.lazy(() =>
  import("./Components/Questionnaires/ListQuestionnaires")
);
const ListQuestionnaireResponses = React.lazy(() =>
  import("./Components/Questionnaires/ListQuestionnaireResponses")
);
const FillQuestionnaire = React.lazy(() =>
  import("./Components/Questionnaires/FillQuestionnaire")
);
const FillForm = React.lazy(() => import("./Components/Forms/FillForm"));
const SelectForm = React.lazy(() => import("./Components/Forms/SelectForm"));
const ConfigureForm = React.lazy(() =>
  import("./Components/Forms/ConfigureForm")
);
const FormPdfViewer = React.lazy(() =>
  import("./Components/Forms/FormPdfViewer")
);
const ListForms = React.lazy(() => import("./Components/Forms/ListForms"));
const AddEditForm = React.lazy(() => import("./Components/Forms/AddEditForm"));
const EligibilityScreening = React.lazy(() =>
  import("./Components/EligibilityScreening")
);
const ListObservations = React.lazy(() =>
  import("./Components/Observations/ListObservations")
);
const AddObservation = React.lazy(() =>
  import("./Components/Observations/AddObservation")
);
const ListAliases = React.lazy(() =>
  import("./Components/Aliases/ListAliases")
);
const AddEditAlias = React.lazy(() =>
  import("./Components/Aliases/AddEditAlias")
);
const Journey = React.lazy(() => import("./Components/Journey"));
// RBAC Routes
const ListCustomers = React.lazy(() =>
  import("./Components/RBAC/Customers/ListCustomers")
);
const AddEditCustomer = React.lazy(() =>
  import("./Components/RBAC/Customers/AddEditCustomer")
);
const AssignPermissionCustomers = React.lazy(() =>
  import("./Components/RBAC/Customers/AssignPermissionCustomers")
);
const ListRoles = React.lazy(() => import("./Components/RBAC/Roles/ListRoles"));
const AddEditRole = React.lazy(() =>
  import("./Components/RBAC/Roles/AddEditRole")
);
const AssignPermissionRoles = React.lazy(() =>
  import("./Components/RBAC/Roles/AssignPermissionRoles")
);
const ListResources = React.lazy(() =>
  import("./Components/RBAC/Resources/ListResources")
);
const AddEditResource = React.lazy(() =>
  import("./Components/RBAC/Resources/AddEditResource")
);
const ROLES = appConstants.ROLES;
const MODULES = appConstants.MODULES;

const loading = () => <Loader text={"Loading"} />;

function App() {
  return (
    <Router>
      <ToastContainer autoClose={5000} transition={Slide} />
      <React.Suspense fallback={loading()}>
        <Routes>
          <Route>
            <Route
              path="/login"
              element={<Login title="OPEN CITY LABS - Login" />}
            ></Route>
            <Route
              path="/forgotPassword"
              element={
                <ForgotPassword title="OPEN CITY LABS - Forgot Password" />
              }
            ></Route>
            <Route
              path="/changePassword/:token"
              element={
                <ChangePassword title="OPEN CITY LABS - Change Password" />
              }
            ></Route>
          </Route>
          <Route
            path="/launch"
            element={<Launch title="OPEN CITY LABS - SMART-on-FHIR Launch" />}
          ></Route>
          <Route
            path="/redirect"
            element={
              <Redirect title="OPEN CITY LABS - SMART-on-FHIR Redirect" />
            }
          ></Route>
          <Route
            path="/"
            element={
              <ProtectedLayout
                allowedRole={ROLES.STAFF}
                allowedModule={MODULES.STATIC}
                allowedResource={MODULES.STATIC}
              />
            }
          >
            <Route element={<StaffLayout />}>
              <Route
                path="agency360"
                element={
                  <ProtectedLayout
                    allowedRole={ROLES.STAFF}
                    allowedModule={MODULES.AGENCY360}
                    allowedResource={MODULES.STATIC}
                  />
                }
              >
                <Route
                  path="organizations"
                  element={
                    <ProtectedLayout
                      allowedRole={ROLES.STAFF}
                      allowedModule={MODULES.AGENCY360}
                      allowedResource={"organizations"}
                    />
                  }
                >
                  <Route
                    path="edit/:id"
                    element={
                      <AddEditOrganization title="OPEN CITY LABS - Edit Organization" />
                    }
                  ></Route>
                  <Route
                    path="add"
                    element={
                      <AddEditOrganization title="OPEN CITY LABS - Add Organization" />
                    }
                  ></Route>
                  <Route
                    path="details/:id"
                    element={
                      <OrganizationDetails title="OPEN CITY LABS - Organization Profile" />
                    }
                  ></Route>
                </Route>
                <Route
                  path="contacts"
                  element={
                    <ProtectedLayout
                      allowedRole={ROLES.STAFF}
                      allowedModule={MODULES.AGENCY360}
                      allowedResource={"contacts"}
                    />
                  }
                >
                  <Route
                    path="list/:oid"
                    element={
                      <ListContacts title="OPEN CITY LABS - List Contacts" />
                    }
                  ></Route>
                  <Route
                    path="add/:oid"
                    element={
                      <AddContact title="OPEN CITY LABS - Add Contact" />
                    }
                  ></Route>
                  <Route
                    path="edit/:oid/:id"
                    element={
                      <EditContact title="OPEN CITY LABS - Edit Contact" />
                    }
                  ></Route>
                </Route>
                <Route
                  path="services"
                  element={
                    <ProtectedLayout
                      allowedRole={ROLES.STAFF}
                      allowedModule={MODULES.AGENCY360}
                      allowedResource={"services"}
                    />
                  }
                >
                  <Route
                    path="list/:oid"
                    element={
                      <ListServices title="OPEN CITY LABS - List Services" />
                    }
                  ></Route>
                  <Route
                    path="add/:oid"
                    element={
                      <AddEditService title="OPEN CITY LABS - Add Service" />
                    }
                  ></Route>
                  <Route
                    path="edit/:oid/:id"
                    element={
                      <AddEditService title="OPEN CITY LABS - Edit Service" />
                    }
                  ></Route>
                </Route>
                <Route
                  path="locations"
                  element={
                    <ProtectedLayout
                      allowedRole={ROLES.STAFF}
                      allowedModule={MODULES.AGENCY360}
                      allowedResource={"locations"}
                    />
                  }
                >
                  <Route
                    path="list/:oid"
                    element={
                      <ListLocations title="OPEN CITY LABS - List Locations" />
                    }
                  ></Route>
                  <Route
                    path="add/:oid"
                    element={
                      <AddEditLocation title="OPEN CITY LABS - Add Location" />
                    }
                  ></Route>
                  <Route
                    path="edit/:oid/:id"
                    element={
                      <AddEditLocation title="OPEN CITY LABS - Edit Location" />
                    }
                  ></Route>
                </Route>
                <Route
                  path="practitioners"
                  element={
                    <ProtectedLayout
                      allowedRole={ROLES.STAFF}
                      allowedModule={MODULES.AGENCY360}
                      allowedResource={"practitioners"}
                    />
                  }
                >
                  <Route
                    path="list/:oid"
                    element={
                      <ListPractitioners title="OPEN CITY LABS - List Practitioners" />
                    }
                  ></Route>
                  <Route
                    path="add/:oid"
                    element={
                      <AddPractitioner title="OPEN CITY LABS - Add Practitioner" />
                    }
                  ></Route>
                  <Route
                    path="edit/:id"
                    element={
                      <EditPractitioner title="OPEN CITY LABS - Edit Practitioner" />
                    }
                  ></Route>
                  <Route
                    path="details/:id"
                    element={
                      <PractitionerDetails title="OPEN CITY LABS - Practitioner Profile" />
                    }
                  ></Route>
                </Route>
              </Route>
              <Route
                path="navigator360"
                element={
                  <ProtectedLayout
                    allowedRole={ROLES.STAFF}
                    allowedModule={MODULES.NAVIGATOR360}
                    allowedResource={MODULES.STATIC}
                  />
                }
              >
                <Route
                  path="referrals"
                  element={
                    <ProtectedLayout
                      allowedRole={ROLES.STAFF}
                      allowedModule={MODULES.NAVIGATOR360}
                      allowedResource={"referrals"}
                    />
                  }
                >
                  <Route
                    path="list"
                    element={
                      <ListReferrals title="OPEN CITY LABS - List Referrals" />
                    }
                  ></Route>
                  <Route
                    path="create"
                    element={
                      <AddReferral title="OPEN CITY LABS - Add Referral" />
                    }
                  ></Route>
                </Route>
                <Route
                  path="patients"
                  element={
                    <ProtectedLayout
                      allowedRole={ROLES.STAFF}
                      allowedModule={MODULES.NAVIGATOR360}
                      allowedResource={"patients"}
                    />
                  }
                >
                  <Route
                    path="list"
                    element={
                      <ListPatients title="OPEN CITY LABS - List Patients" />
                    }
                  ></Route>
                  <Route
                    path="add"
                    element={
                      <AddEditPatients title="OPEN CITY LABS - Intake Patient" />
                    }
                  ></Route>
                  <Route
                    path="edit/:uid"
                    element={
                      <AddEditPatients title="OPEN CITY LABS - Edit Patient" />
                    }
                  ></Route>
                </Route>
                <Route
                  path="resource"
                  element={
                    <ProtectedLayout
                      allowedRole={ROLES.STAFF}
                      allowedModule={MODULES.NAVIGATOR360}
                      allowedResource={"resource"}
                    />
                  }
                >
                  <Route
                    path="search"
                    element={
                      <ResourceSearch title="OPEN CITY LABS - Resource Search" />
                    }
                  ></Route>
                  <Route
                    path="services/details/:id"
                    element={
                      <ServiceResourceDetails title="OPEN CITY LABS - Service Resource Details" />
                    }
                  ></Route>
                  <Route
                    path="organizations/details/:id"
                    element={
                      <OrganizationResourceDetails title="OPEN CITY LABS - Agency Resource Details" />
                    }
                  ></Route>
                </Route>
                <Route
                  path="goals"
                  element={
                    <ProtectedLayout
                      allowedRole={ROLES.STAFF}
                      allowedModule={MODULES.NAVIGATOR360}
                      allowedResource={"goals"}
                    />
                  }
                >
                  <Route
                    path="list"
                    element={<ListGoals title="OPEN CITY LABS - List Goals" />}
                  ></Route>
                  <Route
                    path="add"
                    element={<AddGoal title="OPEN CITY LABS - Add Goal" />}
                  ></Route>
                </Route>
                <Route
                  path="conditions"
                  element={
                    <ProtectedLayout
                      allowedRole={ROLES.STAFF}
                      allowedModule={MODULES.NAVIGATOR360}
                      allowedResource={"conditions"}
                    />
                  }
                >
                  <Route
                    path="list"
                    element={
                      <ListConditions title="OPEN CITY LABS - List Conditions" />
                    }
                  ></Route>
                  <Route
                    path="add"
                    element={
                      <AddCondition title="OPEN CITY LABS - Add Condition" />
                    }
                  ></Route>
                </Route>
                <Route
                  path="procedures"
                  element={
                    <ProtectedLayout
                      allowedRole={ROLES.STAFF}
                      allowedModule={MODULES.NAVIGATOR360}
                      allowedResource={"procedures"}
                    />
                  }
                >
                  <Route
                    path="list"
                    element={
                      <ListProcedures title="OPEN CITY LABS - List Procedures" />
                    }
                  ></Route>
                  <Route
                    path="add"
                    element={
                      <AddProcedure title="OPEN CITY LABS - Add Procedure" />
                    }
                  ></Route>
                </Route>
                <Route
                  path="tasks"
                  element={
                    <ProtectedLayout
                      allowedRole={ROLES.STAFF}
                      allowedModule={MODULES.NAVIGATOR360}
                      allowedResource={"tasks"}
                    />
                  }
                >
                  <Route
                    path="list"
                    element={<ListTasks title="OPEN CITY LABS - List Tasks" />}
                  ></Route>
                  <Route
                    path="add"
                    element={<AddTask title="OPEN CITY LABS - Add Task" />}
                  ></Route>
                  <Route
                    path="questionnaires/fill/:id"
                    element={
                      <FillQuestionnaire title="OPEN CITY LABS - Fill Questionnaires" />
                    }
                  ></Route>
                  <Route
                    path="form/fill/:id"
                    element={
                      <FillForm title="OPEN CITY LABS - Fill PDF Form" />
                    }
                  ></Route>
                </Route>
                <Route
                  path="careteams"
                  element={
                    <ProtectedLayout
                      allowedRole={ROLES.STAFF}
                      allowedModule={MODULES.NAVIGATOR360}
                      allowedResource={"careteams"}
                    />
                  }
                >
                  <Route
                    path="list"
                    element={
                      <ListCareTeams title="OPEN CITY LABS - List Care Teams" />
                    }
                  ></Route>
                  <Route
                    path="add"
                    element={
                      <AddCareTeam title="OPEN CITY LABS - Add Care Team" />
                    }
                  ></Route>
                </Route>
                <Route
                  path="questionnaires"
                  element={
                    <ProtectedLayout
                      allowedRole={ROLES.STAFF}
                      allowedModule={MODULES.NAVIGATOR360}
                      allowedResource={"questionnaires"}
                    />
                  }
                >
                  <Route
                    path="list"
                    element={
                      <ListQuestionnaires title="OPEN CITY LABS - List Questionnaires" />
                    }
                  ></Route>
                </Route>
                <Route
                  path="questionnaires-response"
                  element={
                    <ProtectedLayout
                      allowedRole={ROLES.STAFF}
                      allowedModule={MODULES.NAVIGATOR360}
                      allowedResource={"questionnaireresponse"}
                    />
                  }
                >
                  <Route
                    path="list"
                    element={
                      <ListQuestionnaireResponses title="OPEN CITY LABS - List Questionnaire Responses" />
                    }
                  ></Route>
                  <Route
                    path="view/:id"
                    element={
                      <FillQuestionnaire title="OPEN CITY LABS - View Questionnaire response" />
                    }
                  ></Route>
                  <Route
                    path="form/view/:id"
                    element={
                      <FillForm title="OPEN CITY LABS - View Questionnaire Form response" />
                    }
                  ></Route>
                </Route>
                <Route
                  path="observations"
                  element={
                    <ProtectedLayout
                      allowedRole={ROLES.STAFF}
                      allowedModule={MODULES.NAVIGATOR360}
                      allowedResource={"observations"}
                    />
                  }
                >
                  <Route
                    path="list"
                    element={
                      <ListObservations title="OPEN CITY LABS - List Observations" />
                    }
                  ></Route>
                  <Route
                    path="add"
                    element={
                      <AddObservation title="OPEN CITY LABS - Add Observation" />
                    }
                  ></Route>
                </Route>
                <Route path="practitioners">
                  <Route
                    path="details/:id"
                    element={
                      <PractitionerDetails title="OPEN CITY LABS - Practitioner Profile" />
                    }
                  ></Route>
                  <Route
                    path="edit/:id"
                    element={
                      <EditPractitioner title="OPEN CITY LABS - Edit Practitioner" />
                    }
                  ></Route>
                </Route>
                <Route
                  path="form/fill/:id/:sid"
                  element={<FillForm title="OPEN CITY LABS - Fill PDF Form" />}
                ></Route>
                <Route
                  path="form/pdf/viewer/:id/:sid"
                  element={
                    <FormPdfViewer title="OPEN CITY LABS - View PDF Form" />
                  }
                ></Route>
                <Route
                  path=""
                  element={
                    <ProtectedLayout
                      allowedRole={ROLES.STAFF}
                      allowedModule={MODULES.FEATURES}
                      allowedResource={"eligibilityscreening"}
                    />
                  }
                >
                  <Route
                    path="screening/:id"
                    element={
                      <EligibilityScreening title="OPEN CITY LABS - Eligibility Screening" />
                    }
                  ></Route>
                </Route>
              </Route>
              <Route
                path="journey360"
                element={
                  <ProtectedLayout
                    allowedRole={ROLES.STAFF}
                    allowedModule={MODULES.JOURNEY360}
                    allowedResource={MODULES.STATIC}
                  />
                }
              >
                <Route
                  path="journey/:id"
                  element={<Journey title="OPEN CITY LABS - Patient Journey" />}
                ></Route>
              </Route>
              <Route
                path="/privacy-policy"
                element={
                  <ProtectedLayout
                    allowedRole={ROLES.STAFF}
                    allowedModule={MODULES.STATIC}
                    allowedResource={MODULES.STATIC}
                  >
                    <Privacy title="OPEN CITY LABS - Privacy Policy" />
                  </ProtectedLayout>
                }
              ></Route>
            </Route>
          </Route>
          <Route
            path="/"
            element={<ProtectedLayout allowedRole={ROLES.SUPERADMIN} />}
          >
            <Route element={<SuperAdminLayout />}>
              <Route path="organizations">
                <Route
                  path="list"
                  element={
                    <ListOrganizations title="OPEN CITY LABS - List Organizations" />
                  }
                ></Route>
                <Route
                  path="edit/:id"
                  element={
                    <AddEditOrganization title="OPEN CITY LABS - Edit Organization" />
                  }
                ></Route>
                <Route
                  path="add"
                  element={
                    <AddEditOrganization title="OPEN CITY LABS - Add Organization" />
                  }
                ></Route>
                <Route
                  path="details/:id"
                  element={
                    <OrganizationDetails title="OPEN CITY LABS - Organization Profile" />
                  }
                ></Route>
              </Route>
              <Route path="locations">
                <Route
                  path="list/:oid"
                  element={
                    <ListLocations title="OPEN CITY LABS - List Locations" />
                  }
                ></Route>
                <Route
                  path="add/:oid"
                  element={
                    <AddEditLocation title="OPEN CITY LABS - Add Location" />
                  }
                ></Route>
                <Route
                  path="edit/:oid/:id"
                  element={
                    <AddEditLocation title="OPEN CITY LABS - Edit Location" />
                  }
                ></Route>
              </Route>
              <Route path="services">
                <Route
                  path="list"
                  element={
                    <ListServices title="OPEN CITY LABS - List Services" />
                  }
                ></Route>
                <Route
                  path="add"
                  element={
                    <AddEditService title="OPEN CITY LABS - Add Service" />
                  }
                ></Route>
                <Route
                  path="edit/:id"
                  element={
                    <AddEditService title="OPEN CITY LABS - Edit Service" />
                  }
                ></Route>
                <Route
                  path="form/select/:id"
                  element={
                    <SelectForm title="OPEN CITY LABS - Select PDF Form" />
                  }
                ></Route>
                <Route
                  path="form/configure/:id"
                  element={
                    <ConfigureForm title="OPEN CITY LABS - Configure PDF Form" />
                  }
                ></Route>
              </Route>
              <Route path="forms">
                <Route
                  path="list"
                  element={
                    <ListForms title="OPEN CITY LABS - List Pdf Forms" />
                  }
                ></Route>
                <Route
                  path="add"
                  element={
                    <AddEditForm title="OPEN CITY LABS - Add Pdf Form" />
                  }
                ></Route>
                <Route
                  path="edit/:id"
                  element={
                    <AddEditForm title="OPEN CITY LABS - Edit Pdf Form" />
                  }
                ></Route>
              </Route>
              <Route path="practitioners">
                <Route
                  path="list"
                  element={
                    <ListPractitioners title="OPEN CITY LABS - List Practitioners" />
                  }
                ></Route>
                <Route
                  path="add"
                  element={
                    <AddPractitioner title="OPEN CITY LABS - Add Practitioner" />
                  }
                ></Route>
                <Route
                  path="edit/:id"
                  element={
                    <EditPractitioner title="OPEN CITY LABS - Edit Practitioner" />
                  }
                ></Route>
                <Route
                  path="details/:id"
                  element={
                    <PractitionerDetails title="OPEN CITY LABS - Practitioner Profile" />
                  }
                ></Route>
              </Route>
              <Route path="aliases">
                <Route
                  path="list"
                  element={
                    <ListAliases title="OPEN CITY LABS - List Aliases" />
                  }
                ></Route>
                <Route
                  path="add"
                  element={<AddEditAlias title="OPEN CITY LABS - Add Alias" />}
                ></Route>
                <Route
                  path="edit/:id"
                  element={<AddEditAlias title="OPEN CITY LABS - Edit Alias" />}
                ></Route>
              </Route>
              <Route path="practitioner-roles">
                <Route
                  path="list"
                  element={
                    <ListPractitionerRoles title="OPEN CITY LABS - List Practitioners Roles" />
                  }
                ></Route>
                <Route
                  path="add"
                  element={
                    <AddEditPractitionerRoles title="OPEN CITY LABS - Add Practitioners Role" />
                  }
                ></Route>
                <Route
                  path="edit/:id"
                  element={
                    <AddEditPractitionerRoles title="OPEN CITY LABS - Edit Practitioners Role" />
                  }
                ></Route>
              </Route>
              <Route path="/rbac">
                <Route path="customers">
                  <Route
                    path="list"
                    element={
                      <ListCustomers title="OPEN CITY LABS - List Customers" />
                    }
                  ></Route>
                  <Route
                    path="edit/:id"
                    element={
                      <AddEditCustomer title="OPEN CITY LABS - Edit Customer" />
                    }
                  ></Route>
                  <Route
                    path="add"
                    element={
                      <AddEditCustomer title="OPEN CITY LABS - Add Customer" />
                    }
                  ></Route>
                  <Route
                    path="permission/:id"
                    element={
                      <AssignPermissionCustomers title="OPEN CITY LABS - Assign Permission To Customers" />
                    }
                  ></Route>
                </Route>
                <Route path="roles">
                  <Route
                    path="list"
                    element={<ListRoles title="OPEN CITY LABS - List Roles" />}
                  ></Route>
                  <Route
                    path="edit/:id"
                    element={<AddEditRole title="OPEN CITY LABS - Edit Role" />}
                  ></Route>
                  <Route
                    path="add"
                    element={<AddEditRole title="OPEN CITY LABS - Add Role" />}
                  ></Route>
                  <Route
                    path="permission/:id"
                    element={
                      <AssignPermissionRoles title="OPEN CITY LABS - Assign Permission To Roles" />
                    }
                  ></Route>
                </Route>
                <Route path="resources">
                  <Route
                    path="list"
                    element={
                      <ListResources title="OPEN CITY LABS - List Resources" />
                    }
                  ></Route>
                  <Route
                    path="edit/:id"
                    element={
                      <AddEditResource title="OPEN CITY LABS - Edit Resource" />
                    }
                  ></Route>
                  <Route
                    path="add"
                    element={
                      <AddEditResource title="OPEN CITY LABS - Add Resource" />
                    }
                  ></Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </React.Suspense>
    </Router>
  );
}

export default App;
