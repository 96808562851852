import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";
const initialState = {
  pageCount: 0,
  rowCount: 0,
  text: "",
  list: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const getCustomers = createAsyncThunk("customers", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .get(
        `/rbaccustomers?text=${params.text}&offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}`
      )
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const getCustomerById = createAsyncThunk(
  "customer/:id",
  async (id, thunkApi) => {
    try {
      return await axiosInstance
        .get(`/rbaccustomers/${id}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateCustomerPermission = createAsyncThunk(
  "customer/permission/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/rbaccustomers/permission/${params.id}`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateCustomer = createAsyncThunk(
  "customer/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/rbaccustomers/${params.id}`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const addCustomer = createAsyncThunk(
  "customer/add",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/rbaccustomers`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deleteCustomer = createAsyncThunk(
  "customer/delete",
  async (id, thunkApi) => {
    try {
      return await axiosInstance
        .delete(`/rbaccustomers/${id}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const { actions, reducer } = createSlice({
  name: "rbacCustomers",
  initialState,
  reducers: {
    setIntialProps: (state, action) => {
      return { ...state, ...action?.payload };
    },
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //get rbac customers
      .addCase(getCustomers.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data?.customers,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getCustomers.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { setIntialProps, clearStatus } = actions;

const rbacCustomerSlice = {
  getCustomers,
  getCustomerById,
  updateCustomer,
  updateCustomerPermission,
  addCustomer,
  deleteCustomer,
  setIntialProps,
  clearStatus,
  reducer,
};

export default rbacCustomerSlice;
