import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";
const initialState = {
  pageCount: 0,
  rowCount: 0,
  text: "",
  patient: {},
  selectedPatient: null,
  list: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  isErrorList: false,
  isSuccessList: false,
  isFetchingList: false,
  successMessage: "",
  errorMessage: "",
};

const getPatients = createAsyncThunk("patients", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .get(
        `/patients?text=${params.text}&offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}`
      )
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const getPatientById = createAsyncThunk("patients/id", async (id, thunkApi) => {
  try {
    return await axiosInstance
      .get(`/patients/${id}`)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const getFieldType = createAsyncThunk(
  "patient/api/getFieldType",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/patient/api/getFieldType`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updatePatient = createAsyncThunk(
  "patients/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/patients/${params.id}`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const addPatient = createAsyncThunk(
  "patients/add",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/patients`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const addFhirPatient = createAsyncThunk(
  "patients/fhir",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post("/patients/fhir", params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getReferences = createAsyncThunk(
  "patients/references",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(`/patients/references/${params.id}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const { actions, reducer } = createSlice({
  name: "patients",
  initialState,
  reducers: {
    setSelectedPatient: (state, action) => {
      state.selectedPatient = action?.payload;
      if (!action?.payload) state.patient = null;
    },
    setIntialProps: (state, action) => {
      return { ...state, ...action?.payload };
    },
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.isErrorList = false;
      state.isSuccessList = false;
      state.isFetchingList = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //get patients
      .addCase(getPatients.pending, (state) => {
        return {
          ...state,
          isFetchingList: true,
        };
      })
      .addCase(getPatients.fulfilled, (state, action) => {
        return {
          ...state,
          isFetchingList: false,
          isSuccessList: true,
          list: action?.payload?.data?.patients,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getPatients.rejected, (state, action) => {
        return {
          ...state,
          isFetchingList: false,
          isErrorList: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      })
      // get patient by id
      .addCase(getPatientById.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getPatientById.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          patient: action?.payload?.data,
          selectedPatient: action?.payload?.data?._id,
        };
      })
      .addCase(getPatientById.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { setIntialProps, setSelectedPatient, clearStatus } = actions;

const getSelectedPatient = (state) => state.patients.selectedPatient;

const patientSlice = {
  clearStatus,
  getPatients,
  getPatientById,
  getFieldType,
  updatePatient,
  addPatient,
  addFhirPatient,
  getReferences,
  setSelectedPatient,
  getSelectedPatient,
  setIntialProps,
  reducer,
};

export default patientSlice;
