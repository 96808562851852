import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";

const getOrganizationContacts = createAsyncThunk(
  "organizations/contact",
  async (id, thunkApi) => {
    try {
      return await axiosInstance
        .get(`/organizations/contact/${id}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const addOrganizationContact = createAsyncThunk(
  "organizations/contact/add",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/practitioners`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateOrganizationContact = createAsyncThunk(
  "organizations/contact/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/organizations/contact/${params.id}`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deleteOrganizationContact = createAsyncThunk(
  "organizations/contact/delete",
  async (id, thunkApi) => {
    try {
      return await axiosInstance
        .delete(`/organizations/contact/${id}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const contactSlice = {
  getOrganizationContacts,
  addOrganizationContact,
  updateOrganizationContact,
  deleteOrganizationContact,
};

export default contactSlice;
