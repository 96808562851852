import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  client: null,
};

const { actions, reducer } = createSlice({
  name: "fhir",
  initialState,
  reducers: {
    setFhirClient: (state, action) => {
      return { ...initialState, ...action?.payload };
    },
  },
});

const { setFhirClient } = actions;

const getFhirClient = (state) => state.fhir;

const fhirSlice = {
  setFhirClient,
  getFhirClient,
  reducer,
};

export default fhirSlice;
