import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon, faXmark } from "../../../Assets/icons";

export default function NoPatientSelected({ onClose }) {
  const navigate = useNavigate();

  return (
    <div
      id="updateProfileModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      className="modal modal--show"
    >
      <div className="modal__dialog modal__dialog--centered" role="document">
        <div className="modal__content">
          <div className="modal__header">
            <div className="modal__header__title">
              <h5 className="title--h4">No Patient Selected</h5>
            </div>
            <button
              onClick={onClose}
              type="button"
              className="btn btn--clear"
              data-dismiss="modal"
              aria-label="Close"
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <div className="modal__body">
            <div className="modal__body__content">
              <h5 className="title--h5 mb_10">
                You must select a patient/client before making a referral.
              </h5>
              Please click on “Intake” in the menu to the left to create or
              select a patient/client.
            </div>
          </div>
          <div className="modal__footer">
            <button
              onClick={() => {
                navigate(`/navigator360/patients/list`);
              }}
              type="button"
              className="btn btn--success"
            >
              Go To Intake
            </button>
            <button
              type="button"
              className="btn btn--secondary"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
