import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";
const initialState = {
  pageCount: 0,
  rowCount: 0,
  text: "",
  code: "",
  organization: "",
  list: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const getPractitionerRoles = createAsyncThunk(
  "practitionerrole",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(
          `/practitionerrole?text=${params.text}&offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}&organization=${params.organization}&code=${params.code}`
        )
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getPractitionerRoleById = createAsyncThunk(
  "practitionerrole/id",
  async (id, thunkApi) => {
    try {
      return await axiosInstance
        .get(`/practitionerrole/${id}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updatePractitionerRole = createAsyncThunk(
  "practitionerrole/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/practitionerrole/${params.id}`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const addPractitionerRole = createAsyncThunk(
  "practitionerrole/add",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/practitionerrole`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deletePractitionerRole = createAsyncThunk(
  "practitionerrole/delete",
  async (id, thunkApi) => {
    try {
      return await axiosInstance
        .delete(`/practitionerrole/${id}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const { actions, reducer } = createSlice({
  name: "practitionerRoles",
  initialState,
  reducers: {
    setIntialProps: (state, action) => {
      return { ...state, ...action?.payload };
    },
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //get practitionerRoles
      .addCase(getPractitionerRoles.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getPractitionerRoles.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data?.practitionerRoles,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getPractitionerRoles.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { clearStatus, setIntialProps } = actions;

const practitionerRoleSlice = {
  clearStatus,
  getPractitionerRoles,
  getPractitionerRoleById,
  updatePractitionerRole,
  addPractitionerRole,
  deletePractitionerRole,
  setIntialProps,
  reducer,
};

export default practitionerRoleSlice;
