import { useSelector, useDispatch } from "react-redux";
import { paginationSlice } from "../../../Features";
import images from "../../../Assets/images";

export default function Pagination() {
  const dispatch = useDispatch();
  const { currentPage, pageCount, rowCount, pageList, limit } = useSelector(
    (state) => state.pagination
  );

  return (
    <>
      {rowCount > 10 ? (
        <ul className="pagination">
          <li
            onClick={() => {
              let page = 1;
              dispatch(
                paginationSlice.setPaginationProps({
                  offset: (page - 1) * limit,
                  currentPage: page,
                  pageCount,
                  rowCount,
                  pageList,
                })
              );
            }}
            className={`pagination__page ${
              currentPage === 1 ? "pagination__page--disabled" : ""
            }`}
            role="button"
          >
            <img src={images.firstPage} alt="First Page" />
          </li>
          <li
            onClick={() => {
              let page = currentPage - 1;
              dispatch(
                paginationSlice.setPaginationProps({
                  offset: (page - 1) * limit,
                  currentPage: page,
                  pageCount,
                  rowCount,
                  pageList,
                })
              );
            }}
            className={`pagination__page ${
              currentPage === 1 ? "pagination__page--disabled" : ""
            }`}
            role="button"
          >
            <img src={images.prevPage} alt="Prev Page" />
          </li>
          {pageList?.length > 0
            ? pageList.map((value, index) => {
                return (
                  <li
                    onClick={() => {
                      let page = value;
                      dispatch(
                        paginationSlice.setPaginationProps({
                          offset: (page - 1) * limit,
                          currentPage: page,
                          pageCount,
                          rowCount,
                          pageList,
                        })
                      );
                    }}
                    key={index}
                    className={`pagination__page ${
                      currentPage === value ? "pagination__page--current" : ""
                    }`}
                    role="button"
                  >
                    {value}
                  </li>
                );
              })
            : null}
          <li
            onClick={() => {
              let page = currentPage + 1;
              dispatch(
                paginationSlice.setPaginationProps({
                  offset: (page - 1) * limit,
                  currentPage: page,
                  pageCount,
                  rowCount,
                  pageList,
                })
              );
            }}
            role="button"
            className={`pagination__page ${
              currentPage === pageCount ? "pagination__page--disabled" : ""
            }`}
          >
            <img src={images.nextPage} alt="Next Page" />
          </li>
          <li
            onClick={() => {
              let page = pageCount;
              dispatch(
                paginationSlice.setPaginationProps({
                  offset: (page - 1) * limit,
                  currentPage: page,
                  pageCount,
                  rowCount,
                  pageList,
                })
              );
            }}
            role="button"
            className={`pagination__page ${
              currentPage === pageCount ? "pagination__page--disabled" : ""
            }`}
          >
            <img src={images.lastPage} alt="Last Page" />
          </li>
        </ul>
      ) : (
        ""
      )}
    </>
  );
}
