import config from "../../../Config";

export default function ReferralEmail(props) {
  return (
    <>
      <p>
        <strong>Hello,</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        You have received a patient/client referral from
        <strong> {props.username}</strong>, who works at:
        <p>
          <br />
        </p>
        {/* <p>NJ Integrated Care for Kids</p>
        <p>(NJ InCK) Children and Family Health Institute</p> */}
        <p>{props.requesterOrganization}</p>
        <p>{props.performerLocation}</p>
        {props.phone ? (
          <p>Phone: {props.phone}</p>
        ) : props.email ? (
          <p>Email: {props.email}</p>
        ) : props.url ? (
          <p>Url: {props.url}</p>
        ) : (
          ""
        )}
        <p>
          <br />
        </p>
        In order to securely protect patient/client information, you can view
        your referral <a href={`${config.BACKEND_URL}/login`}>here.</a>
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>Regards,</p>
      <p>{props.username}</p>
      <p>
        <br />
      </p>
    </>
  );
}
