const messages = {
  SUCCESS: "Success",
  LOGIN_SUCCESS: "Login Successfull",
  LOGOUT_SUCCESS: "Logout Successfull",
  SERVER_ERROR: "No Server Response.",
  NO_DATA_TEXT: "There's nothing to display.",
  SELECT_REQUIRED: "Please select one of the options to continue",
  NO_PROCEDURES: "No Procedures/Interventions recorded yet.",
  NO_PARTICIPANTS: "No Participants.",
  INVALID_PASSWORD: "Provided password is invalid",
  PASSWORD_REQUIRED: "Password is required.",
  OLD_PASSWORD_REQUIRED: "Old Password is required.",
  CONFIRM_PASSWORD_REQUIRED: "Confirm Password is required.",
  PASSWORD_NO_MATCH: "Password does not match.",
  EMAIL_REQUIRED: "Email is required.",
  FIELD_REQUIRED: "This field is required.",
  FILE_REQUIRED: "Please select a file",
  OPTIONS_REQUIRED: "Option value & label are required",
  UNIT_ERROR: "Low & High units should be same",
  COMPARE_ERROR: "Lower value should be lower than the higher value.",
  EITHER_FIELD_REQUIRED: "One of these fields is required",
  INVALID_PHONE: "The entered value is not a valid Phone Number.",
  INVALID_EMAIL: "The entered value is not a valid Email.",
  INVALID_URL: "The entered value is not a valid Url.",
  INVALID_YEAR: "The entered value is not a valid Year.",
  DELETE_REFERRAL: "Are you sure you want to delete this referral ?",
  DELETE_CONDITION: "Are you sure you want to delete this condition ?",
  DELETE_GOAL: "Are you sure you want to delete this goal ?",
  DELETE_PROCEDURE: "Are you sure you want to delete this procedure ?",
  DELETE_TASK: "Are you sure you want to delete this task ?",
  DELETE_CARETEAM: "Are you sure you want to delete this care team ?",
  DELETE_ORG_USER:
    "Are you sure you want to delete this user from the organization ?",
  DELETE_ORG_SERVICE:
    "Are you sure you want to delete this service from the organization ?",
  DELETE_ORG_CONTACT:
    "Are you sure you want to delete this contact from the organization ?",
  DELETE_ORG_LOCATION:
    "Are you sure you want to delete this location for the organization ?",
  DELETE_SERVICE: "Are you sure you want to delete this service ?",
  DELETE_ORGANIZATION: "Are you sure you want to delete this organization ?",
  DELETE_QUESTIONNAIRE: "Are you sure you want to delete this questionnaire ?",
  DELETE_QUESTIONNAIRERESPONSE:
    "Are you sure you want to delete this questionnaire response ?",
  DELETE_ALIAS: "Are you sure you want to delete this alias ?",
  NO_MAPPING_SOURCE: "No source has been selected for this mapping",
  NO_MAPPING_TARGET: "No target has been selected for this mapping",
  NO_MAPPING: "No mappings were created",
  DELETE_MAPPING: "Are you sure you want to delete this mapping ?",
  DELETE_FORM: "Are you sure you want to delete this form ?",
  DELETE_CUSTOMER: "Are you sure you want to delete this customer ?",
  DELETE_ROLE: "Are you sure you want to delete this role ?",
  DELETE_RESOURCE: "Are you sure you want to delete this resource ?",
  SELECT_ROLE: "Please select a role to continue.",
  DELETE_PRACTITIONER_ROLE:
    "Are you sure you want to delete this role for the practitioner ?",
};

export default messages;
