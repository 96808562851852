import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";

const initialState = {
  pageCount: 0,
  rowCount: 0,
  category: "",
  status: "",
  list: [],
  selectedRow: null,
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const getProcedures = createAsyncThunk(
  "procedures",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(
          `/procedures?offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}&category=${params.category}&status=${params.status}&subject=${params.subject}`
        )
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const addProcedure = createAsyncThunk(
  "procedures/add",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/procedures`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateProcedure = createAsyncThunk(
  "procedures/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/procedures`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deleteProcedure = createAsyncThunk(
  "procedures/delete",
  async (id, thunkApi) => {
    try {
      return await axiosInstance
        .delete(`/procedures/${id}`)
        .then((response) => response.data)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const { actions, reducer } = createSlice({
  name: "procedures",
  initialState,
  reducers: {
    setIntialProps: (state, action) => {
      return { ...state, ...action?.payload };
    },
    setSelectedRow: (state, action) => {
      state.selectedRow = action?.payload;
    },
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // get procedures
      .addCase(getProcedures.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getProcedures.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data?.procedures,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getProcedures.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { setIntialProps, setSelectedRow, clearStatus } = actions;

const procedureSlice = {
  getProcedures,
  addProcedure,
  updateProcedure,
  deleteProcedure,
  setSelectedRow,
  setIntialProps,
  clearStatus,
  reducer,
};

export default procedureSlice;
