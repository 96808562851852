import { FontAwesomeIcon, faCircleInfo } from "../../../Assets/icons";
import { CTooltip } from "@coreui/react";

const Label = ({ tooltip, content, label, id, isRequired }) => {
  return (
    <>
      {tooltip ? (
        <CTooltip content={content}>
          <label className="label label--tooltip" htmlFor={id}>
            {label}
            {isRequired ? <span className="asterisk">*</span> : ""}
            <FontAwesomeIcon icon={faCircleInfo} />
          </label>
        </CTooltip>
      ) : (
        <>
          <label className="label" htmlFor={id}>
            {label} {isRequired ? <span className="asterisk">*</span> : ""}
          </label>
        </>
      )}
    </>
  );
};

export default Label;
