import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import Label from "../Label";
import Select from "react-select";
import { FontAwesomeIcon, faTrash, faPlus } from "../../../Assets/icons";
import { appConstants, messageConstants } from "../../../Constants";
import Autocomplete from "react-google-autocomplete";
import config from "../../../Config";
import { asyncForEach } from "../../../Utils";
import _ from "lodash";

const Address = forwardRef(({ rows, isRequired }, ref) => {
  const [error, setError] = useState({});
  const [addresses, setAddress] = useState([{}]);
  const addressTypeOpts = appConstants.addressTypeOpts;
  useEffect(() => {
    setAddress(rows?.length ? rows : [{}]);
  }, [rows]);

  const removefields = (index) => {
    setAddress((addresses) => addresses.filter((v, i) => i !== index));
  };

  const addfields = (index) => {
    if (addresses[index].formattedAddress) setAddress([...addresses, {}]);
  };

  useImperativeHandle(ref, () => ({
    handleSubmit(event) {
      event.preventDefault();
      let invalid = false;
      asyncForEach(addresses, (val, index) => {
        isRequired && setError(validate(val, index));
        invalid =
          isRequired &&
          Object.values(_(validate(val, index)).pickBy(_.identity).value())
            .length
            ? true
            : false;
      });
      if (!invalid) return addresses.filter((t) => t.formattedAddress);
    },
  }));

  const validate = (values, index) => {
    const errorObj = {};
    !values.formattedAddress
      ? (errorObj[`formattedAddress${index}`] = messageConstants.FIELD_REQUIRED)
      : (errorObj[`formattedAddress${index}`] = "");
    return errorObj;
  };

  return (
    <div className="col_xs_12">
      <div className="repeater">
        {addresses?.length
          ? addresses.map((value, index) => {
              return (
                <div key={index} className="repeater__row">
                  <div className="repeater__row__fields">
                    <div className="row">
                      <div className="col_xl_4 col_md_6">
                        <Label
                          tooltip={true}
                          content="Name of the Address(es) that are not role specific (typically home address)."
                          label="Name"
                          isRequired={false}
                          id={`name${index}`}
                        />
                        <input
                          type="text"
                          name={`name${index}`}
                          id={`name${index}`}
                          onChange={(event) => {
                            const { value } = event.target;
                            const fieldsArr = [...addresses];
                            fieldsArr[index] = {
                              ...fieldsArr[index],
                              name: value,
                            };
                            setAddress(fieldsArr);
                          }}
                          className={
                            error[`name${index}`] ? "control error" : "control"
                          }
                          autoComplete="off"
                          value={value?.name || ""}
                        />
                        {error?.[`name${index}`] ? (
                          <p className={error[`name${index}`] ? "error" : ""}>
                            {error[`name${index}`]}
                          </p>
                        ) : null}
                      </div>
                      <div className="col_xl_4 col_md_6">
                        <Label
                          tooltip={true}
                          content="Address(es) that are not role specific (typically home address)."
                          label="Address"
                          isRequired={false}
                          aria-label="address"
                        />
                        <Autocomplete
                          className={
                            error.formattedAddress ? "control error" : "control"
                          }
                          aria-label="address"
                          autoComplete="off"
                          defaultValue={value?.formattedAddress || ""}
                          apiKey={config.GOOGLE_API_KEY}
                          onChange={() => {}}
                          onPlaceSelected={async (place) => {
                            const fieldsArr = [...addresses];
                            let placeObj = {
                              formattedAddress: place.formatted_address,
                              placeId: place.place_id,
                              name: place.name,
                              position: {
                                type: "Point",
                                coordinates: [
                                  place.geometry.location.lat(),
                                  place.geometry.location.lng(),
                                ],
                              },
                            };
                            await _.each(place.address_components, (val) => {
                              if (_.includes(val.types, "street_number")) {
                                placeObj.address_1 = val.long_name;
                              }
                              if (_.includes(val.types, "route")) {
                                placeObj.address_1 = placeObj.address_1
                                  ? `${placeObj.address_1} ${val.long_name}`
                                  : val.long_name;
                              }
                              if (_.includes(val.types, "subpremise")) {
                                placeObj.address_1 = placeObj.address_1
                                  ? `${placeObj.address_1} #${val.long_name}`
                                  : val.long_name;
                              }
                              if (_.includes(val.types, "neighborhood")) {
                                placeObj.address_2 = val.long_name;
                              }
                              if (
                                _.includes(val.types, "sublocality_level_1") ||
                                _.includes(val.types, "sublocality") ||
                                _.includes(val.types, "locality")
                              ) {
                                placeObj.city = val.long_name;
                              }
                              if (
                                _.includes(
                                  val.types,
                                  "administrative_area_level_2"
                                )
                              ) {
                                placeObj.county = val.long_name;
                              }
                              if (
                                _.includes(
                                  val.types,
                                  "administrative_area_level_1"
                                )
                              ) {
                                placeObj.state = val.long_name;
                              }
                              if (_.includes(val.types, "country")) {
                                placeObj.country = val.long_name;
                              }
                              if (_.includes(val.types, "postal_code")) {
                                placeObj.postalCode = val.long_name;
                              }
                            });
                            fieldsArr[index] = placeObj.formattedAddress && {
                              ...fieldsArr[index],
                              ...placeObj,
                              name: fieldsArr?.[index]?.name
                                ? fieldsArr?.[index]?.name
                                : place.name,
                            };
                            setAddress(fieldsArr);
                          }}
                          options={{
                            types: ["address"],
                          }}
                          name="formattedAddress"
                        />
                        {error?.[`formattedAddress${index}`] ? (
                          <p
                            className={
                              error[`formattedAddress${index}`] ? "error" : ""
                            }
                          >
                            {error[`formattedAddress${index}`]}
                          </p>
                        ) : null}
                      </div>
                      <div className="col_xl_4 col_md_6">
                        <Label
                          tooltip={true}
                          content="The type of an address (physical / postal)."
                          label="Type"
                          isRequired={false}
                          id={`type${index}`}
                          aria-label="type"
                        />
                        <Select
                          value={
                            value?.type &&
                            addressTypeOpts.find((g) => g?.code === value?.type)
                          }
                          name={`type${index}`}
                          id={`type${index}`}
                          aria-label="type"
                          onChange={(option) => {
                            const fieldsArr = [...addresses];
                            fieldsArr[index] = {
                              ...fieldsArr[index],
                              type: option?.code,
                            };
                            setAddress(fieldsArr);
                          }}
                          isSearchable
                          isClearable
                          classNamePrefix={`${
                            error[`type${index}`] ? "select__error" : "select"
                          }`}
                          options={addressTypeOpts}
                          getOptionValue={(option) => option.code}
                          getOptionLabel={(option) => option.display}
                        />
                        {error?.[`type${index}`] ? (
                          <p className={error[`type${index}`] ? "error" : ""}>
                            {error[`type${index}`]}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="repeater__row__button">
                    {index === addresses.length - 1 ? (
                      <button
                        onClick={() => addfields(index)}
                        className="btn btn--success"
                        aria-label="add"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    ) : null}

                    {index !== addresses.length - 1 ? (
                      <button
                        onClick={() => removefields(index)}
                        className="btn btn--danger"
                        aria-label="delete"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    ) : null}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
});

export default Address;
