import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";

const initialState = {
  pageCount: 0,
  rowCount: 0,
  code: "",
  status: "",
  text: "",
  list: [],
  selectedRow: null,
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const getQuestionnaires = createAsyncThunk(
  "questionnaires",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(
          `/questionnaires?offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}&code=${params.code}&status=${params.status}&text=${params.text}`
        )
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const addQuestionnaireResponse = createAsyncThunk(
  "questionnaires/response",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/questionnaires/response`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getQuestionnaireResponses = createAsyncThunk(
  "questionnaires/response",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(
          `/questionnaires/response?offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}&code=${params.code}&status=${params.status}&text=${params.text}&subject=${params.subject}`
        )
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getQuestionnaireById = createAsyncThunk(
  "questionnaire/id",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(`/questionnaires/${params.id}?subject=${params.subject}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateQuestionnaire = createAsyncThunk(
  "questionnaire/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/questionnaires`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateQuestionnaireResponse = createAsyncThunk(
  "questionnaire/response/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/questionnaires/response`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deleteQuestionnaireResponse = createAsyncThunk(
  "questionnaire/response/delete",
  async (id, thunkApi) => {
    try {
      return await axiosInstance
        .delete(`/questionnaires/response/${id}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deleteQuestionnaire = createAsyncThunk(
  "questionnaire/delete",
  async (id, thunkApi) => {
    try {
      return await axiosInstance
        .delete(`/questionnaires/${id}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const { actions, reducer } = createSlice({
  name: "questionnaires",
  initialState,
  reducers: {
    setIntialProps: (state, action) => {
      return { ...state, ...action?.payload };
    },
    setSelectedRow: (state, action) => {
      state.selectedRow = action?.payload;
    },
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // get questionnaires
      .addCase(getQuestionnaires.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getQuestionnaires.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data?.questionnaires,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getQuestionnaires.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      })
      // get questionnaire responses
      .addCase(getQuestionnaireResponses.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getQuestionnaireResponses.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data?.questionnaireResponses,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getQuestionnaireResponses.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { setIntialProps, setSelectedRow, clearStatus } = actions;

const questionnaireSlice = {
  getQuestionnaires,
  getQuestionnaireById,
  updateQuestionnaire,
  updateQuestionnaireResponse,
  addQuestionnaireResponse,
  getQuestionnaireResponses,
  deleteQuestionnaireResponse,
  deleteQuestionnaire,
  setSelectedRow,
  setIntialProps,
  clearStatus,
  reducer,
};

export default questionnaireSlice;
