import PrintReferralForPatient from "./printReferralForPatient";
import ReferralEmail from "./referralEmail";
import ReferralDirectMessage from "./referralDirectMessage";

export default function Templates(props) {
  let name = props.name;
  return (
    <>
      {name === "PrintReferralForPatient" ? (
        <PrintReferralForPatient {...props} />
      ) : name === "ReferralEmail" ? (
        <ReferralEmail {...props} />
      ) : name === "ReferralDirectMessage" ? (
        <ReferralDirectMessage {...props} />
      ) : (
        ""
      )}
    </>
  );
}
