import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";
const initialState = {
  taxonomyTerms: null,
  selectedTerms: [],
  selectedTermsCollection: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const getValueSet = createAsyncThunk(
  "taxonomy/valueset",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(
          `/taxonomy/valueset?text=${params.text}&offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}&name=${params.name}`
        )
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getTaxonomyTerms = createAsyncThunk(
  "taxonomy/getTerms",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(`/taxonomy/getTerms?text=${params.text}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getValueSetList = createAsyncThunk(
  "taxonomy/valueset/list",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(
          `/taxonomy/valueset/list?text=${params.text}&offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}`
        )
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const { actions, reducer } = createSlice({
  name: "taxonomy",
  initialState,
  reducers: {
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
    setSelectedTaxonomy: (state, action) => {
      state.selectedTerms = action?.payload;
    },
    setSelectedTaxonomyCol: (state, action) => {
      state.selectedTermsCollection = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //get taxonomy terms
      .addCase(getTaxonomyTerms.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getTaxonomyTerms.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          taxonomyTerms: action?.payload?.data,
        };
      })
      .addCase(getTaxonomyTerms.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { clearStatus, setSelectedTaxonomy, setSelectedTaxonomyCol } = actions;
const getSelectedTaxonomy = (state) => state.taxonomy.selectedTerms;
const getSelectedTaxonomyCol = (state) =>
  state.taxonomy.selectedTermsCollection;

const taxonomySlice = {
  clearStatus,
  getTaxonomyTerms,
  getValueSet,
  setSelectedTaxonomy,
  setSelectedTaxonomyCol,
  getValueSetList,
  getSelectedTaxonomy,
  getSelectedTaxonomyCol,
  reducer,
};

export default taxonomySlice;
