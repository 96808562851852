import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const getEligibilityQuestions = createAsyncThunk(
  "eligibility/id",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(`/eligibility/${params.id}?subject=${params.subject}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const addEligibilityResponse = createAsyncThunk(
  "eligibility/response",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/eligibility/response`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const { actions, reducer } = createSlice({
  name: "eligibility",
  initialState,
  reducers: {
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
});

const { clearStatus } = actions;

const eligibilitySlice = {
  getEligibilityQuestions,
  addEligibilityResponse,
  clearStatus,
  reducer,
};

export default eligibilitySlice;
