const images = {
  no_profile: require("./no_profile.png"),
  profile: require("./user.jpg").default,
  logo_header: require("./logo_header.svg").default,
  logo_icon: require("./logo_icon.svg").default,
  message: require("./message.svg").default,
  notification: require("./notification.svg").default,
  firstPage: require("./firstPage.svg").default,
  prevPage: require("./prevPage.svg").default,
  lastPage: require("./lastPage.svg").default,
  nextPage: require("./nextPage.svg").default,
  all: require("./all.png"),
  "basic-needs": require("./basic-needs.png"),
  "consumer-services": require("./consumer-services.png"),
  "criminal-justice-and-legal-services": require("./criminal-justice-and-legal-services.png"),
  education: require("./education.png"),
  "environment-and-public-health-safety": require("./environment-and-public-health-safety.png"),
  "health-care": require("./health-care.png"),
  "income-support-and-employment": require("./income-support-and-employment.png"),
  "individual-and-family-life": require("./individual-and-family-life.png"),
  "mental-health-and-substance-use-disorder-services": require("./mental-health-and-substance-use-disorder-services.png"),
  "organizational-community-international-services": require("./organizational-community-international-services.png"),
  "target-populations": require("./target-populations.png"),
  navigator: require("./navigator.svg").default,
  journey: require("./journey.svg").default,
  "journey--blue": require("./journey--blue.svg").default,
  agency: require("./agency.svg").default,
  care: require("./care.svg").default,
  "care--blue": require("./care--blue.svg").default,
  "logo-stacked": require("./logo-stacked.svg").default,
  showPwd: require("./showPWD.svg").default,
  hidePwd: require("./hidePWD.svg").default,
  nj211: require("./nj211.svg").default,
  backArr: require("./backArr.svg").default,
  back: require("./back.svg").default,
  inckLogo: require("./inck-logo.png"),
  inckLogoMobile: require("./inck-logo-mobile.png"),
};

export default images;
