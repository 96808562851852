import _ from "lodash";

const getPages = (totalItems, currentPage, pageSize) => {
  // default to first page
  currentPage = currentPage || 1;

  // default page size is 10
  pageSize = pageSize || 10;

  // calculate total pages
  var pages = Math.ceil(totalItems / pageSize);

  var startPage, endPage;
  if (pages <= 10) {
    // less than 10 total pages so show all
    startPage = 1;
    endPage = pages;
  } else {
    // more than 10 total pages so calculate start and end pages
    if (currentPage <= 3) {
      startPage = 1;
      endPage = 5;
    } else if (currentPage + 2 >= pages) {
      startPage = pages - 4;
      endPage = pages;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }
  }

  // calculate start and end item indexes
  var startIndex = (currentPage - 1) * pageSize;
  var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  var pageList = _.range(startPage, endPage + 1);

  // return object with all pager properties required by the view
  return {
    totalItems: totalItems,
    currentPage: currentPage,
    pageSize: pageSize,
    pages: pages,
    startPage: startPage,
    endPage: endPage,
    startIndex: startIndex,
    endIndex: endIndex,
    pageList: pageList,
  };
};

export default getPages;
