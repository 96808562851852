import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { makeAlphaNumId, asyncForEach } from "../../../Utils";
import Label from "../Label";
import { AsyncPaginate } from "react-select-async-paginate";
import { taxonomySlice } from "../../../Features";
import { appConstants, messageConstants } from "../../../Constants";
import { toast } from "react-toastify";
import {
  FontAwesomeIcon,
  faXmark,
  faFileAlt,
  faTrash,
} from "../../../Assets/icons";

export default function DocumentUpload({ files, onClose, upload }) {
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [selectedFiles, setSelectedFile] = useState(files);

  const handleChange = (event) => {
    asyncForEach(event?.target?.files, (value) => {
      let reader = new FileReader();
      let file = value;
      reader.onloadend = () => {
        setSelectedFile((preValue) => [
          {
            file,
            id: makeAlphaNumId(8),
            filename: file?.name,
            filetype: file?.type,
            fileimage: reader.result,
            datetime: file?.lastModifiedDate?.toLocaleString("en-IN"),
            filesize: filesizes(file?.size),
            data: reader.result,
            type: null,
          },
          ...preValue,
        ]);
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    });
  };

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const deleteFile = async (id) => {
    const result = selectedFiles.filter((file) => file.id !== id);
    setSelectedFile(result);
  };

  const fileUploadSubmit = async (event) => {
    event.preventDefault();
    if (selectedFiles.length) {
      let invalid = false;
      let errorObj = { ...error };
      const promise = new Promise(async (resolve, reject) => {
        asyncForEach(selectedFiles, (val, index) => {
          errorObj = { ...errorObj, ...validate(val, index) };
          setError(errorObj);
          invalid =
            invalid ||
            Object.values(_(validate(val, index)).pickBy(_.identity).value())
              .length
              ? true
              : false;
        });
        resolve();
      });
      return promise.then(() => {
        if (!invalid) upload(selectedFiles);
      });
    } else {
      toast.error(messageConstants.FILE_REQUIRED);
    }
  };

  const extendLoadOptions = useCallback(
    (searchQuery, loadedOptions, { name }) => {
      let options = [],
        rowCount = 0;
      return new Promise((resolve, reject) => {
        dispatch(
          taxonomySlice.getValueSet({
            text: searchQuery,
            name,
            offset: loadedOptions.length || appConstants.DEFAULT_SKIP,
            limit: appConstants.DEFAULT_LIMIT,
            sortBy: "createdAt",
            sortOrder: "desc",
          })
        )
          .unwrap()
          .then((response) => {
            options = response?.data?.codes;
            rowCount = response?.data?.rowCount;
            resolve({
              options,
              hasMore:
                loadedOptions.length + appConstants.DEFAULT_LIMIT < rowCount
                  ? true
                  : false,
              name,
            });
          })
          .catch((error) => {
            toast.error(error?.data?.message || error?.data);
            resolve({
              options,
              hasMore: false,
              name,
            });
          });
      });
    },
    [dispatch]
  );

  const validate = (values, index) => {
    const errorObj = {};
    !values.type
      ? (errorObj[`type${index}`] = messageConstants.FIELD_REQUIRED)
      : (errorObj[`type${index}`] = "");
    return errorObj;
  };

  return (
    <>
      <div
        id="updateProfileModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        className="modal modal--show"
      >
        <div
          className="modal__dialog modal__dialog--md modal__dialog--centered docload"
          role="document"
        >
          <div className="modal__content">
            <div className="modal__header">
              <div className="modal__header__title">
                <h5 className="title--h4">Attach Documents</h5>
              </div>
              <button
                onClick={onClose}
                type="button"
                className="btn btn--clear"
                data-dismiss="modal"
                aria-label="Close"
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
              <div className="document__upload__box">
                <input
                  tabIndex="-1"
                  type="file"
                  id="fileupload"
                  className="document__upload__input"
                  onChange={handleChange}
                  multiple
                />
                <span>
                  Drag 'n' drop files here, or{" "}
                  <span className="document__upload__box__link">
                    click to select files
                  </span>
                </span>
              </div>
            </div>
            <div className="modal__body">
              <div className="document__upload">
                <div className="document__upload__list">
                  {selectedFiles.map((data, index) => {
                    const { id, filename, fileimage, datetime, filesize } =
                      data;
                    return (
                      <div className="document__upload__list__box" key={index}>
                        {filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
                          <div className="file-image">
                            <img src={fileimage} alt="" />
                          </div>
                        ) : (
                          <div className="file-image">
                            <FontAwesomeIcon icon={faFileAlt} />
                          </div>
                        )}
                        <div className="file-detail">
                          <h6>{filename}</h6>
                          <div className="desc">
                            <span>Size : {filesize}</span>
                            <span>Modified Time : {datetime}</span>
                          </div>
                          <div className="file-actions">
                            <div>
                              <Label
                                tooltip={true}
                                content="Specifies the particular kind of document referenced (e.g. History and Physical, Discharge Summary, Progress Note). This usually equates to the purpose of making the document referenced."
                                label="Type"
                                isRequired={true}
                                id={`docType${index}`}
                              />
                              <AsyncPaginate
                                classNamePrefix={`${
                                  error[`type${index}`]
                                    ? "select__error"
                                    : "select"
                                }`}
                                hideSelectedOptions={false}
                                defaultOptions
                                loadOptions={extendLoadOptions}
                                onChange={(option) => {
                                  const fieldsArr = [...selectedFiles];
                                  fieldsArr[index] = {
                                    ...fieldsArr[index],
                                    type: option
                                      ? {
                                          coding: [option],
                                          text: option?.display,
                                        }
                                      : null,
                                  };
                                  setSelectedFile(fieldsArr);
                                  setError({
                                    ...error,
                                    ...validate(fieldsArr[index], index),
                                  });
                                }}
                                value={
                                  data?.type?.coding?.length
                                    ? data.type.coding
                                    : null
                                }
                                placeholder="Select Type..."
                                getOptionValue={(option) => option.code}
                                getOptionLabel={(option) => option.display}
                                additional={{
                                  name: "DocumentTypes",
                                }}
                                name={`type${index}`}
                                id={`type${index}`}
                                aria-label={`type${index}`}
                              />
                              {error?.[`type${index}`] ? (
                                <p
                                  className={
                                    error[`type${index}`] ? "error" : ""
                                  }
                                >
                                  {error[`type${index}`]}
                                </p>
                              ) : null}
                            </div>
                            <button
                              className="btn btn--danger btn--sm"
                              aria-label={`delete${index}`}
                              onClick={() => deleteFile(id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="modal__footer justify--center">
              <button
                type="button"
                onClick={fileUploadSubmit}
                className="btn btn--primary btn--full"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
