export default function Loading({ text, background }) {
  return (
    <>
      {background ? (
        <div className="loader loader__signin" aria-hidden="true">
          <div className="loader__wraper">
            {text}
            <span className="loader__wraper__dot"></span>
            <span className="loader__wraper__dot"></span>
            <span className="loader__wraper__dot"></span>
          </div>
        </div>
      ) : (
        <div className="loader" aria-hidden="true">
          <div className="loader__wraper">
            {text}
            <span className="loader__wraper__dot"></span>
            <span className="loader__wraper__dot"></span>
            <span className="loader__wraper__dot"></span>
          </div>
        </div>
      )}
    </>
  );
}
