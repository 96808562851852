import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";

const initialState = {
  pageCount: 0,
  rowCount: 0,
  list: [],
  details: {},
  code: "",
  status: "",
  text: "",
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const getFormFields = createAsyncThunk(
  "form/fields",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(
          `/form/fields/${params.id}?offset=${params.offset}&limit=${params.limit}`
        )
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const addFormResponse = createAsyncThunk(
  "form/response",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/form/response?questionnaire=${params.questionnaire}`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateFormFields = createAsyncThunk(
  "form/fields/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/form/fields/${params.id}`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getFormById = createAsyncThunk("form/id", async (id, thunkApi) => {
  try {
    return await axiosInstance
      .get(`/form/${id}`)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const getFormLayoutById = createAsyncThunk(
  "form/layout/id",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(`/form/layout/${params.id}?subject=${params.subject}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getPdfForm = createAsyncThunk("form/pdf/id", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .get(`/form/pdf/fill/${params.id}?subject=${params.subject}`)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const getForms = createAsyncThunk("form", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .get(
        `/form?offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}&code=${params.code}&status=${params.status}&text=${params.text}`
      )
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const addForm = createAsyncThunk("form/add", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .post(`/form`, params)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const updateForm = createAsyncThunk("form/update", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .put(`/form/${params.id}`, params)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const deleteForm = createAsyncThunk("form/delete", async (id, thunkApi) => {
  try {
    return await axiosInstance
      .delete(`/form/${id}`)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const signForm = createAsyncThunk("form/sign", async (id, thunkApi) => {
  try {
    return await axiosInstance
      .get(`/form/sign/${id}`)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const { actions, reducer } = createSlice({
  name: "forms",
  initialState,
  reducers: {
    setIntialProps: (state, action) => {
      return { ...state, ...action?.payload };
    },
    setSelectedRow: (state, action) => {
      state.selectedRow = action?.payload;
    },
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // get form fields
      .addCase(getFormFields.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getFormFields.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          details: action?.payload?.data?.form,
          list: action?.payload?.data?.fields,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getFormFields.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      })
      // get forms
      .addCase(getForms.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getForms.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data?.forms,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getForms.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { setIntialProps, setSelectedRow, clearStatus } = actions;

const formSlice = {
  getFormFields,
  updateFormFields,
  getFormLayoutById,
  getFormById,
  getPdfForm,
  addFormResponse,
  getForms,
  addForm,
  updateForm,
  deleteForm,
  signForm,
  setIntialProps,
  setSelectedRow,
  clearStatus,
  reducer,
};

export default formSlice;
