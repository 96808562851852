import _ from "lodash";
import moment from "moment";

const validateEmail = (email) => {
  var regxExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regxExp.test(email);
};

const validatePhone = (phone) => {
  phone = phone.replace(/ /g, "");
  var regxExp = /^[- +()0-9]{6,}$/;
  return regxExp.test(phone);
};

const validateLink = (link) => {
  link = link.replace(/ /g, "");
  var regxExp =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/;
  return regxExp.test(link);
};

const validatePassword = (password) => {
  var regxExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&'()*+,^\\-\\./:;<=>?[\]_`{~}|])[A-Za-z\d!@#$%&'()*+,^\\-\\./:;<=>?[\]_`{~}|]{8,}/;
  return regxExp.test(password);
};

const isSame = (str1, str2) => {
  return str1 === str2;
};

const _serverError = (res) => {
  const ex = (res && res.body) || (res && JSON.stringify(res.text)) || {};
  if (ex.validation) {
    const keys = ex.validation.keys; // failed validations keys
    const errMap = {};

    // error message is contained in "[]" but in order the keys are, so split it up
    ex.message.match(/[^[\]]+(?=])/g).forEach(function (msg, idx) {
      errMap[keys[idx]] = _.isArray(msg) ? msg.join(". ") : msg;
    });

    ex.message = "Validation Error";
    ex.validation = errMap;
  }
  return ex;
};

const objectToFormData = function (obj, form, namespace) {
  let fd = form || new FormData();
  let formKey;

  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof Blob) &&
        !(obj[property] instanceof File) &&
        !(obj[property] instanceof Array)
      ) {
        objectToFormData(obj[property], fd, property);
      } else if (obj[property] instanceof Array) {
        // if it's a array
        for (var i = 0; i < obj[property].length; i++) {
          // formData.append('array_php_side[]', obj[property][i]);
          fd.append(formKey + "[]", obj[property][i]);
        }
      } else {
        // if it's a string or a File object or blob
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
const debounce = function (func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

let sortByKeys = (unordered) => {
  const ordered = {};
  Object.keys(unordered)
    .sort()
    .forEach(function (key) {
      ordered[key] = unordered[key];
    });
  return ordered;
};

const arrayContainsArray = (superset, subset) => {
  if (0 === subset.length) {
    return false;
  }
  return subset.every(function (value) {
    return superset.indexOf(value) >= 0;
  });
};

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

/**
 * Get the URL parameters
 * source: https://css-tricks.com/snippets/javascript/get-url-variables/
 * @param  {String} url The URL
 * @return {Object}     The URL parameters
 */
const getParams = (url, decodeURI = true) => {
  var params = {};
  var parser = document.createElement("a");
  parser.href = url;
  var query = parser.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split(/=(.+)/);
    params[pair[0]] = decodeURI ? decodeURIComponent(pair[1]) : pair[1];
  }
  return params;
};

const formatDateWithoutTime = (date) => {
  if (!date) {
    return date;
  }
  return moment(date).format("LL");
};

const validPinCode = (pinCode) => {
  let regxExp = /^\d{6}$/;
  return regxExp.test(pinCode);
};

const validPostiveHours = (hours) => {
  let regxExp = /^\d$/;
  return regxExp.test(hours);
};

const validPostiveInteger = (no) => {
  let regxExp = /^\d{1,5}$/;
  return regxExp.test(no);
};

const validPostiveMinutes = (minutes) => {
  let regxExp = /^\d{1,2}$/;
  return regxExp.test(minutes);
};

const validlatitude = (latitude) => {
  let regxExp = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}$/gm;
  return regxExp.test(latitude);
};

const validlongitude = (longitude) => {
  let regxExp = /^-?([1]?[1-7][1-9]|[1]?[1-8][0]|[1-9]?[0-9])\.{1}\d{1,6}$/gm;
  return regxExp.test(longitude);
};

const mobileCheck = function () {
  let check = false;
  if (/Mobi/.test(navigator.userAgent)) {
    check = true;
  }
  return check;
};

const converToLocalString = (number) => {
  return Number(number).toLocaleString("en");
};

const getInitials = (nameString) => {
  const fullName = nameString.split(" ");
  const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  return initials.toUpperCase();
};

const validateYear = (year) => {
  let regxExp = /^[0-9]{4}$/;
  return regxExp.test(year);
};

const parseValue = (value, type, format) => {
  if (typeof value !== format) {
    if (value instanceof Array) {
      value = value[0];
      return parseValue(value, type, format);
    } else if (value instanceof Object) {
      value = Object.values(value)?.[0];
      return parseValue(value, type, format);
    }
  } else {
    if (type === "radio") {
      return value?.trim()?.length ? "yes" : "no";
    } else if (type === "checkbox") {
      return value?.trim()?.length ? true : false;
    } else {
      return value?.trim();
    }
  }
};

const parseEligibilityValue = (value, type, format) => {
  if (typeof value !== format) {
    if (value instanceof Array) {
      value = value[0];
      return parseValue(value, type, format);
    } else if (value instanceof Object) {
      value = Object.values(value)?.[0];
      return parseValue(value, type, format);
    }
  } else {
    if (type === "radio") {
      return value?.trim()?.length ? "yes" : "no";
    } else if (type === "checkbox") {
      return value?.trim()?.length ? true : false;
    } else if (type === "number") {
      return value?.trim()?.length ? Number(value) : "";
    } else {
      return value?.trim();
    }
  }
};

const makeAlphaNumId = (length) => {
  let id = "";
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charsLength = chars.length;
  let min = 0,
    max = charsLength - 1;
  for (let i = 0; i < length; ++i) {
    id += chars[Math.floor(Math.random() * (max - min + 1)) + min];
  }
  return id;
};

export {
  _serverError as ServerError,
  validateEmail,
  objectToFormData,
  debounce,
  validatePassword,
  sortByKeys,
  isSame,
  arrayContainsArray,
  asyncForEach,
  getParams,
  formatDateWithoutTime,
  validPinCode,
  validlatitude,
  validateLink,
  validlongitude,
  validPostiveHours,
  validPostiveMinutes,
  validatePhone,
  validPostiveInteger,
  converToLocalString,
  mobileCheck,
  getInitials,
  validateYear,
  parseValue,
  parseEligibilityValue,
  makeAlphaNumId,
};
