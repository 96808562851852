import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { useDispatch } from "react-redux";
import Label from "../Label";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { FontAwesomeIcon, faTrash, faPlus } from "../../../Assets/icons";
import { appConstants, messageConstants } from "../../../Constants";
import { asyncForEach } from "../../../Utils";
import { taxonomySlice, organizationSlice } from "../../../Features";
import _ from "lodash";

const Participant = forwardRef(({ rows, references, mode }, ref) => {
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [participants, setParticipant] = useState([{}]);
  const [memberList, setMemberList] = useState([]);
  const participantMemberOpts = appConstants.participantMemberOpts;
  useEffect(() => {
    const fieldsArr = [];
    setParticipant(
      rows?.length
        ? rows.map((row, index) => {
            fieldsArr[index] = row?.member?.collectionName
              ? references?.[`${row.member.collectionName}`]
              : references?.["practitioners"];
            setMemberList(fieldsArr);
            return {
              ...row,
              memberResource: row?.member?.collectionName
                ? participantMemberOpts.find(
                    (e) => e?.code === row.member.collectionName
                  )
                : participantMemberOpts.find(
                    (e) => e?.code === "practitioners"
                  ),
            };
          })
        : [{}]
    );
  }, [references, participantMemberOpts, rows]);

  const removefields = (index) => {
    setParticipant((participants) =>
      participants.filter((v, i) => i !== index)
    );
  };

  const addfields = (index) => {
    if (
      participants[index]?.member &&
      !Object.values(
        _(validate(participants[index], index)).pickBy(_.identity).value()
      ).length
    )
      setParticipant([...participants, {}]);
    else setError(validate(participants[index], index));
  };

  useImperativeHandle(ref, () => ({
    handleSubmit(event) {
      event.preventDefault();
      let invalid = false;
      asyncForEach(participants, (val, index) => {
        setError(validate(val, index));
        invalid = Object.values(
          _(validate(val, index)).pickBy(_.identity).value()
        ).length
          ? true
          : false;
      });
      if (!invalid) return participants.filter((t) => t?.member);
    },
  }));

  const validate = (values, index) => {
    const errorObj = {};
    !values.memberResource
      ? (errorObj[`memberResource${index}`] = messageConstants.FIELD_REQUIRED)
      : (errorObj[`memberResource${index}`] = "");
    !values.member
      ? (errorObj[`member${index}`] = messageConstants.FIELD_REQUIRED)
      : (errorObj[`member${index}`] = "");
    return errorObj;
  };

  const extendLoadOptions = useCallback(
    (searchQuery, loadedOptions, { name }) => {
      let options = [],
        rowCount = 0;
      return new Promise((resolve, reject) => {
        if (name === "managingOrganization")
          dispatch(
            organizationSlice.getOrganizations({
              text: searchQuery,
              offset: loadedOptions.length || appConstants.DEFAULT_SKIP,
              limit: appConstants.DEFAULT_LIMIT,
              sortBy: "createdAt",
              sortOrder: "desc",
            })
          )
            .unwrap()
            .then((response) => {
              options = response.data.organizations;
              rowCount = response.data.rowCount;
              resolve({
                options,
                hasMore:
                  loadedOptions.length + appConstants.DEFAULT_LIMIT < rowCount
                    ? true
                    : false,
              });
            })
            .catch((error) => {
              toast.error(error?.data || error?.data?.message);
              resolve({
                options,
                hasMore: false,
              });
            });
        else
          dispatch(
            taxonomySlice.getValueSet({
              text: searchQuery,
              name,
              offset: loadedOptions.length || appConstants.DEFAULT_SKIP,
              limit: appConstants.DEFAULT_LIMIT,
              sortBy: "createdAt",
              sortOrder: "desc",
            })
          )
            .unwrap()
            .then((response) => {
              options = response?.data?.codes;
              rowCount = response?.data?.rowCount;
              resolve({
                options,
                hasMore:
                  loadedOptions.length + appConstants.DEFAULT_LIMIT < rowCount
                    ? true
                    : false,
                name,
              });
            })
            .catch((error) => {
              toast.error(error?.data?.message || error?.data);
              resolve({
                options,
                hasMore: false,
                name,
              });
            });
      });
    },
    [dispatch]
  );

  const customOption = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  return (
    <div className="col_xs_12">
      <div className="repeater">
        {participants?.length
          ? participants.map((value, index) => {
              return (
                <div key={index} className="repeater__row">
                  <div className="repeater__row__fields">
                    <div className="row">
                      <div
                        className={
                          mode === "edit" ? "col_xl_12" : "col_xl_3 col_md_6"
                        }
                      >
                        <Label
                          label="Role"
                          tooltip={true}
                          content="Indicates specific responsibility of an individual within the care team, such as 'Primary care physician', 'Trained social worker counselor', 'Caregiver', etc."
                          isRequired={false}
                          aria-label="Role"
                        />
                        <AsyncPaginate
                          components={{ Option: customOption }}
                          classNamePrefix={`${
                            error[`role${index}`] ? "select__error" : "select"
                          }`}
                          isMulti
                          aria-label="Role"
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          defaultOptions
                          loadOptions={extendLoadOptions}
                          onChange={(option) => {
                            const fieldsArr = [...participants];
                            fieldsArr[index] = {
                              ...fieldsArr[index],
                              role: option.map((opt) => {
                                return {
                                  coding: [
                                    _.pick(opt, ["code", "display", "system"]),
                                  ],
                                  text: opt.display,
                                };
                              }),
                            };
                            setParticipant(fieldsArr);
                          }}
                          value={
                            value?.role?.length &&
                            value.role.map((opt) => opt?.coding?.[0])
                          }
                          placeholder="Select..."
                          getOptionValue={(option) => option.code}
                          getOptionLabel={(option) => option.display}
                          additional={{
                            name: "ParticipantRoles",
                          }}
                          name={`role${index}`}
                          id={`role${index}`}
                        />
                        {error?.[`role${index}`] ? (
                          <p className={error[`role${index}`] ? "error" : ""}>
                            {error[`role${index}`]}
                          </p>
                        ) : null}
                      </div>
                      <div
                        className={
                          mode === "edit" ? "col_xl_12" : "col_xl_3 col_md_6"
                        }
                      >
                        <Label
                          label="Member Resource"
                          tooltip={true}
                          content="The specific person or organization who is participating/expected to participate in the care team."
                          isRequired={true}
                          aria-label="Member Resource"
                        />
                        <Select
                          isClearable={true}
                          value={value?.memberResource}
                          classNamePrefix={`${
                            error[`memberResource${index}`]
                              ? "select__error"
                              : "select"
                          }`}
                          options={participantMemberOpts}
                          name={`memberResource${index}`}
                          id={`memberResource${index}`}
                          aria-label="Member Resource"
                          onChange={(option) => {
                            const fieldsArr = [...participants];
                            fieldsArr[index] = {
                              ...fieldsArr[index],
                              memberResource: option,
                              member:
                                value?.member?.collectionName === option?.code
                                  ? value.member
                                  : null,
                            };
                            setParticipant(fieldsArr);
                            let membersArr = [...memberList];
                            membersArr[index] =
                              (option?.code &&
                                references?.[`${option.code}`]) ||
                              [];
                            setMemberList(membersArr);
                            setError({
                              memberResource: validate({
                                ...fieldsArr[index],
                                memberResource: option,
                              }).memberResource,
                            });
                          }}
                          getOptionValue={(option) => option.code}
                          getOptionLabel={(option) => option.display}
                        />
                        {error?.[`memberResource${index}`] ? (
                          <p
                            className={
                              error[`memberResource${index}`] ? "error" : ""
                            }
                          >
                            {error[`memberResource${index}`]}
                          </p>
                        ) : null}
                      </div>
                      <div
                        className={
                          mode === "edit" ? "col_xl_12" : "col_xl_3 col_md_6"
                        }
                      >
                        <Label
                          label="Member Reference"
                          tooltip={true}
                          content="Reference of specific person or organization who is participating/expected to participate in the care team."
                          isRequired={true}
                          id={`member${index}`}
                          aria-label="Member Reference"
                        />
                        <Select
                          isClearable={true}
                          value={
                            value?.member?.collectionName ===
                            value?.memberResource?.code
                              ? value.member
                              : ""
                          }
                          classNamePrefix={`${
                            error[`member${index}`] ? "select__error" : "select"
                          }`}
                          options={memberList?.[index] || []}
                          name={`member${index}`}
                          id={`member${index}`}
                          aria-label="Member Reference"
                          onChange={(option) => {
                            const fieldsArr = [...participants];
                            fieldsArr[index] = {
                              ...fieldsArr[index],
                              member: option,
                            };
                            setParticipant(fieldsArr);
                            setError({
                              member: validate({
                                ...fieldsArr[index],
                                member: option,
                              }).member,
                            });
                          }}
                          getOptionValue={(option) => option._id}
                          getOptionLabel={(option) =>
                            option?.name?.[0]?.text?.trim() ||
                            (option?.code?.[0]?.coding?.length &&
                              _.map(option?.code, (c) =>
                                _.map(c?.coding, "display").join(", ")
                              ).join(", ")) ||
                            option?.name?.trim()
                          }
                        />
                        {error?.[`member${index}`] ? (
                          <p className={error[`member${index}`] ? "error" : ""}>
                            {error[`member${index}`]}
                          </p>
                        ) : null}
                      </div>
                      <div
                        className={
                          mode === "edit" ? "col_xl_12" : "col_xl_3 col_md_6"
                        }
                      >
                        <Label
                          tooltip={true}
                          content="The organization of the practitioner."
                          label="On Behalf Of"
                          isRequired={false}
                          aria-label="On Behalf Of"
                        />
                        <AsyncPaginate
                          value={value?.onBehalfOf}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          defaultOptions
                          isClearable
                          loadOptions={extendLoadOptions}
                          onChange={(option) => {
                            const fieldsArr = [...participants];
                            fieldsArr[index] = {
                              ...fieldsArr[index],
                              onBehalfOf: option,
                            };
                            setParticipant(fieldsArr);
                          }}
                          placeholder="Select..."
                          getOptionValue={(option) => option._id}
                          getOptionLabel={(option) => option.name}
                          name={`onBehalfOf${index}`}
                          id={`onBehalfOf${index}`}
                          aria-label="On Behalf Of"
                          additional={{ name: "managingOrganization" }}
                          classNamePrefix={`${
                            error[`onBehalfOf${index}`]
                              ? "select__error"
                              : "select"
                          }`}
                        />
                        {error?.[`onBehalfOf${index}`] ? (
                          <p
                            className={
                              error[`onBehalfOf${index}`] ? "error" : ""
                            }
                          >
                            {error[`onBehalfOf${index}`]}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="repeater__row__button">
                    {index === participants.length - 1 ? (
                      <button
                        onClick={() => addfields(index)}
                        className="btn btn--success"
                        aria-label="add"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    ) : null}

                    {index !== participants.length - 1 ? (
                      <button
                        onClick={() => removefields(index)}
                        className="btn btn--danger"
                        aria-label="delete"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    ) : null}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
});

export default Participant;
