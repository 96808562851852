import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { messageConstants } from "../../Constants";
import { validateEmail, validatePassword } from "../../Utils";
import { authSlice } from "../../Features";
import { Loader, Label } from "../Common";
import images from "../../Assets/images";
import config from "../../Config";
import _ from "lodash";

export default function Login({ title }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordHide, setPasswordHide] = useState(true);
  const [error, setError] = useState({});
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const {
    isFetching,
    isAuthenticated,
    isError,
    errorMessage,
    successMessage,
    loggedinUser,
    accessToken,
  } = useSelector((state) => state.auth);
  useEffect(() => {
    document.title = title;
  }, [title, dispatch]);
  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
    } else if (isAuthenticated && !loggedinUser) {
      dispatch(authSlice.getUserInfo());
    } else if (loggedinUser) {
      toast.success(successMessage);
      !loggedinUser?.practitionerId && !loggedinUser?.customerId
        ? navigate("/practitioners/list")
        : navigate("/navigator360/referrals/list");
    }
    return () => {
      dispatch(authSlice.clearStatus());
    };
  }, [
    isError,
    isAuthenticated,
    isFetching,
    loggedinUser,
    accessToken,
    errorMessage,
    successMessage,
    dispatch,
    navigate,
  ]);

  const handleOnBlur = (event) => {
    const errorObj = {};
    if (event?.target?.value?.length && !validateEmail(event.target.value)) {
      errorObj.email = messageConstants.INVALID_EMAIL;
    }
    setError(errorObj);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(validate(credentials));
    if (
      !Object.values(_(validate(credentials)).pickBy(_.identity).value()).length
    ) {
      dispatch(
        authSlice.login({
          username: credentials.email.trim(),
          password: credentials.password.trim(),
          client_id: config.CLIENT_ID,
          client_secret: config.CLIENT_SECRET,
          grant_type: "password",
        })
      );
    }
  };

  const validate = (values) => {
    const errorObj = {};
    if (!values.email.trim().length) {
      errorObj.email = messageConstants.EMAIL_REQUIRED;
    } else if (!validateEmail(values.email)) {
      errorObj.email = messageConstants.INVALID_EMAIL;
    }
    if (!values.password.trim().length) {
      errorObj.password = messageConstants.PASSWORD_REQUIRED;
    } else if (!validatePassword(values.password)) {
      errorObj.password = messageConstants.INVALID_PASSWORD;
    }
    return errorObj;
  };

  return (
    <div className="signin">
      <div className="signin__wraper">
        <div className="signin__inner">
          <div className="signin__logo">
            {window.location.host.includes("mynjinck") ||
            window.location.host.includes("demo") ? (
              <img src={images.inckLogo} alt="NJ inCK logo" />
            ) : (
              <img src={images["logo-stacked"]} alt="open city labs logo" />
            )}
          </div>
          <div className="signin__slogan">
            <p>Personalized Healthcare and Human Services to Every Family.</p>
          </div>
          <div className="signin__form">
            <div className="formgroup">
              <Label tooltip={false} label="Email address" id="email" />
              <input
                name="email"
                value={credentials.email}
                onBlur={handleOnBlur}
                onChange={handleChange}
                className={error.email ? "control error" : "control"}
                autoComplete="off"
                type="email"
                id="email"
                placeholder="Email Address"
              />
              {error?.email ? (
                <p className={error.email ? "error" : ""}>{error.email}</p>
              ) : null}
            </div>
            <div className="formgroup">
              <Label tooltip={false} label="Password" id="password" />
              <div className="relative">
                <input
                  name="password"
                  value={credentials.password}
                  onChange={handleChange}
                  className={error.password ? "control error" : "control"}
                  autoComplete="off"
                  type={passwordHide ? "password" : "text"}
                  id="password"
                  placeholder="Enter your password"
                />
                {passwordHide ? (
                  <button className="pwd">
                    <img
                      onClick={() => {
                        setPasswordHide(false);
                      }}
                      src={images.showPwd}
                      alt="show password"
                    />
                  </button>
                ) : (
                  <button className="pwd">
                    <img
                      onClick={() => {
                        setPasswordHide(true);
                      }}
                      src={images.hidePwd}
                      alt="hide password"
                    />
                  </button>
                )}
              </div>
              {error?.password ? (
                <p className={error.password ? "error" : ""}>
                  {error.password}
                </p>
              ) : null}
            </div>
            <div className="formgroup relative">
              {isFetching ? <Loader background={true} /> : ""}
              <button
                onClick={handleSubmit}
                className="btn btn--primary btn--full uppercase"
              >
                Sign In
              </button>
            </div>
          </div>
          <div className="signin__forgotpwd">
            <Link to="/forgotPassword">
              <span>Forgot Password?</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
