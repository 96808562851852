import { FontAwesomeIcon, faXmark } from "../../../Assets/icons";

export default function Delete({ title, content, onClose, onSubmit }) {
  return (
    <div
      id="updateProfileModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      className="modal modal--show"
    >
      <div className="modal__dialog modal__dialog--xs modal__dialog--centered" role="document">
        <div className="modal__content">
          <div className="modal__header">
            <div className="modal__header__title">
              <h5 className="title--h4">{title}</h5>
            </div>
            <button
              onClick={onClose}
              type="button"
              className="btn btn--clear"
              data-dismiss="modal"
              aria-label="Close"
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <div className="modal__body">
            {content}
          </div>
          <div className="modal__footer">
            <button
              onClick={onSubmit}
              type="button"
              className="btn btn--danger"
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn--secondary"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
