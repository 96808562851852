import { useState } from "react";
import { messageConstants } from "../../../Constants";
import { FontAwesomeIcon, faXmark } from "../../../Assets/icons";
import { getInitials } from "../../../Utils";
import _ from "lodash";

export default function SelectContact({ mode, contacts, onClose, onChange }) {
  const [list, setList] = useState(contacts || []);

  return (
    <div className="modal modal--show">
      <div className="modal__dialog modal__dialog--contacts modal__dialog--sidebar">
        <div className="modal__content">
          <div className="modal__header">
            {mode === "view" ? (
              <div className="modal__header__title">
                <h5 className="title--h4">Contact List</h5>
              </div>
            ) : (
              <input
                id="search"
                className="control"
                placeholder="Search Contact"
                type="search"
                aria-label="search"
                onChange={(event) => {
                  const { value } = event.target;
                  setList(
                    contacts.filter(
                      (item) =>
                        item?.name?.text &&
                        new RegExp(`${value}`, "i").test(item.name.text)
                    )
                  );
                }}
              />
            )}
            <button
              onClick={onClose}
              type="button"
              className="btn btn--clear"
              data-dismiss="modal"
              aria-label="Close"
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <div className="modal__body">
            <ul className="contactList">
              {list?.length ? (
                list.map((value, index) => {
                  return (
                    <li key={index} className="contactList__item">
                      {mode === "view" ? (
                        <Contact {...value} />
                      ) : (
                        <div className="checkbox">
                          <input
                            onChange={() => {
                              let arr = [...list];
                              arr[index] = {
                                ...arr[index],
                                isSelected: !arr[index].isSelected,
                              };
                              setList(arr);
                              onChange(arr);
                            }}
                            checked={value.isSelected}
                            type="checkbox"
                            id={getInitials(value?.name?.text?.trim())}
                            className="checkbox__input"
                          />
                          <label
                            className="checkbox__label"
                            htmlFor={getInitials(value?.name?.text?.trim())}
                          >
                            <Contact {...value} />
                          </label>
                        </div>
                      )}
                    </li>
                  );
                })
              ) : (
                <div className="no_data">{messageConstants.NO_DATA_TEXT}</div>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
const Contact = (value) => {
  return (
    <div className="contact">
      <div className="contact__init">
        {getInitials(value?.name?.text?.trim())}
      </div>
      <div className="contact__details">
        <h6>{value.name ? value.name.text.trim() : ""}</h6>
        <small>
          {value?.roles?.code?.[0]?.coding?.length
            ? _.map(value?.roles?.code, (c) =>
                _.map(c?.coding, "display").join(", ")
              ).join(", ")
            : ""}
        </small>
        <ul className="contact__details__info">
          <li className="contact__details__info__item">
            <a
              href={`mailto: ${value?.emails?.[0]?.value || ""}`}
              className="contact__details__info__link"
              aria-label="contact email"
            >
              {value?.emails?.[0]?.value || ""}
            </a>
          </li>
          <li className="contact__details__info__item">
            <a
              href={`tel: ${value?.phoneNumbers?.[0]?.value || ""}`}
              className="contact__details__info__link"
              aria-label="contact phone"
            >
              {value?.phoneNumbers?.[0]?.value || ""}{" "}
            </a>
          </li>
        </ul>
        <div className="contact__details__location">
          {value?.organization?.name}
        </div>
      </div>
    </div>
  );
};
