import { useState } from "react";
import { useDispatch } from "react-redux";
import { Label, Loader } from "../Common";
import { validateEmail } from "../../Utils";
import { messageConstants } from "../../Constants";
import { authSlice } from "../../Features";
import { toast } from "react-toastify";
import moment from "moment";
import _ from "lodash";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
  });

  const handleOnBlur = (event) => {
    const errorObj = {};
    if (event?.target?.value?.length && !validateEmail(event.target.value)) {
      errorObj.email = messageConstants.INVALID_EMAIL;
    }
    setError(errorObj);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(validate(credentials));
    if (
      !Object.values(_(validate(credentials)).pickBy(_.identity).value()).length
    ) {
      setLoading(true);
      dispatch(
        authSlice.forgotPassword({
          ...credentials,
          zone: moment.parseZone(moment().local().toString()).utcOffset(),
        })
      )
        .unwrap()
        .then((response) => {
          setLoading(false);
          setCredentials({ email: "" });
          toast.success(response.message);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.data?.message || error?.data);
        });
    }
  };

  const validate = (values) => {
    const errorObj = {};
    if (!values.email.trim().length) {
      errorObj.email = messageConstants.EMAIL_REQUIRED;
    } else if (!validateEmail(values.email)) {
      errorObj.email = messageConstants.INVALID_EMAIL;
    }
    return errorObj;
  };

  return (
    <div className="signin forgotPwd">
      <div className="signin__wraper">
        <div className="signin__inner">
          <div className="signin__slogan">
            <h1 className="signin__slogan__title mb_20">Forgot Password?</h1>
            <p>
              Please enter your registered email address below and you'll
              receive a password reset link on your email.
            </p>
          </div>
          <div className="signin__form">
            <div className="formgroup">
              <Label tooltip={false} label="Email address"
                id="email" />
              <input
                name="email"
                value={credentials.email}
                onBlur={handleOnBlur}
                onChange={(event) => {
                  const { value } = event.target;
                  setCredentials({ ...credentials, email: value });
                }}
                className={error.email ? "control error" : "control"}
                autoComplete="off"
                type="email"
                id="email"
                placeholder="Email Address"
              />
              {error?.email ? (
                <p className={error.email ? "error" : ""}>{error.email}</p>
              ) : null}
            </div>
            <div className="formgroup relative">
              {loading ? <Loader background={true} /> : ""}
              <button
                className="btn btn--primary btn--full"
                onClick={handleSubmit}
              >
                Retrieve Password
              </button>
            </div>
          </div>
          <div className="signin__login">
            <a href="/login">Back to login</a>
          </div>
        </div>
      </div>
    </div>
  );
}
