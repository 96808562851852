import {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch } from "react-redux";
import Label from "../Label";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { FontAwesomeIcon, faTrash, faPlus } from "../../../Assets/icons";
import { appConstants } from "../../../Constants";
import { taxonomySlice } from "../../../Features";
import { toast } from "react-toastify";

const Identifier = forwardRef(({ rows }, ref) => {
  const dispatch = useDispatch();
  const error = {};
  const identifierUseOpts = appConstants.identifierUseOpts;
  const [identifiers, setIdentifier] = useState([{}]);
  useEffect(() => {
    setIdentifier(rows?.length ? rows : [{}]);
  }, [rows]);

  const extendLoadOptions = useCallback(
    (searchQuery, loadedOptions, { name }) => {
      let options = [],
        rowCount = 0;
      return new Promise((resolve, reject) => {
        dispatch(
          taxonomySlice.getValueSet({
            text: searchQuery,
            name,
            offset: loadedOptions.length || appConstants.DEFAULT_SKIP,
            limit: appConstants.DEFAULT_LIMIT,
            sortBy: "createdAt",
            sortOrder: "desc",
          })
        )
          .unwrap()
          .then((response) => {
            options = response?.data?.codes;
            rowCount = response?.data?.rowCount;
            resolve({
              options,
              hasMore:
                loadedOptions.length + appConstants.DEFAULT_LIMIT < rowCount
                  ? true
                  : false,
              name,
            });
          })
          .catch((error) => {
            toast.error(error?.data?.message || error?.data);
            resolve({
              options,
              hasMore: false,
              name,
            });
          });
      });
    },
    [dispatch]
  );

  const removefields = (index) => {
    setIdentifier((identifiers) => identifiers.filter((v, i) => i !== index));
  };

  const addfields = (index) => {
    if (identifiers[index].value) setIdentifier([...identifiers, {}]);
  };

  useImperativeHandle(ref, () => ({
    handleSubmit(event) {
      event.preventDefault();
      return identifiers.filter((t) => t.value);
    },
  }));

  return (
    <div className="col_xs_12">
      <div className="repeater">
        {identifiers?.length
          ? identifiers.map((value, index) => {
              return (
                <div key={index} className="repeater__row">
                  <div className="repeater__row__fields">
                    <div className="row">
                      <div className="col_xl_3 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="The purpose of this identifier."
                            label="Use"
                            isRequired={false}
                            aria-label={`use${index}`}
                          />
                          <Select
                            value={
                              value?.use &&
                              identifierUseOpts.find(
                                (g) => g?.code === value.use
                              )
                            }
                            name={`use${index}`}
                            id={`use${index}`}
                            aria-label={`use${index}`}
                            onChange={(option) => {
                              const fieldsArr = [...identifiers];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                use: option?.code,
                              };
                              setIdentifier(fieldsArr);
                            }}
                            isSearchable
                            isClearable
                            classNamePrefix={`${
                              error[`use${index}`] ? "select__error" : "select"
                            }`}
                            options={identifierUseOpts}
                            getOptionValue={(option) => option.code}
                            getOptionLabel={(option) => option.display}
                          />
                          {error?.[`use${index}`] ? (
                            <p className={error[`use${index}`] ? "error" : ""}>
                              {error[`use${index}`]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col_xl_3 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="HL7-defined code system of concepts specifying type of identifier."
                            label="Type"
                            isRequired={false}
                            aria-label={`type${index}`}
                          />
                          <AsyncPaginate
                            value={value?.type?.coding?.[0]}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            defaultOptions
                            isSearchable
                            isClearable
                            loadOptions={extendLoadOptions}
                            onChange={(option) => {
                              const fieldsArr = [...identifiers];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                type: {
                                  coding: option && [option],
                                  text: option?.display,
                                },
                              };
                              setIdentifier(fieldsArr);
                            }}
                            getOptionValue={(option) => option.code}
                            getOptionLabel={(option) => option.display}
                            additional={{ name: "IdentifierType" }}
                            name={`type${index}`}
                            id={`type${index}`}
                            aria-label={`type${index}`}
                            classNamePrefix={`${
                              error[`type${index}`] ? "select__error" : "select"
                            }`}
                          />
                          {error?.[`type${index}`] ? (
                            <p className={error[`type${index}`] ? "error" : ""}>
                              {error[`type${index}`]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col_xl_3 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="HL7-defined text of concepts specifying type of identifier."
                            label="Type Text"
                            isRequired={false}
                            id={`typeText${index}`}
                          />
                          <input
                            type="text"
                            name={`typeText${index}`}
                            id={`typeText${index}`}
                            onChange={(event) => {
                              const { value } = event.target;
                              const fieldsArr = [...identifiers];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                type: {
                                  text: value,
                                },
                              };
                              setIdentifier(fieldsArr);
                            }}
                            className={
                              error[`typeText${index}`]
                                ? "control error"
                                : "control"
                            }
                            autoComplete="off"
                            value={value?.type?.text || ""}
                          />
                          {error?.[`typeText${index}`] ? (
                            <p
                              className={
                                error[`typeText${index}`] ? "error" : ""
                              }
                            >
                              {error[`typeText${index}`]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col_xl_3 col_md_6">
                        <div className="formgroup">
                          <Label
                            tooltip={true}
                            content="The actual value of identifier, e.g. the Medicaid ID."
                            label="Value"
                            isRequired={false}
                            id={`value${index}`}
                          />
                          <input
                            type="text"
                            name={`value${index}`}
                            id={`value${index}`}
                            onChange={(event) => {
                              const { value } = event.target;
                              const fieldsArr = [...identifiers];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                value: value,
                              };
                              setIdentifier(fieldsArr);
                            }}
                            className={
                              error[`value${index}`]
                                ? "control error"
                                : "control"
                            }
                            autoComplete="off"
                            value={value?.value || ""}
                          />
                          {error?.[`value${index}`] ? (
                            <p
                              className={error[`value${index}`] ? "error" : ""}
                            >
                              {error[`value${index}`]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="repeater__row__button">
                    {index === identifiers.length - 1 ? (
                      <button
                        onClick={() => addfields(index)}
                        className="btn btn--success"
                        aria-label="add"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    ) : null}

                    {index !== identifiers.length - 1 ? (
                      <button
                        onClick={() => removefields(index)}
                        className="btn btn--danger"
                        aria-label="delete"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    ) : null}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
});

export default Identifier;
