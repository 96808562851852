import axios from "axios";
import { store } from "../Store";
import { authSlice } from "../Features";
import config from "../Config";
import { messageConstants } from "../Constants";

let isRefreshing = false;
let failedQueue = [];

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 60s. If the request takes longer than
// that then the request will be aborted.
const axiosInstance = axios.create({
  baseURL: `${config.BACKEND_URL}/api`,
  timeout: 300000,
  headers: { "Content-Type": "application/json" },
});

// Step-2: Create request, response & error handlers
const requestHandler = (request) => {
  const accessToken = store.getState()?.auth?.accessToken;
  // Token will be dynamic so we can use any app-specific way to always
  // fetch the new token before making the call
  if (accessToken) request.headers.Authorization = `Bearer ${accessToken}`;
  return request;
};

const responseHandler = (response) => {
  const { dispatch } = store;
  console.log("response instance::", response);
  if (response.status === 401 || response.status === 404) {
    dispatch(authSlice.logout());
    throw new axios.Cancel("Unauthorized");
  }
  return response.data;
};

const errorHandler = (error) => {
  const { dispatch } = store;
  console.log("error instance::", error);
  if (error.response?.status === 0) {
    return Promise.reject({ message: messageConstants.SERVER_ERROR });
  }
  const originalRequest = error.config;
  if (
    (error.response?.status === 401 || error.response?.status === 404) &&
    !originalRequest._retry
  ) {
    const refreshToken = store.getState()?.auth?.refreshToken;
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          return axiosInstance.request(originalRequest);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    }
    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.BACKEND_URL}/api/auth/token`, {
          client_id: config.CLIENT_ID,
          client_secret: config.CLIENT_SECRET,
          grant_type: "refresh_token",
          refresh_token: refreshToken,
        })
        .then((response) => {
          dispatch(
            authSlice.setAccessToken({
              accessToken: response.data.access_token,
              refreshToken: response.data.refresh_token,
            })
          );
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.data.access_token}`;
          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${response.data.access_token}`;
          processQueue(null, response.data.access_token);
          resolve(axiosInstance.request(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          dispatch(authSlice.logout());
          reject(error.response);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  }
  return Promise.reject(error.response);
};

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
axiosInstance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

axiosInstance.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

// Step-4: Export the newly created Axios instance to be used in different locations.
export default axiosInstance;
