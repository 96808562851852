import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";

const getLocations = createAsyncThunk(
  "organizations/location",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(
          `/organizations/location?text=${params.text}&offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}`
        )
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const addOrganizationLocation = createAsyncThunk(
  "organizations/location/add",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/organizations/location`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateOrganizationLocation = createAsyncThunk(
  "organizations/location/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/organizations/location/${params.id}`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deleteOrganizationLocation = createAsyncThunk(
  "organizations/location/delete",
  async (id, thunkApi) => {
    try {
      return await axiosInstance
        .delete(`/organizations/location/${id}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const locationSlice = {
  getLocations,
  addOrganizationLocation,
  updateOrganizationLocation,
  deleteOrganizationLocation,
};

export default locationSlice;
