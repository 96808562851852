import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";
const initialState = {
  pageCount: 0,
  rowCount: 0,
  text: "",
  list: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const getPractitioners = createAsyncThunk(
  "practitioners",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(
          `/practitioners?text=${params.text}&offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}&organizationFilter=${params.organizationFilter}`
        )
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getPractitionerById = createAsyncThunk(
  "practitioners/id",
  async (id, thunkApi) => {
    try {
      return await axiosInstance
        .get(`/practitioners/${id}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updatePractitioner = createAsyncThunk(
  "practitioners/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/practitioners/${params.id}`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const addPractitioner = createAsyncThunk(
  "practitioners/add",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/practitioners`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deletePractitioner = createAsyncThunk(
  "practitioners/delete",
  async (id, thunkApi) => {
    try {
      return await axiosInstance
        .delete(`/practitioners/${id}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const changeReferralAccess = createAsyncThunk(
  "practitioners/access",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/practitioners/access/${params.id}?val=${params.val}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const skipProfileUpdate = createAsyncThunk(
  "practitioners/skip",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/practitioners/skip/${params.id}`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const addToFavorites = createAsyncThunk(
  "practitioners/favorites",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/practitioners/favorites`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const { actions, reducer } = createSlice({
  name: "practitioners",
  initialState,
  reducers: {
    setIntialProps: (state, action) => {
      return { ...state, ...action?.payload };
    },
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //get practitioners
      .addCase(getPractitioners.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getPractitioners.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data?.practitioners,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getPractitioners.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { clearStatus, setIntialProps } = actions;

const practitionerSlice = {
  clearStatus,
  getPractitioners,
  getPractitionerById,
  updatePractitioner,
  addPractitioner,
  deletePractitioner,
  changeReferralAccess,
  skipProfileUpdate,
  addToFavorites,
  setIntialProps,
  reducer,
};

export default practitionerSlice;
