export default function checkAccess(
  loggedinUser,
  allowedModule,
  allowedResource
) {
  if (
    allowedModule &&
    (loggedinUser?.finalPermissions?.find(
      (p) => p?.resource?.module?.code === allowedModule
    ) ||
      allowedModule === "static")
  )
    if (
      allowedResource &&
      (loggedinUser?.finalPermissions?.find(
        (p) =>
          p?.resource?.name?.toLowerCase() === allowedResource?.toLowerCase()
      ) ||
        allowedResource === "static")
    )
      return true;
    else return false;
  else return false;
}
