import { configureStore } from "@reduxjs/toolkit";
import {
  authSlice,
  patientSlice,
  commonSlice,
  loaderSlice,
  referralSlice,
  taxonomySlice,
  practitionerSlice,
  sortSlice,
  paginationSlice,
  resourceSlice,
  serviceSlice,
  fhirSlice,
  goalSlice,
  conditionSlice,
  organizationSlice,
  procedureSlice,
  taskSlice,
  careTeamSlice,
  carePlanSlice,
  observationSlice,
  questionnaireSlice,
  formSlice,
  aliasSlice,
  eligibilitySlice,
  rbacCustomerSlice,
  rbacResourceSlice,
  rbacRoleSlice,
  documentReferenceSlice,
  practitionerRoleSlice,
} from "../Features";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "fhir"],
};

const patientPersistConfig = {
  key: "patients",
  storage,
  whitelist: ["selectedPatient"],
};

const reducers = combineReducers({
  common: commonSlice.reducer,
  auth: authSlice.reducer,
  patients: persistReducer(patientPersistConfig, patientSlice.reducer),
  loader: loaderSlice.reducer,
  sort: sortSlice.reducer,
  pagination: paginationSlice.reducer,
  referrals: referralSlice.reducer,
  taxonomy: taxonomySlice.reducer,
  resources: resourceSlice.reducer,
  services: serviceSlice.reducer,
  fhir: fhirSlice.reducer,
  goals: goalSlice.reducer,
  conditions: conditionSlice.reducer,
  procedures: procedureSlice.reducer,
  tasks: taskSlice.reducer,
  careTeams: careTeamSlice.reducer,
  carePlans: carePlanSlice.reducer,
  observations: observationSlice.reducer,
  questionnaires: questionnaireSlice.reducer,
  organizations: organizationSlice.reducer,
  practitioners: practitionerSlice.reducer,
  forms: formSlice.reducer,
  aliases: aliasSlice.reducer,
  eligibility: eligibilitySlice.reducer,
  rbacCustomers: rbacCustomerSlice.reducer,
  rbacResources: rbacResourceSlice.reducer,
  rbacRoles: rbacRoleSlice.reducer,
  documentReference: documentReferenceSlice.reducer,
  practitionerRoles: practitionerRoleSlice.reducer,
});

const persistedReducer = persistReducer(rootPersistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
