import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";

const initialState = {
  pageCount: 0,
  rowCount: 0,
  category: "",
  text: "",
  managingOrganization: "",
  status: "",
  list: [],
  selectedRow: null,
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const getCarePlans = createAsyncThunk("carePlans", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .get(
        `/careplans?offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}&category=${params.category}&text=${params.text}&managingOrganization=${params.managingOrganization}&status=${params.status}&subject=${params.subject}`
      )
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const addCarePlan = createAsyncThunk(
  "carePlans/add",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/careplans`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateCarePlan = createAsyncThunk(
  "carePlans/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/careplans`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deleteCarePlan = createAsyncThunk(
  "carePlans/delete",
  async (id, thunkApi) => {
    try {
      return await axiosInstance
        .delete(`/careplans/${id}`)
        .then((response) => response.data)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const { actions, reducer } = createSlice({
  name: "carePlans",
  initialState,
  reducers: {
    setIntialProps: (state, action) => {
      return { ...state, ...action?.payload };
    },
    setSelectedRow: (state, action) => {
      state.selectedRow = action?.payload;
    },
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // get carePlans
      .addCase(getCarePlans.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getCarePlans.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data?.careplans,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getCarePlans.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { setIntialProps, setSelectedRow, clearStatus } = actions;

const carePlanSlice = {
  getCarePlans,
  addCarePlan,
  updateCarePlan,
  deleteCarePlan,
  setSelectedRow,
  setIntialProps,
  clearStatus,
  reducer,
};

export default carePlanSlice;
