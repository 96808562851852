import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";

const initialState = {
  pageCount: 0,
  rowCount: 0,
  category: "",
  text: "",
  managingOrganization: "",
  status: "",
  list: [],
  selectedRow: null,
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const getCareTeams = createAsyncThunk("careTeams", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .get(
        `/careteams?offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}&category=${params.category}&text=${params.text}&managingOrganization=${params.managingOrganization}&status=${params.status}&subject=${params.subject}`
      )
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const addCareTeam = createAsyncThunk(
  "careTeams/add",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/careteams`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateCareTeam = createAsyncThunk(
  "careTeams/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/careteams`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deleteCareTeam = createAsyncThunk(
  "careTeams/delete",
  async (id, thunkApi) => {
    try {
      return await axiosInstance
        .delete(`/careteams/${id}`)
        .then((response) => response.data)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const { actions, reducer } = createSlice({
  name: "careTeams",
  initialState,
  reducers: {
    setIntialProps: (state, action) => {
      return { ...state, ...action?.payload };
    },
    setSelectedRow: (state, action) => {
      state.selectedRow = action?.payload;
    },
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // get careTeams
      .addCase(getCareTeams.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getCareTeams.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data?.careteams,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getCareTeams.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { setIntialProps, setSelectedRow, clearStatus } = actions;

const careTeamSlice = {
  getCareTeams,
  addCareTeam,
  updateCareTeam,
  deleteCareTeam,
  setSelectedRow,
  setIntialProps,
  clearStatus,
  reducer,
};

export default careTeamSlice;
