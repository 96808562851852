import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import Label from "../Label";
import MultipleInput from ".";
import { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { FontAwesomeIcon, faTrash, faPlus } from "../../../Assets/icons";
import { appConstants, messageConstants } from "../../../Constants";
import { asyncForEach } from "../../../Utils";
import _ from "lodash";
import { taxonomySlice } from "../../../Features";
import { toast } from "react-toastify";

const Component = forwardRef(({ rows }, ref) => {
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [component, setComponent] = useState([{}]);
  const referenceRangeComRef = useRef(null);
  const observationValueComRef = useRef(null);
  useEffect(() => {
    setComponent(rows?.length ? rows : [{}]);
  }, [rows]);

  const removefields = (index) => {
    setComponent((component) => component.filter((v, i) => i !== index));
  };

  const addfields = (index) => {
    if (
      component[index]?.code?.coding?.length &&
      !Object.values(
        _(validate(component[index], index)).pickBy(_.identity).value()
      ).length
    )
      setComponent([...component, {}]);
    else setError(validate(component[index], index));
  };

  useImperativeHandle(ref, () => ({
    handleSubmit(event) {
      event.preventDefault();
      let invalid = false;
      const promise = new Promise(async (resolve, reject) => {
        await asyncForEach(component, (val, index) => {
          setError(validate(val, index));
          let referenceRange = referenceRangeComRef.current.handleSubmit(event);
          let value = observationValueComRef.current.handleSubmit(event);
          if (
            Object.values(_(validate(val, index)).pickBy(_.identity).value())
              .length ||
            !referenceRange ||
            !value
          )
            invalid = true;
          if (!invalid) component[index] = { ...val, referenceRange, value };
        });
        resolve();
      });
      return promise.then(() => {
        if (!invalid) return component.filter((t) => t?.code?.coding?.length);
      });
    },
  }));

  const validate = (values, index) => {
    const errorObj = {};
    Object.values(_(values).pickBy(_.identity).value()).length &&
    !values?.code?.coding?.length
      ? (errorObj[`code${index}`] = messageConstants.FIELD_REQUIRED)
      : (errorObj[`code${index}`] = "");
    return errorObj;
  };

  const extendLoadOptions = useCallback(
    (searchQuery, loadedOptions, { name }) => {
      let options = [],
        rowCount = 0;
      return new Promise((resolve, reject) => {
        dispatch(
          taxonomySlice.getValueSet({
            text: searchQuery,
            name,
            offset: loadedOptions.length || appConstants.DEFAULT_SKIP,
            limit: appConstants.DEFAULT_LIMIT,
            sortBy: "createdAt",
            sortOrder: "desc",
          })
        )
          .unwrap()
          .then((response) => {
            options = response?.data?.codes;
            rowCount = response?.data?.rowCount;
            resolve({
              options,
              hasMore:
                loadedOptions.length + appConstants.DEFAULT_LIMIT < rowCount
                  ? true
                  : false,
              name,
            });
          })
          .catch((error) => {
            toast.error(error?.data?.message || error?.data);
            resolve({
              options,
              hasMore: false,
              name,
            });
          });
      });
    },
    [dispatch]
  );

  const customOption = (props) => {
    return (
      <>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </>
    );
  };

  return (
    <div className="col_xs_12">
      <div className="repeater">
        {component?.length
          ? component.map((value, index) => {
              return (
                <div key={index} className="repeater__row">
                  <div className="repeater__row__fields">
                    <div className="row">
                      <div className="col_xl_4 col_md_6">
                          <Label
                            tooltip={true}
                            content="Describes what was observed. Sometimes this is called the observation 'name'."
                            label="Code"
                            isRequired={true}
                            id={`code${index}`}
                          />
                          <AsyncPaginate
                            components={{ Option: customOption }}
                            classNamePrefix={`${
                              error.code ? "select__error" : "select"
                            }`}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            defaultOptions
                            loadOptions={extendLoadOptions}
                            onChange={(option) => {
                              const fieldsArr = [...component];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                code: {
                                  coding: option.map((opt) =>
                                    _.pick(opt, ["code", "display", "system"])
                                  ),
                                  text: _.map(option, "display").join(", "),
                                },
                              };
                              setComponent(fieldsArr);
                              setError(validate(fieldsArr[index], index));
                            }}
                            value={
                              value?.code?.coding?.length && value.code.coding
                            }
                            placeholder="Select..."
                            getOptionValue={(option) => option.code}
                            getOptionLabel={(option) => option.display}
                            additional={{
                              name: "SDOHCCValueSetLOINCSNOMEDCT",
                            }}
                            name={`code${index}`}
                            id={`code${index}`}
                            aria-label={`code${index}`}
                          />
                          {error?.[`code${index}`] ? (
                            <p className={error[`code${index}`] ? "error" : ""}>
                              {error[`code${index}`]}
                            </p>
                          ) : null}
                      </div>
                      <div className="col_xl_4 col_md_6">
                          <Label
                            tooltip={true}
                            content="Provides a reason why the expected value in the element Observation.value[x] is missing."
                            label="Data Absent Reason"
                            isRequired={false}
                            id={`dataAbsentReason${index}`}
                          />
                          <AsyncPaginate
                            components={{ Option: customOption }}
                            classNamePrefix={`${
                              error.dataAbsentReason
                                ? "select__error"
                                : "select"
                            }`}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            defaultOptions
                            loadOptions={extendLoadOptions}
                            onChange={(option) => {
                              const fieldsArr = [...component];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                dataAbsentReason: {
                                  coding: option.map((opt) =>
                                    _.pick(opt, ["code", "display", "system"])
                                  ),
                                  text: _.map(option, "display").join(", "),
                                },
                              };
                              setComponent(fieldsArr);
                            }}
                            value={
                              value?.dataAbsentReason?.coding?.length &&
                              value.dataAbsentReason.coding
                            }
                            placeholder="Select..."
                            getOptionValue={(option) => option.code}
                            getOptionLabel={(option) => option.display}
                            additional={{
                              name: "DataAbsentReason",
                            }}
                            name={`dataAbsentReason${index}`}
                            id={`dataAbsentReason${index}`}
                            aria-label={`dataAbsentReason${index}`}
                          />
                          {error?.dataAbsentReason ? (
                            <p
                              className={error.dataAbsentReason ? "error" : ""}
                            >
                              {error.dataAbsentReason}
                            </p>
                          ) : null}
                      </div>
                      <div className="col_xl_4 col_md_6">
                          <Label
                            tooltip={true}
                            content="A categorical assessment of an observation value. For example, high, low, normal."
                            label="Interpretation"
                            isRequired={false}
                            id={`interpretation${index}`}
                          />
                          <AsyncPaginate
                            components={{ Option: customOption }}
                            classNamePrefix={`${
                              error.interpretation ? "select__error" : "select"
                            }`}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            defaultOptions
                            loadOptions={extendLoadOptions}
                            onChange={(option) => {
                              const fieldsArr = [...component];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                interpretation: option.map((opt) => {
                                  return {
                                    coding: [
                                      _.pick(opt, [
                                        "code",
                                        "display",
                                        "system",
                                      ]),
                                    ],
                                    text: opt.display,
                                  };
                                }),
                              };
                              setComponent(fieldsArr);
                            }}
                            value={
                              value?.interpretation?.length &&
                              value.interpretation.map(
                                (opt) => opt?.coding?.[0]
                              )
                            }
                            placeholder="Select..."
                            getOptionValue={(option) => option.code}
                            getOptionLabel={(option) => option.display}
                            additional={{
                              name: "ObservationInterpretationCodes",
                            }}
                            name={`interpretation${index}`}
                            id={`interpretation${index}`}
                            aria-label={`interpretation${index}`}
                          />
                          {error?.interpretation ? (
                            <p className={error.interpretation ? "error" : ""}>
                              {error.interpretation}
                            </p>
                          ) : null}
                        </div>
                    </div>
                    <div className="row">
                      <div className="col_md_12">
                        <div className="title--h6 mt_10">
                          Value
                        </div>
                      </div>
                        <MultipleInput
                          name="ObservationValue"
                          value={value.value}
                          ref={observationValueComRef}
                          mode="add"
                        />
                    </div>
                    <div className="row">
                      <div className="col_md_12">
                          <div className="title--h6 mt_10 mb_10">
                            Reference Range
                          </div>
                      </div>
                        <MultipleInput
                          name="ReferenceRange"
                          rows={value.referenceRange}
                          ref={referenceRangeComRef}
                          mode="add"
                        />
                    </div>
                  </div>
                  <div className="repeater__row__button">
                    {index === component.length - 1 ? (
                      <button
                        onClick={() => addfields(index)}
                        className="btn btn--success"
                        aria-label="add"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    ) : null}

                    {index !== component.length - 1 ? (
                      <button
                        onClick={() => removefields(index)}
                        className="btn btn--danger"
                        aria-label="delete"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    ) : null}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
});

export default Component;
