import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { validatePassword } from "../../../Utils";
import { Label, Loader } from "../../Common";
import { messageConstants } from "../../../Constants";
import { authSlice } from "../../../Features";
import images from "../../../Assets/images";
import { toast } from "react-toastify";
import _ from "lodash";

export default function ChangePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const loggedinUser = useSelector(authSlice.getLoggedinUser);
  const [loading, setLoading] = useState(false);
  const [passwordHide, setPasswordHide] = useState({});
  const [error, setError] = useState({});
  const [passwords, setPasswords] = useState({
    newPwd: "",
    confirmPwd: "",
  });

  const validate = (values) => {
    const errorObj = {};
    if (!values.newPwd || !values.newPwd.trim().length) {
      errorObj.newPwd = messageConstants.PASSWORD_REQUIRED;
    } else if (values.newPwd && !validatePassword(values.newPwd)) {
      errorObj.newPwd = messageConstants.INVALID_PASSWORD;
    }
    if (!values.confirmPwd || !values.confirmPwd.trim().length) {
      errorObj.confirmPwd = messageConstants.CONFIRM_PASSWORD_REQUIRED;
    } else if (values.confirmPwd && !validatePassword(values.confirmPwd)) {
      errorObj.confirmPwd = messageConstants.INVALID_PASSWORD;
    } else if (
      values.newPwd &&
      values.confirmPwd &&
      values.newPwd !== values.confirmPwd
    )
      errorObj.confirmPwd = messageConstants.PASSWORD_NO_MATCH;
    return errorObj;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(validate(passwords));
    if (
      !Object.values(_(validate(passwords)).pickBy(_.identity).value()).length
    ) {
      dispatch(
        authSlice.changePassword({
          ...passwords,
          token: token ? token : loggedinUser.email,
          noExpiry: token ? false : true,
        })
      )
        .unwrap()
        .then(async (response) => {
          await dispatch(authSlice.getUserInfo());
          setLoading(false);
          toast.success(response.message);
          navigate(0);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.data?.message || error?.data);
        });
    }
  };

  return (
    <div
      id="ChangePassword"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="ChangePassword"
      aria-hidden="true"
      className="modal modal--show"
    >
      {loading ? <Loader /> : ""}
      <div className="modal__dialog modal__dialog--centered" role="document">
        <div className="modal__content">
          <div className="modal__header">
            <div className="modal__header__title">
              <h5 className="title--h4">Change Password</h5>
            </div>
          </div>
          <div className="modal__body">
            <div className="row">
              <div className="col_xxl_12 col_xl_5 col_md_6">
                <div className="formgroup">
                  <Label
                    tooltip={false}
                    isRequired={true}
                    label="New Password"
                    id="newPwd"
                  />
                  <div className="relative">
                    <input
                      name="newPwd"
                      id="newPwd"
                      value={passwords.newPwd}
                      onChange={(event) => {
                        const { value } = event.target;
                        setPasswords({ ...passwords, newPwd: value });
                        setError({
                          newPwd: validate({
                            ...passwords,
                            newPwd: value,
                          }).newPwd,
                        });
                      }}
                      className={error.newPwd ? "control error" : "control"}
                      autoComplete="off"
                      type={passwordHide.newPwd ? "text" : "password"}
                      placeholder="Enter your new password"
                    />
                    {passwordHide.newPwd ? (
                      <button
                        className="pwd"
                        aria-label="hide password"
                        onClick={() => {
                          setPasswordHide({
                            ...passwordHide,
                            newPwd: false,
                          });
                        }}
                      >
                        <img src={images.hidePwd} alt="hide password" />
                      </button>
                    ) : (
                      <button
                        className="pwd"
                        aria-label="show password"
                        onClick={() => {
                          setPasswordHide({
                            ...passwordHide,
                            newPwd: true,
                          });
                        }}
                      >
                        <img src={images.showPwd} alt="show password" />
                      </button>
                    )}
                  </div>
                  {error?.newPwd ? (
                    <p className={error.newPwd ? "error" : ""}>
                      {error.newPwd}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col_xxl_12 col_xl_5 col_md_6">
                <div className="formgroup">
                  <Label
                    tooltip={false}
                    isRequired={true}
                    label="Confirm New Password"
                    id="confirmPwd"
                  />
                  <div className="relative">
                    <input
                      name="confirmPwd"
                      id="confirmPwd"
                      value={passwords.confirmPwd}
                      onChange={(event) => {
                        const { value } = event.target;
                        setPasswords({ ...passwords, confirmPwd: value });
                        setError({
                          confirmPwd: validate({
                            ...passwords,
                            confirmPwd: value,
                          }).confirmPwd,
                        });
                      }}
                      className={error.confirmPwd ? "control error" : "control"}
                      autoComplete="off"
                      type={passwordHide.confirmPwd ? "text" : "password"}
                      placeholder="Enter confirm password"
                    />
                    {passwordHide.confirmPwd ? (
                      <button
                        className="pwd"
                        aria-label="hide password"
                        onClick={() => {
                          setPasswordHide({
                            ...passwordHide,
                            confirmPwd: false,
                          });
                        }}
                      >
                        <img src={images.hidePwd} alt="hide password" />
                      </button>
                    ) : (
                      <button
                      aria-label="show password"
                        className="pwd"
                        onClick={() => {
                          setPasswordHide({
                            ...passwordHide,
                            confirmPwd: true,
                          });
                        }}
                      >
                        <img src={images.showPwd} alt="show password" />
                      </button>
                    )}
                  </div>
                  {error?.confirmPwd ? (
                    <p className={error.confirmPwd ? "error" : ""}>
                      {error.confirmPwd}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="modal__footer justify--center">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn--success btn--full"
            >
              Update Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
