import { useState } from "react";
import {
  GoogleMap,
  LoadScript,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
import config from "../../../Config";
import { appConstants } from "../../../Constants";

const Map = ({ title, markers }) => {
  const [activeMarker, setActiveMarker] = useState(null);
  const defaultCenter = markers?.[0]
    ? markers?.[0]?.collectionName === "patients"
      ? markers?.[0]?.address?.[0]?.position?.coordinates?.length && {
          lat: markers?.[0]?.address?.[0]?.position?.coordinates?.[1],
          lng: markers?.[0]?.address?.[0]?.position?.coordinates?.[0],
        }
      : markers?.[0]?.position?.coordinates?.length && {
          lat: markers?.[0]?.position?.coordinates?.[1],
          lng: markers?.[0]?.position?.coordinates?.[0],
        }
    : null;

  // Return map bounds based on list of markers
  const getMapBounds = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach((place) => {
      bounds.extend(
        place?.collectionName === "patients"
          ? place?.address?.[0]?.position?.coordinates?.length &&
              new window.google.maps.LatLng(
                place?.address?.[0]?.position?.coordinates?.[1],
                place?.address?.[0]?.position?.coordinates?.[0]
              )
          : place?.position?.coordinates?.length &&
              new window.google.maps.LatLng(
                place?.position?.coordinates?.[1],
                place?.position?.coordinates?.[0]
              )
      );
    });
    return bounds;
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, bounds) => {
    window.google.maps.event.addDomListenerOnce(map, "idle", () => {
      window.google.maps.event.addDomListener(window, "resize", () => {
        // map.fitBounds(bounds);
        map.panToBounds(bounds);
      });
    });
  };

  // Fit map to its bounds after the api is loaded
  const handleOnLoad = (map) => {
    // Get bounds by our markers
    const bounds = getMapBounds(map);
    // Fit map to bounds
    // map.fitBounds(bounds);
    map.panToBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, bounds);
  };

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  return (
    <div className="resource__map">
      <div className="resource__heading">
        <div className="title--h5">{title}</div>
        {/* <div className="resource__heading__actions">
          <button
            className="btn--cross"
            onClick={() => {
              dispatch(commonSlice.setShowMap(false));
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div> */}
      </div>
      <div className="resource__map__canvas">
        <LoadScript googleMapsApiKey={config.GOOGLE_API_KEY}>
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "100%" }}
            center={defaultCenter}
            zoom={appConstants.DEFAULT_ZOOM}
            onLoad={handleOnLoad}
            onClick={() => setActiveMarker(null)}
            draggable={false}
          >
            {markers.map((place, index) => (
              <Marker
                key={index}
                position={
                  place?.collectionName === "patients"
                    ? place?.address?.[0]?.position?.coordinates?.length && {
                        lat: place?.address?.[0]?.position?.coordinates?.[1],
                        lng: place?.address?.[0]?.position?.coordinates?.[0],
                      }
                    : place?.position?.coordinates?.length && {
                        lat: place?.position?.coordinates?.[1],
                        lng: place?.position?.coordinates?.[0],
                      }
                }
                onClick={() => handleActiveMarker(index)}
                icon={
                  place?.collectionName === "patients"
                    ? {
                        path: "M12,2C8.14,2,5,5.14,5,9c0,5.25,7,13,7,13s7-7.75,7-13C19,5.14,15.86,2,12,2z M12,4c1.1,0,2,0.9,2,2c0,1.11-0.9,2-2,2 s-2-0.89-2-2C10,4.9,10.9,4,12,4z M12,14c-1.67,0-3.14-0.85-4-2.15c0.02-1.32,2.67-2.05,4-2.05s3.98,0.73,4,2.05 C15.14,13.15,13.67,14,12,14z",
                        fillColor: "red",
                        fillOpacity: 9999,
                        strokeWeight: 0,
                        rotation: 0,
                        scale: 2,
                      }
                    : "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
                }
              >
                {activeMarker === index ? (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <>
                      <div>
                        <strong>
                          {place?.collectionName === "patients"
                            ? place?.name?.[0]?.text?.trim()
                            : place?.orgName}
                        </strong>
                        <p>
                          {place?.address?.[0]?.formattedAddress ||
                            place?.contentString}
                        </p>
                      </div>
                    </>
                  </InfoWindow>
                ) : null}
              </Marker>
            ))}
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
};

export default Map;
