import { useState } from "react";
import { FontAwesomeIcon, faTrash, faPlus } from "../../../Assets/icons";
import { validateEmail, validateLink, validatePhone } from "../../../Utils";
import { messageConstants } from "../../../Constants";

const SubInput = ({ placeholder, type, field, rows, onChange }) => {
  const [error, setError] = useState({});
  const [fields, setField] = useState(rows?.length ? rows : [{ value: "" }]);
  const removefields = (index) => {
    fields.splice(index, 1);
    setField(fields);
    onChange(fields.filter((t) => t.value));
  };

  const addfields = (index) => {
    fields?.[index]?.value &&
      fields?.[index]?.system &&
      setError(
        validate(fields[index].value, `${fields[index].system}${index}`)
      );
    if (
      fields?.[index]?.value &&
      !validate(fields[index].value, `${fields[index].system}${index}`)[
        `${fields[index].system}${index}`
      ]
    )
      setField([...fields, { value: "" }]);
    onChange(fields.filter((t) => t.value));
  };

  const handleOnBlur = (name) => (event) => {
    event.preventDefault();
    const { value } = event.target;
    setError(validate(value, name));
  };

  const validate = (value, name) => {
    const errorObj = {};
    if (field === "phone") {
      value && !validatePhone(value)
        ? (errorObj[`${name}`] = messageConstants.INVALID_PHONE)
        : (errorObj[`${name}`] = "");
    }
    if (field === "email") {
      value && !validateEmail(value)
        ? (errorObj[`${name}`] = messageConstants.INVALID_EMAIL)
        : (errorObj[`${name}`] = "");
    }
    if (field === "url") {
      value && !validateLink(value)
        ? (errorObj[`${name}`] = messageConstants.INVALID_URL)
        : (errorObj[`${name}`] = "");
    }
    return errorObj;
  };

  return (
    <>
      {fields?.length
        ? fields.map((value, index) => {
            return (
              <div
                key={index}
                className={
                  error[`${field}${index}`]
                    ? "multiFields error"
                    : "multiFields"
                }
              >
                <input
                  value={value?.value || ""}
                  onChange={(event) => {
                    const { value } = event.target;
                    const fieldsArr = [...fields];
                    fieldsArr[index] = {
                      ...fieldsArr[index],
                      value,
                      system: field,
                    };
                    setField(fieldsArr);
                    onChange(fieldsArr.filter((t) => t.value));
                  }}
                  onBlur={handleOnBlur(`${field}${index}`)}
                  type={type}
                  aria-label={`${field}${index}`}
                  placeholder={placeholder}
                  className="control"
                  name={`${field}${index}`}
                />

                {index === fields.length - 1 ? (
                  <button
                    onClick={() => addfields(index)}
                    className="btn btn--success"
                    aria-label="add"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                ) : null}

                {index !== fields.length - 1 ? (
                  <button
                    onClick={() => removefields(index)}
                    className="btn btn--danger"
                    aria-label="delete"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                ) : null}
                {error?.[`${field}${index}`] ? (
                  <p className={error[`${field}${index}`] ? "error" : ""}>
                    {error[`${field}${index}`]}
                  </p>
                ) : null}
              </div>
            );
          })
        : null}
    </>
  );
};

export default SubInput;
