import { createSlice } from "@reduxjs/toolkit";
import { pagination } from "../Services";
import { appConstants } from "../Constants";

const initialState = {
  limit: appConstants.DEFAULT_LIMIT,
  offset: appConstants.DEFAULT_SKIP,
  currentPage: 1,
  pageCount: 0,
  rowCount: 0,
  pageList: [1],
};

const { actions, reducer } = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setPaginationProps: (state, action) => {
      if (
        action?.payload?.currentPage < 1 ||
        action?.payload?.currentPage > action?.payload?.pageCount
      )
        return { ...initialState, ...action?.payload };

      // get pager object from service
      let pageObj = pagination(
        action?.payload?.rowCount,
        action?.payload?.currentPage
      );
      return { ...initialState, ...action?.payload, ...pageObj };
    },
    resetPaginationProps: (state, action) => {
      return initialState;
    },
  },
});

const { setPaginationProps, resetPaginationProps } = actions;

const paginationSlice = {
  setPaginationProps,
  resetPaginationProps,
  reducer,
};

export default paginationSlice;
