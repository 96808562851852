import { useSelector, useDispatch } from "react-redux";
import { sortSlice } from "../../../Features";

export default function Sorting() {
  const dispatch = useDispatch();
  const { sortOpts, sortBy, sortOrder } = useSelector((state) => state.sort);

  return (
    <>
      <span className="btn btn--white">Sort</span>
      <div className="dropdown">
        <ul className="nav nav--column">
          {sortOpts?.length
            ? sortOpts.map((value, index) => {
                return (
                  <li
                    key={index}
                    id={value.code}
                    className={`${
                      value.type === "normal" ? "nav__link" : "nav__link"
                    } ${
                      value.code === sortBy
                        ? sortOrder === "asc"
                          ? "nav__link--asc"
                          : "nav__link--desc"
                        : ""
                    }`}
                    onClick={() => {
                      sortBy !== value.code
                        ? dispatch(
                            sortSlice.setSortProps({
                              sortOpts,
                              sortBy: value.code,
                              sortOrder,
                            })
                          )
                        : dispatch(
                            sortSlice.setSortProps({
                              sortOpts,
                              sortBy,
                              sortOrder: sortOrder === "asc" ? "desc" : "asc",
                            })
                          );
                    }}
                  >
                    {value.display}
                  </li>
                );
              })
            : null}
        </ul>
      </div>
    </>
  );
}
