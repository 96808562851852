import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";
const initialState = {
  pageCount: 0,
  rowCount: 0,
  text: "",
  list: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const getRoles = createAsyncThunk("roles", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .get(
        `/rbacroles?text=${params.text}&offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}`
      )
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const getRoleById = createAsyncThunk("role/:id", async (id, thunkApi) => {
  try {
    return await axiosInstance
      .get(`/rbacroles/${id}`)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const updateRole = createAsyncThunk("role/update", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .put(`/rbacroles/${params.id}`, params)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const updateRolePermission = createAsyncThunk(
  "role/permission/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/rbacroles/permission/${params.id}`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const addRole = createAsyncThunk("role/add", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .post(`/rbacroles`, params)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const deleteRole = createAsyncThunk("role/delete", async (id, thunkApi) => {
  try {
    return await axiosInstance
      .delete(`/rbacroles/${id}`)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const { actions, reducer } = createSlice({
  name: "rbacRoles",
  initialState,
  reducers: {
    setIntialProps: (state, action) => {
      return { ...state, ...action?.payload };
    },
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //get rbac roles
      .addCase(getRoles.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data.roles,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getRoles.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { setIntialProps, clearStatus } = actions;

const rbacRoleSlice = {
  getRoles,
  getRoleById,
  updateRole,
  updateRolePermission,
  addRole,
  deleteRole,
  setIntialProps,
  clearStatus,
  reducer,
};

export default rbacRoleSlice;
