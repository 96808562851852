import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import Label from "../Label";
import { FontAwesomeIcon, faTrash, faPlus } from "../../../Assets/icons";
import { appConstants } from "../../../Constants";
import _ from "lodash";
import moment from "moment";

const Schedule = forwardRef(({ rows }, ref) => {
  const error = useState({});
  const [schedule, setSchedule] = useState([
    {
      availableTime: [
        {
          daysOfWeek: [],
          availableStartTime: moment()
            .set({ hour: 9, minute: 0 })
            .format(appConstants.DATEPICKERTIME),
          availableEndTime: moment()
            .set({ hour: 17, minute: 0 })
            .format(appConstants.DATEPICKERTIME),
        },
      ],
    },
  ]);
  useEffect(() => {
    setSchedule(
      rows?.length
        ? rows.map((a) => {
            if (a.validFrom)
              a.validFrom = moment(a.validFrom).format(
                appConstants.DATEPICKERDATE
              );
            if (a.validTo)
              a.validTo = moment(a.validTo).format(appConstants.DATEPICKERDATE);
            a.availableTime = a.availableTime?.length
              ? a.availableTime.map((t) => {
                  if (t.availableStartTime)
                    t.availableStartTime = moment(t.availableStartTime).format(
                      appConstants.DATEPICKERTIME
                    );
                  if (t.availableEndTime)
                    t.availableEndTime = moment(t.availableEndTime).format(
                      appConstants.DATEPICKERTIME
                    );
                  if (!t.daysOfWeek) t.daysOfWeek = [];
                  return t;
                })
              : [
                  {
                    availableTime: [
                      {
                        daysOfWeek: [],
                        availableStartTime: moment()
                          .set({ hour: 9, minute: 0 })
                          .format(appConstants.DATEPICKERTIME),
                        availableEndTime: moment()
                          .set({ hour: 17, minute: 0 })
                          .format(appConstants.DATEPICKERTIME),
                      },
                    ],
                  },
                ];
            return a;
          })
        : [
            {
              availableTime: [
                {
                  daysOfWeek: [],
                  availableStartTime: moment()
                    .set({ hour: 9, minute: 0 })
                    .format(appConstants.DATEPICKERTIME),
                  availableEndTime: moment()
                    .set({ hour: 17, minute: 0 })
                    .format(appConstants.DATEPICKERTIME),
                },
              ],
            },
          ]
    );
  }, [rows]);

  const removefields = (index) => {
    setSchedule((schedule) => schedule.filter((v, i) => i !== index));
  };

  const addfields = (index) => {
    if (
      schedule?.[index]?.availableTime?.length &&
      schedule[index].availableTime.filter((a) => a?.daysOfWeek?.length).length
    )
      setSchedule([
        ...schedule,
        {
          availableTime: [
            {
              daysOfWeek: [],
              availableStartTime: moment()
                .set({ hour: 9, minute: 0 })
                .format(appConstants.DATEPICKERTIME),
              availableEndTime: moment()
                .set({ hour: 17, minute: 0 })
                .format(appConstants.DATEPICKERTIME),
            },
          ],
        },
      ]);
  };

  const handleAvailableTimeChange = (name, index, idx) => (event) => {
    const { checked, value } = event.target;
    const fieldsArr = [...schedule];
    fieldsArr.map((item, i) => {
      if (
        (i === index && name === "availableStartTime") ||
        (i === index && name === "availableEndTime")
      ) {
        item.availableTime[idx][name] = value;
      } else if (i === index) {
        checked
          ? item.availableTime[idx].daysOfWeek.push(value)
          : item.availableTime[idx].daysOfWeek.splice(
              item.availableTime[idx].daysOfWeek.indexOf(value),
              1
            );
      }
      return item;
    });
    setSchedule(fieldsArr);
  };

  const addAvailableTime = (idx, index) => {
    if (schedule?.[index]?.availableTime?.[idx]?.daysOfWeek?.length) {
      const fieldsArr = [...schedule];
      fieldsArr[index] = {
        ...fieldsArr[index],
        availableTime: [
          {
            daysOfWeek: [],
            availableStartTime: moment()
              .set({ hour: 9, minute: 0 })
              .format(appConstants.DATEPICKERTIME),
            availableEndTime: moment()
              .set({ hour: 17, minute: 0 })
              .format(appConstants.DATEPICKERTIME),
          },
          ...fieldsArr[index].availableTime,
        ],
      };
      setSchedule(fieldsArr);
    }
  };

  const removeAvailableTime = (idx, index) => {
    if (schedule?.[index]?.availableTime?.[idx])
      schedule[index].availableTime.splice(idx, 1);
  };

  useImperativeHandle(ref, () => ({
    handleSubmit(event) {
      event.preventDefault();
      return schedule.filter(
        (t) =>
          t?.availableTime?.length &&
          t.availableTime.filter((a) => a?.daysOfWeek?.length).length
      );
    },
  }));

  return (
    <div className="col_xs_12">
      <div className="repeater mb_20">
        {schedule &&
          schedule.map((value, index) => {
            return (
              <div key={index} className="repeater__row">
                <div className="repeater__row__fields">
                  <div className="row">
                    <div className="col_xxl_3 col_md_6">
                      <Label
                        tooltip={true}
                        content="The date from which the schedule information is valid."
                        label="Valid From"
                        isRequired={false}
                        id={`validFrom${index}`}
                      />
                      <input
                        type="date"
                        name={`validFrom${index}`}
                        id={`validFrom${index}`}
                        onChange={(event) => {
                          const { value } = event.target;
                          const fieldsArr = [...schedule];
                          fieldsArr[index] = {
                            ...fieldsArr[index],
                            validFrom: value,
                          };
                          setSchedule(fieldsArr);
                        }}
                        className={
                          error[`validFrom${index}`]
                            ? "control error"
                            : "control"
                        }
                        value={value?.validFrom || ""}
                      />
                      {error?.[`validFrom${index}`] ? (
                        <p
                          className={error[`validFrom${index}`] ? "error" : ""}
                        >
                          {error[`validFrom${index}`]}
                        </p>
                      ) : null}
                    </div>
                    <div className="col_xxl_3 col_md_6">
                      <Label
                        tooltip={true}
                        content="The date to which the schedule information is valid."
                        label="Valid To"
                        isRequired={false}
                        id={`validTo${index}`}
                      />
                      <input
                        type="date"
                        name={`validTo${index}`}
                        id={`validTo${index}`}
                        onChange={(event) => {
                          const { value } = event.target;
                          const fieldsArr = [...schedule];
                          fieldsArr[index] = {
                            ...fieldsArr[index],
                            validTo: value,
                          };
                          setSchedule(fieldsArr);
                        }}
                        className={
                          error[`validTo${index}`] ? "control error" : "control"
                        }
                        value={value?.validTo || ""}
                      />
                      {error?.[`validTo${index}`] ? (
                        <p className={error[`validTo${index}`] ? "error" : ""}>
                          {error[`validTo${index}`]}
                        </p>
                      ) : null}
                    </div>
                    <div className="col_xxl_3 col_md_6">
                      <Label
                        tooltip={true}
                        content="Human readable description of the availability of the service."
                        label="Description"
                        isRequired={false}
                        id={`description${index}`}
                      />
                      <input
                        type="text"
                        name={`description${index}`}
                        id={`description${index}`}
                        onChange={(event) => {
                          const { value } = event.target;
                          const fieldsArr = [...schedule];
                          fieldsArr[index] = {
                            ...fieldsArr[index],
                            description: value,
                          };
                          setSchedule(fieldsArr);
                        }}
                        className={
                          error[`description${index}`]
                            ? "control error"
                            : "control"
                        }
                        value={value?.description || ""}
                      />
                      {error?.[`description${index}`] ? (
                        <p
                          className={
                            error[`description${index}`] ? "error" : ""
                          }
                        >
                          {error[`description${index}`]}
                        </p>
                      ) : null}
                    </div>
                    <div className="col_xxl_3 col_md_6">
                      <Label
                        tooltip={true}
                        content="Is the location open all day?"
                        label="Availability"
                        isRequired={false}
                        aria-label="Availability"
                      />
                      <div className="days">
                        <div className="days__day">
                          <input
                            className="checkbox__input"
                            type="checkbox"
                            name={`allDay${index}`}
                            id={`allDay${index}`}
                            onChange={(event) => {
                              const { checked } = event.target;
                              const fieldsArr = [...schedule];
                              fieldsArr[index] = {
                                ...fieldsArr[index],
                                allDay: checked,
                                availableTime: checked
                                  ? fieldsArr[index].availableTime.map((a) => ({
                                      daysOfWeek: [
                                        "monday",
                                        "tuesday",
                                        "wednesday",
                                        "thursday",
                                        "friday",
                                        "saturday",
                                        "sunday",
                                      ],
                                      availableStartTime: moment()
                                        .set({ hour: 0, minute: 0 })
                                        .format(appConstants.DATEPICKERTIME),
                                      availableEndTime: moment()
                                        .set({ hour: 23, minute: 59 })
                                        .format(appConstants.DATEPICKERTIME),
                                    }))
                                  : fieldsArr[index].availableTime,
                              };
                              setSchedule(fieldsArr);
                            }}
                            value={value?.allDay}
                            checked={value?.allDay ? true : false}
                          />
                          {error?.[`allDay${index}`] ? (
                            <p
                              className={error[`allDay${index}`] ? "error" : ""}
                            >
                              {error[`allDay${index}`]}
                            </p>
                          ) : null}
                          <label
                            className="checkbox__label"
                            htmlFor={`allDay${index}`}
                          >
                            24/7
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {value?.availableTime?.length
                    ? value.availableTime.map((val, idx) => {
                        return (
                          <div key={idx} className="repeater">
                            <div className="repeater__row">
                              <div className="repeater__row__fields">
                                <div className="row">
                                  <div className="col_xs_12">
                                    <Label
                                      tooltip={true}
                                      content="Indicates which days of the week are available between the start and end times."
                                      label="Days"
                                      isRequired={false}
                                      aria-label="Days"
                                    />
                                    <div className="days">
                                      <div className="days__day">
                                        <input
                                          disabled={value.allDay}
                                          type="checkbox"
                                          id={`monday${index}${idx}`}
                                          className="checkbox__input"
                                          checked={
                                            val.daysOfWeek &&
                                            _.includes(val.daysOfWeek, "monday")
                                          }
                                          value="monday"
                                          onChange={handleAvailableTimeChange(
                                            "monday",
                                            index,
                                            idx
                                          )}
                                        />
                                        <label
                                          className="checkbox__label"
                                          htmlFor={`monday${index}${idx}`}
                                        >
                                          Monday
                                        </label>
                                      </div>
                                      <div className="days__day">
                                        <input
                                          disabled={value.allDay}
                                          type="checkbox"
                                          id={`tuesday${index}${idx}`}
                                          className="checkbox__input"
                                          checked={
                                            val.daysOfWeek &&
                                            _.includes(
                                              val.daysOfWeek,
                                              "tuesday"
                                            )
                                          }
                                          value="tuesday"
                                          onChange={handleAvailableTimeChange(
                                            "tuesday",
                                            index,
                                            idx
                                          )}
                                        />
                                        <label
                                          className="checkbox__label"
                                          htmlFor={`tuesday${index}${idx}`}
                                        >
                                          Tuesday
                                        </label>
                                      </div>
                                      <div className="days__day">
                                        <input
                                          disabled={value.allDay}
                                          type="checkbox"
                                          id={`wednesday${index}${idx}`}
                                          className="checkbox__input"
                                          checked={
                                            val.daysOfWeek &&
                                            _.includes(
                                              val.daysOfWeek,
                                              "wednesday"
                                            )
                                          }
                                          value="wednesday"
                                          onChange={handleAvailableTimeChange(
                                            "wednesday",
                                            index,
                                            idx
                                          )}
                                        />
                                        <label
                                          className="checkbox__label"
                                          htmlFor={`wednesday${index}${idx}`}
                                        >
                                          Wednesday
                                        </label>
                                      </div>
                                      <div className="days__day">
                                        <input
                                          disabled={value.allDay}
                                          type="checkbox"
                                          id={`thursday${index}${idx}`}
                                          className="checkbox__input"
                                          checked={
                                            val.daysOfWeek &&
                                            _.includes(
                                              val.daysOfWeek,
                                              "thursday"
                                            )
                                          }
                                          value="thursday"
                                          onChange={handleAvailableTimeChange(
                                            "thursday",
                                            index,
                                            idx
                                          )}
                                        />
                                        <label
                                          className="checkbox__label"
                                          htmlFor={`thursday${index}${idx}`}
                                        >
                                          Thursday
                                        </label>
                                      </div>
                                      <div className="days__day">
                                        <input
                                          disabled={value.allDay}
                                          type="checkbox"
                                          id={`friday${index}${idx}`}
                                          className="checkbox__input"
                                          checked={
                                            val.daysOfWeek &&
                                            _.includes(val.daysOfWeek, "friday")
                                          }
                                          value="friday"
                                          onChange={handleAvailableTimeChange(
                                            "friday",
                                            index,
                                            idx
                                          )}
                                        />
                                        <label
                                          className="checkbox__label"
                                          htmlFor={`friday${index}${idx}`}
                                        >
                                          Friday
                                        </label>
                                      </div>
                                      <div className="days__day">
                                        <input
                                          disabled={value.allDay}
                                          type="checkbox"
                                          id={`saturday${index}${idx}`}
                                          className="checkbox__input"
                                          checked={
                                            val.daysOfWeek &&
                                            _.includes(
                                              val.daysOfWeek,
                                              "saturday"
                                            )
                                          }
                                          value="saturday"
                                          onChange={handleAvailableTimeChange(
                                            "saturday",
                                            index,
                                            idx
                                          )}
                                        />
                                        <label
                                          className="checkbox__label"
                                          htmlFor={`saturday${index}${idx}`}
                                        >
                                          Saturday
                                        </label>
                                      </div>
                                      <div className="days__day">
                                        <input
                                          disabled={value.allDay}
                                          type="checkbox"
                                          id={`sunday${index}${idx}`}
                                          className="checkbox__input"
                                          checked={
                                            val.daysOfWeek &&
                                            _.includes(val.daysOfWeek, "sunday")
                                          }
                                          value="sunday"
                                          onChange={handleAvailableTimeChange(
                                            "sunday",
                                            index,
                                            idx
                                          )}
                                        />
                                        <label
                                          className="checkbox__label"
                                          htmlFor={`sunday${index}${idx}`}
                                        >
                                          Sunday
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col_xxl_3 col_md_6">
                                    <Label
                                      tooltip={true}
                                      content="Time that the Service opens."
                                      label="Opens"
                                      isRequired={false}
                                      id={`availableStartTime${idx}${index}`}
                                    />
                                    <input
                                      disabled={value.allDay}
                                      type="time"
                                      name={`availableStartTime${idx}${index}`}
                                      id={`availableStartTime${idx}${index}`}
                                      onChange={handleAvailableTimeChange(
                                        "availableStartTime",
                                        index,
                                        idx
                                      )}
                                      className={
                                        error[
                                          `availableStartTime${idx}${index}`
                                        ]
                                          ? "control error"
                                          : "control"
                                      }
                                      value={
                                        val?.availableStartTime
                                          ? val.availableStartTime
                                          : ""
                                      }
                                    />
                                    {error?.[
                                      `availableStartTime${idx}${index}`
                                    ] ? (
                                      <p
                                        className={
                                          error[
                                            `availableStartTime${idx}${index}`
                                          ]
                                            ? "error"
                                            : ""
                                        }
                                      >
                                        {
                                          error[
                                            `availableStartTime${idx}${index}`
                                          ]
                                        }
                                      </p>
                                    ) : null}
                                  </div>
                                  <div className="col_xxl_3 col_md_6">
                                    <Label
                                      tooltip={true}
                                      content="Time that the Service closes."
                                      label="Closes"
                                      isRequired={false}
                                      id={`availableEndTime${idx}${index}`}
                                    />
                                    <input
                                      disabled={value.allDay}
                                      type="time"
                                      name={`availableEndTime${idx}${index}`}
                                      id={`availableEndTime${idx}${index}`}
                                      onChange={handleAvailableTimeChange(
                                        "availableEndTime",
                                        index,
                                        idx
                                      )}
                                      className={
                                        error[`availableEndTime${idx}${index}`]
                                          ? "control error"
                                          : "control"
                                      }
                                      value={
                                        val?.availableEndTime
                                          ? val.availableEndTime
                                          : ""
                                      }
                                    />
                                    {error?.[
                                      `availableEndTime${idx}${index}`
                                    ] ? (
                                      <p
                                        className={
                                          error[
                                            `availableEndTime${idx}${index}`
                                          ]
                                            ? "error"
                                            : ""
                                        }
                                      >
                                        {
                                          error[
                                            `availableEndTime${idx}${index}`
                                          ]
                                        }
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="repeater__row__button">
                                {idx === value.availableTime.length - 1 ? (
                                  <button
                                    disabled={value.allDay}
                                    onClick={() => {
                                      addAvailableTime(idx, index);
                                    }}
                                    className="btn btn--success"
                                    aria-label="add"
                                  >
                                    <FontAwesomeIcon icon={faPlus} />
                                  </button>
                                ) : null}

                                {idx !== value.availableTime.length - 1 ? (
                                  <button
                                    disabled={value.allDay}
                                    onClick={() => {
                                      removeAvailableTime(idx, index);
                                    }}
                                    className="btn btn--danger"
                                    aria-label="delete"
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
                <div className="repeater__row__button">
                  {index === schedule.length - 1 ? (
                    <button
                      onClick={() => addfields(index)}
                      className="btn btn--success"
                      aria-label="add"
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  ) : null}

                  {index !== schedule.length - 1 ? (
                    <button
                      onClick={() => removefields(index)}
                      className="btn btn--danger"
                      aria-label="delete"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  ) : null}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
});

export default Schedule;
