import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";
const initialState = {
  pageCount: 0,
  rowCount: 0,
  text: "",
  organization: null,
  list: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const getServices = createAsyncThunk("services", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .get(
        `/services?text=${params.text}&offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}&organization=${params.organization}`
      )
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const getServiceById = createAsyncThunk("services/id", async (id, thunkApi) => {
  try {
    return await axiosInstance
      .get(`/services/${id}`)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const addService = createAsyncThunk(
  "services/add",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/services`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateService = createAsyncThunk(
  "services/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/services/${params.id}`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deleteService = createAsyncThunk(
  "services/delete",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .delete(`/services/${params.id}?organization=${params.organization}`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const { actions, reducer } = createSlice({
  name: "services",
  initialState,
  reducers: {
    setIntialProps: (state, action) => {
      return { ...state, ...action?.payload };
    },
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //get services
      .addCase(getServices.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getServices.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data?.services,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getServices.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { setIntialProps, clearStatus } = actions;

const serviceSlice = {
  clearStatus,
  getServices,
  getServiceById,
  addService,
  updateService,
  deleteService,
  setIntialProps,
  reducer,
};

export default serviceSlice;
