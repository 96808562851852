import config from "../../../Config";

export default function ReferralDirectMessage(props) {
  return (
    <>
      Hi, \nYou have received a patient/client referral from {props.username},
      who works at {props.requesterOrganization}. In order to securely protect
      patient/client information, you can view your referral on Open City Labs.
      \nOpen City Labs makes it easier for you to sign up and refer patients to
      80 government programs and community services. You can access the referral
      by creating an account here: {config.BACKEND_URL}. \nRegards, \n
      {props.username}
    </>
  );
}
