import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";

const initialState = {
  pageCount: 0,
  rowCount: 0,
  text: "",
  priority: "",
  status: "",
  list: [],
  selectedRow: null,
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const getTasks = createAsyncThunk("tasks", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .get(
        `/tasks?offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}&text=${params.text}&status=${params.status}&priority=${params.priority}&subject=${params.subject}`
      )
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const addTask = createAsyncThunk("tasks/add", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .post(`/tasks`, params)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const updateTask = createAsyncThunk(
  "tasks/update",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/tasks`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deleteTask = createAsyncThunk("tasks/delete", async (id, thunkApi) => {
  try {
    return await axiosInstance
      .delete(`/tasks/${id}`)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const { actions, reducer } = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setIntialProps: (state, action) => {
      return { ...state, ...action?.payload };
    },
    setSelectedRow: (state, action) => {
      state.selectedRow = action?.payload;
    },
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // get tasks
      .addCase(getTasks.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data?.tasks,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getTasks.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { setIntialProps, setSelectedRow, clearStatus } = actions;

const taskSlice = {
  getTasks,
  addTask,
  updateTask,
  deleteTask,
  setSelectedRow,
  setIntialProps,
  clearStatus,
  reducer,
};

export default taskSlice;
