import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon, faPlus, faMinus } from "../../../Assets/icons";
import { taxonomySlice } from "../../../Features";

const Tree = ({ data = [] }) => {
  return (
    <ul className="tree show">
      {data.map((tree, index) => (
        <TreeNode key={index} node={tree} />
      ))}
    </ul>
  );
};

const flatten = (array, fn) => {
  return array.reduce((acc, value) => {
    var children = flatten(value.children || [], fn);
    if (fn(value) || children.length)
      acc.push(Object.assign({}, value, children.length && { children }));
    return acc;
  }, []);
};

const TreeNode = ({ node }) => {
  const dispatch = useDispatch();
  const selectedTaxonomy = useSelector(taxonomySlice.getSelectedTaxonomy);
  const selectedTaxonomyCol = useSelector(taxonomySlice.getSelectedTaxonomyCol);
  const [childVisible, setChildVisiblity] = useState(
    node.path.split("#").length === 1 ||
      (node?.children?.length &&
        selectedTaxonomy?.length &&
        flatten(node.children, ({ _id }) =>
          selectedTaxonomy.find((t) => t === _id)
        ).length)
      ? true
      : false
  );
  const hasChild = node?.children?.length ? true : false;
  useEffect(() => {
    setChildVisiblity(
      node.path.split("#").length === 1 ||
        (node?.children?.length &&
          selectedTaxonomy?.length &&
          flatten(node.children, ({ _id }) =>
            selectedTaxonomy.find((t) => t === _id)
          ).length)
        ? true
        : false
    );
  }, [selectedTaxonomy, node, dispatch]);

  return (
    <li
      className={
        hasChild && childVisible
          ? "tree__link tree__haschild tree__expanded"
          : hasChild
          ? "tree__link tree__haschild"
          : "tree__link"
      }
    >
      <div className="checkbox">
        {hasChild ? (
          <div
            className="trigger"
            onClick={(e) => setChildVisiblity((v) => !v)}
          >
            <FontAwesomeIcon className="tree__plus" icon={faPlus} />
            <FontAwesomeIcon className="tree__minus" icon={faMinus} />
          </div>
        ) : (
          ""
        )}
        <input
          value={
            node?._id &&
            selectedTaxonomy?.length &&
            selectedTaxonomy.find((t) => t === node._id)
          }
          checked={
            node?._id &&
            selectedTaxonomy?.length &&
            selectedTaxonomy.find((t) => t === node._id)
              ? true
              : false
          }
          onChange={() => {
            let arr = selectedTaxonomy?.length ? [...selectedTaxonomy] : [];
            let arrCol = selectedTaxonomyCol?.length
              ? [...selectedTaxonomyCol]
              : [];
            let index = arr.findIndex((x) => x === node._id);
            if (index === -1) {
              arr.unshift(node._id);
              arrCol.unshift(node);
            } else {
              arr.splice(index, 1);
              arrCol.splice(index, 1);
            }
            dispatch(taxonomySlice.setSelectedTaxonomy(arr));
            dispatch(taxonomySlice.setSelectedTaxonomyCol(arrCol));
          }}
          type="checkbox"
          className="checkbox__input"
          id={node.term}
        />
        <label className="checkbox__label" htmlFor={node.term}>
          {node.term}
        </label>
      </div>
      {hasChild && childVisible && <Tree data={node.children} />}
    </li>
  );
};

export default Tree;
