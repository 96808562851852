import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";
const initialState = {
  pageCount: 0,
  rowCount: 0,
  text: "",
  distance: 10,
  type: "topic",
  directory: "nj211",
  city: "",
  state: "",
  postalCode: "",
  practitionerSpecialty: "",
  specialty: "",
  endpointType: "",
  coordinates: null,
  patientZipCode: null,
  list: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const getLocalDirData = createAsyncThunk(
  "services/localDir/data",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(
          `/services/localDir/data?text=${params.text}&distance=${params.distance}&terms=${params.terms}&type=${params.type}&coordinates=${params.coordinates}&limit=${params.limit}&offset=${params.offset}&postalCode=${params.postalCode}`
        )
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getNationalDirectoryData = createAsyncThunk(
  "services/natDir/data",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(
          `/services/natDir/data?name=${params.text}&specialty=${params.specialty}&limit=${params.limit}&offset=${params.offset}`
        )
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getHsdsDirectoryData = createAsyncThunk(
  "services/hsdsDir/data",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(
          `/services/hsdsDir/data?text=${params.text}&specialty=${params.specialty}&state=${params.state}&postalCode=${params.postalCode}&limit=${params.limit}&offset=${params.offset}`
        )
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const { actions, reducer } = createSlice({
  name: "resources",
  initialState,
  reducers: {
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
    setIntialProps: (state, action) => {
      return { ...state, ...action?.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      //get nj211 resources
      .addCase(getLocalDirData.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getLocalDirData.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data?.resources,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getLocalDirData.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      })
      //get national directory resources
      .addCase(getNationalDirectoryData.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getNationalDirectoryData.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data?.services,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getNationalDirectoryData.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      })
      //get hsds directory resources
      .addCase(getHsdsDirectoryData.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getHsdsDirectoryData.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          list: action?.payload?.data?.services,
          rowCount: action?.payload?.data?.rowCount,
          pageCount: action?.payload?.data?.pageCount,
        };
      })
      .addCase(getHsdsDirectoryData.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { clearStatus, setIntialProps } = actions;

const resourceSlice = {
  clearStatus,
  getLocalDirData,
  getNationalDirectoryData,
  getHsdsDirectoryData,
  setIntialProps,
  reducer,
};

export default resourceSlice;
