import ImageUpload from "./imageUpload";
import SelectContact from "./selectContact";
import SelectAttribute from "./selectAttribute";
import Delete from "./delete";
import ChangePassword from "./changePassword";
import NoPatientSelected from "./noPatientSelected";
import SelectRole from "./selectRole";
import DocumentUpload from "./documentUpload";

export default function Modals(props) {
  let name = props.name;
  return (
    <>
      {name === "ImageUpload" ? (
        <ImageUpload {...props} />
      ) : name === "SelectContact" ? (
        <SelectContact {...props} />
      ) : name === "SelectAttribute" ? (
        <SelectAttribute {...props} />
      ) : name === "Delete" ? (
        <Delete {...props} />
      ) : name === "ChangePassword" ? (
        <ChangePassword {...props} />
      ) : name === "NoPatientSelected" ? (
        <NoPatientSelected {...props} />
      ) : name === "SelectRole" ? (
        <SelectRole {...props} />
      ) : name === "DocumentUpload" ? (
        <DocumentUpload {...props} />
      ) : (
        ""
      )}
    </>
  );
}
