import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../Services";
import { messageConstants } from "../Constants";

const initialState = {
  accessToken: null,
  refreshToken: null,
  loggedinUser: null,
  isAuthenticated: false,
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

const login = createAsyncThunk("auth/login", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .post(`/auth/login`, params)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const ssoLogin = createAsyncThunk("auth/token", async (params, thunkApi) => {
  try {
    return await axiosInstance
      .post(`/auth/token`, params)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const getUserInfo = createAsyncThunk(
  "auth/userInfo",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .get(`/auth/userInfo`)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const logout = createAsyncThunk("auth/logout", async (thunkApi) => {
  try {
    return await axiosInstance
      .get(`/auth/logout`)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/auth/password/forgot`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/auth/password/reset`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const setDefaultRole = createAsyncThunk(
  "auth/default/role",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .put(`/auth/default/role/${params.id}`, params.defaultRole)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateFhirToken = createAsyncThunk(
  "auth/smart/token",
  async (params, thunkApi) => {
    try {
      return await axiosInstance
        .post(`/auth/smart/token`, params)
        .then((response) => response)
        .catch((error) => thunkApi.rejectWithValue(error));
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const { actions, reducer } = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedinUser: (state, action) => {
      state.loggedinUser = action?.payload;
    },
    setAccessToken: (state, action) => {
      if (action?.payload?.accessToken)
        state.accessToken = action.payload.accessToken;
      if (action?.payload?.refreshToken)
        state.refreshToken = action.payload.refreshToken;
    },
    clearStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // login
      .addCase(login.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(login.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: true,
          isSuccess: true,
          isAuthenticated: true,
          successMessage: messageConstants.LOGIN_SUCCESS,
          accessToken: action?.payload?.access_token,
          refreshToken: action?.payload?.refresh_token,
        };
      })
      .addCase(login.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      })
      // current user
      .addCase(getUserInfo.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          loggedinUser: action?.payload?.data,
        };
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        return {
          ...state,
          isAuthenticated: false,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      })
      // logout
      .addCase(logout.pending, (state) => {
        return {
          ...state,
          isFetching: true,
        };
      })
      .addCase(logout.fulfilled, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          isAuthenticated: false,
          accessToken: null,
          refreshToken: null,
          loggedinUser: null,
          successMessage: action?.payload?.message,
        };
      })
      .addCase(logout.rejected, (state, action) => {
        return {
          ...state,
          isFetching: false,
          isError: true,
          errorMessage:
            action?.payload?.message ||
            action?.payload?.data?.message ||
            action?.payload?.data,
        };
      });
  },
});

const { setLoggedinUser, setAccessToken, clearStatus } = actions;

const getAccessToken = (state) => state.auth.accessToken;
const getLoggedinUser = (state) => state.auth.loggedinUser;

const authSlice = {
  getAccessToken,
  getLoggedinUser,
  setAccessToken,
  setLoggedinUser,
  clearStatus,
  ssoLogin,
  login,
  getUserInfo,
  changePassword,
  forgotPassword,
  updateFhirToken,
  setDefaultRole,
  logout,
  reducer,
};

export default authSlice;
