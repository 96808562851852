import config from "../../../Config";
import moment from "moment";
import { appConstants } from "../../../Constants";

export default function PrintReferralForPatient(props) {
  return (
    <div>
      <p>
        You have received a referral to {props.performer} at
        {props.performerOrganization}, {props.performerLocation},{" "}
        {props.telecom}.
      </p>
      <p>
        The referral was sent from {props.requester}{" "}
        {props.requesterOrganization} at
        {moment().format(appConstants.LONGDATE)}
      </p>
      <p>
        Click on the link and login to update the status of the referral:
        {`${config.BACKEND_URL}/login`}
      </p>
      <br />
      <p>Thanks,</p>
      <p>Open City Labs Team</p>
      <br />
      <br />
      <span
      //   style="
      //   background: #f4f3f3;
      //   display: block;
      //   padding: 10px;
      //   text-align: center;
      //   border-radius: 20px;
      // "
      >
        Open City Labs makes it easier for you to sign up and refer patients to
        80 government programs and community services. You can access the
        referral by creating an account here:{" "}
        <a href={config.BACKEND_URL}>OPENCITY LABS</a>
      </span>
    </div>
  );
}
