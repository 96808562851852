import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../Common";
import { messageConstants } from "../../../Constants";
import { authSlice } from "../../../Features";
import { toast } from "react-toastify";
import { FontAwesomeIcon, faChevronDown, faXmark } from "../../../Assets/icons";

export default function SelectRole({ mode, onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedinUser = useSelector(authSlice.getLoggedinUser);
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(
    loggedinUser?.practitionerRoles?.findIndex(
      (o) => o?.organization?._id === loggedinUser?.defaultRole?.organizationId
    ) > 0
      ? loggedinUser?.practitionerRoles?.findIndex(
          (o) =>
            o?.organization?._id === loggedinUser?.defaultRole?.organizationId
        )
      : 0
  );
  const [selectedRole, setSelectedRole] = useState(loggedinUser.defaultRole);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedRole?.roleId) toast.warn(messageConstants.SELECT_ROLE);
    else
      dispatch(
        authSlice.setDefaultRole({
          id: loggedinUser?._id,
          defaultRole: selectedRole,
        })
      )
        .unwrap()
        .then(async (response) => {
          await dispatch(authSlice.getUserInfo());
          setLoading(false);
          toast.success(response.message);
          navigate(0);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.data?.message || error?.data);
        });
  };

  return (
    <div
      id="roleModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="Role modal"
      aria-hidden="true"
      className="rolemodal modal modal--show"
    >
      {loading ? <Loader /> : ""}
      <div className="modal__dialog modal__dialog--centered" role="document">
        <div className="modal__content">
          <div className="modal__header">
            <h5 className="modal__title">Organizations and roles</h5>
            {mode === "edit" ? (
              <button
                onClick={onClose}
                type="button"
                className="btn btn--clear"
                data-dismiss="modal"
                aria-label="Close"
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            ) : (
              ""
            )}
          </div>

          <div className="modal__body">
            <h6>Select your organization and the role</h6>
            <div className="rolemodal__accordian">
              {loggedinUser?.practitionerRoles?.length
                ? loggedinUser?.practitionerRoles.map((value, index) => {
                    return (
                      <div key={index} className="rolemodal__item">
                        <input
                          type="checkbox"
                          className="rolemodal__radio"
                          id={`organization${index}`}
                          name={`organization${index}`}
                          checked={selectedIndex === index}
                          onChange={() => setSelectedIndex(index)}
                        />
                        <label
                          className={`${
                            selectedIndex === index ? "" : "tab-label"
                          } rolemodal__header`}
                          htmlFor={`organization${index}`}
                        >
                          <div className="rolemodal__title">
                            {value?.organization?.name}
                          </div>
                          <div className="rolemodal__icon">
                            <FontAwesomeIcon icon={faChevronDown} />
                          </div>
                        </label>
                        <div
                          className={`${
                            selectedIndex === index ? "" : "tab-content"
                          } rolemodal__content`}
                        >
                          {value?.code?.length
                            ? value.code.map((val, idx) => {
                                return (
                                  <div key={idx} className="checkbox">
                                    <input
                                      type="radio"
                                      className="checkbox__input"
                                      id={`role${index}${idx}`}
                                      name={`role${index}${idx}`}
                                      value={
                                        selectedRole?.index === `${index}${idx}`
                                          ? true
                                          : false
                                      }
                                      checked={
                                        selectedRole?.index === `${index}${idx}`
                                          ? true
                                          : false
                                      }
                                      onChange={(event) => {
                                        const { checked } = event?.target;
                                        if (checked)
                                          setSelectedRole({
                                            organizationId:
                                              value?.organization?._id,
                                            organizationName:
                                              value?.organization?.name?.trim(),
                                            roleName:
                                              val?.display?.trim() ||
                                              val.code?.trim(),
                                            roleId: val.roleId,
                                            index: `${index}${idx}`,
                                          });
                                      }}
                                    />
                                    <label
                                      htmlFor={`role${index}${idx}`}
                                      className="checkbox__label"
                                    >
                                      {val?.display || val?.code}
                                    </label>
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
            <div className="checkbox" style={{ padding: "1rem 0" }}>
              <input
                disabled={selectedRole?.roleId ? false : true}
                type="checkbox"
                name="defaultRole"
                id="defaultRole"
                className="checkbox__input"
                checked={selectedRole?.isDefaultRoleSet ? true : false}
                value={selectedRole?.isDefaultRoleSet ? true : false}
                onChange={(event) =>
                  setSelectedRole((selectedRole) => ({
                    ...selectedRole,
                    isDefaultRoleSet: event?.target.checked ? true : false,
                  }))
                }
              />
              <label className="checkbox__label" htmlFor="defaultRole">
                Set {selectedRole?.organizationName} {selectedRole?.roleName} as
                default role
              </label>
            </div>
          </div>
          <div className="modal__footer">
            <button className="btn btn--success" onClick={handleSubmit}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
