import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sortOpts: [],
  sortBy: "createdAt",
  sortOrder: "desc",
};

const { actions, reducer } = createSlice({
  name: "sort",
  initialState,
  reducers: {
    setSortProps: (state, action) => {
      return { ...initialState, ...action.payload };
    },
    resetSortProps: (state, action) => {
      return initialState;
    },
  },
});

const { setSortProps, resetSortProps } = actions;

const sortSlice = {
  setSortProps,
  resetSortProps,
  reducer,
};

export default sortSlice;
