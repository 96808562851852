const settings = {
  // BACKEND_URL: "http://localhost:4000", // local server
  // BACKEND_URL: "https://staging.opencitylabs.com", // staging server
  // BACKEND_URL: "https://demo.opencitylabs.com", // demo/ training server
  BACKEND_URL: "https://www.opencitylabs.com", // production server
  GOOGLE_API_KEY: "AIzaSyDjwl2b7j_t5OV2OzF97edi4I-0Mwv8kQg",
  SECRET_KEY: "opencitylabs-secret",
  CLIENT_ID: "0246c497-5773-444d-8fbc-d3ff35546f49",
  CLIENT_SECRET: "338273b5-22d6-4b3b-aff1-89a8ef329adf",
};

export default settings;
